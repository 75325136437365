import { CONTENT } from "../../utils/Constants";
import { Container } from "react-bootstrap";
import styles from "../Content/Content.module.css";
import SublyApi from "../../helpers/Api";
import { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import { useTranslation } from "react-i18next";
import { STATUS_CODES } from "../../utils/StatusCode";
import Loader from "../../utils/Loader/Loader";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import SubHeader from "../HeaderSection/SubHeader";
import FooterSection from "../FooterSection/FooterSection";

//----------function for terms and condition content----------- 
function TermsCondition() {
    const { t } = useTranslation();
    const [contentList, setContentList] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    // --------function for open component top of the page ----------
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //---------function for get terms and condition content api calling---------- 
    useEffect(() => {
        setIsLoading(true);
        async function privacyContent() {
            const details = await SublyApi.getContent(CONTENT.TERMS_CONDITION);
            if (details && details.settings && details.settings.success && details.settings.success == STATUS_CODES.SUCCESS_API) {
                setContentList(details && details.data.page_content);
                setIsLoading(false)
            }
        }
        privacyContent();
    }, []);

    const handleBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate("/login");
        }
    };


    return (
        <>
            {isLoading === true ? <Loader /> :
                (
                    <>
                    <SubHeader/>
                        <div className="main">
                            {/* <div className="mbBack desktopHide">
                                <button onClick={handleBack} className="back-button">
                                    <span>
                                        <BiArrowBack style={{ color: "white" }} />
                                    </span>
                                </button>
                            </div> */}
                            <Container>
                                <div className="boxWrapper">
                                    <div className='innerboxWrapper'>
                                        {/* <div className={styles.actionBar}>
                                            <button onClick={handleBack} className="back-button mobileHide">
                                                <span>
                                                    <BiArrowBack style={{ color: "white" }} />
                                                </span>
                                            </button>
                                        </div> */}
                                        <h2 className={styles.HeadingTitle}>{t("TERMS_CONDITION")}</h2>
                                        <div
                                        className={styles.mainDiv}
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(contentList)
                                            }}>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <FooterSection />
                    </>
                )}
        </>
    );
}
export default TermsCondition;