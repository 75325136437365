import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";


const PrevArrow2 = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className="prevArrow2"
      // style={{ width:"30px", height:"30px", backgroundColor:"rgb(255 255 255 / 63%)", position:"absolute", top:"40%",left:"10", borderRadius:"50px",zIndex:"9", lineHeight:"30px", cursor:"pointer"}}
      onClick={onClick}
    >
      {"<"}
    </div>
  );
};

const NextArrow2 = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className="nextArrow2"
      // style={{ width:"30px", height:"30px", backgroundColor:"rgb(255 255 255 / 63%)", position:"absolute", top:"40%", right:"0" ,borderRadius:"50px",lineHeight:"30px", zIndex:"9", marginRight:"10" , cursor:"pointer"}}
      onClick={onClick}
    >
      {">"}
    </div>
  );
};



export default function SimpleSlider({ data }) {

  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: data?.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow2 />,
    nextArrow: <NextArrow2 />,
    autoplay: true,
    autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3.5,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3.5,
          arrows: false
        }
      }

    ]

  };

  const handleNavigate = (id) => {
    navigate(`/creatordetail/${id}`)
  }


  return (
    <Slider {...settings} className="sliderTwo mt-3">
      {data?.map((item, index) => (
        <div key={index} className="sliderTwoWrapper">
          <div className="imagesBox" onClick={() => {
            handleNavigate(item?.iAdminId)
          }}>
            <img src={item.vWebsiteImage} alt="" style={{ cursor: "pointer" }} />
            <div className="imagesBox_content" style={{ cursor: "pointer" }} onClick={() => {
              handleNavigate(item?.iAdminId)
            }}>
              {item?.eWebsiteNameShowInApp == "Yes" && <h3>{item.vWebsiteName}</h3>}
              {item?.eWebsiteSubTitleShowInApp == "Yes" && <h4>{item.vWebsiteSubTitle}</h4>}
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}