import React, { useEffect, useRef, useState } from "react";
import styles from "./ReviewRating.module.css";
import SubHeader from "../HeaderSection/SubHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BiArrowBack, BiStar } from "react-icons/bi";
import { Col, Container, Nav, Navbar, NavDropdown, Row } from "react-bootstrap";
import { CreateReviewRating, GetOneReviewRating, GetReviewRating } from "../../store/slices/ReviewRatingSlice";
import { useDispatch, useSelector } from "react-redux";
import { StarRating } from "../../commonComponents/StarRating/RenderStar";
import Loader from "../../utils/Loader/Loader";
import Norating from "../../assets/Images/Group 1000005480.png";
import iconSort from '../../assets/Images/sort_arrow.svg';
import LoginModal from "../../commonComponents/LoginModal/LoginModal";
import ReviewModal from "./CreateReviewModal";
import EditReviewModal from "./EditReviewModal";
import StarsRating from "react-star-rate";
// import StarRatings from "react-star-ratings";
// import { Rating } from 'react-simple-star-rating';
import { Toast } from "../../utils/Toaster";
import ViewReviewModal from "./ViewReviewModal";
import { BsFillStarFill } from "react-icons/bs";
import { getCreatorDetails } from "../../store/slices/CreatorSlice";

export const ReviewRating = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterChange, setIsFilterChange] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showEditReviewModal, setShowEditReviewModal] = useState(false);
  const [showViewReviewModal, setShowViewReviewModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [reviewId, setReviewId] = useState("");
  const [filter, setFilter] = useState("all");
  const [rating, setRating] = useState(0);
  const { userToken, currentUnit, updateUser, currentUser } = useSelector((state) => state.user);
  const { reviewRatings, review } = useSelector((state) => state.reviewRating);
  const creatorDetails = useSelector((state) => state.creatorList.creatorDetails);
  const maxCharacters = 35;
  const maxCharactersWithoutCreatorResponse = 150;
  const maxCharactersforTitle = 10;

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    if (id) {
      fetchReviews(filter, isFilterChange);
      getCreatorDetails({ id, authToken: userToken || null })
    }
  }, [id, filter]);

  const fetchReviews = (selectedFilter, isFilterChange) => {
    const data = {
      authToken: userToken,
      id: id,
      filter: selectedFilter,
    };

    if (!isFilterChange) {
      setIsLoading(true);
    }


    // setIsLoading(true);
    dispatch(GetReviewRating(data))
      .then(() => {
        setIsLoading(false);
        setIsFilterChange(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsFilterChange(false);
      });
  };

  const handleFilterChange = (value) => {
    setIsOpen(false)
    setIsFilterChange(true);
    setFilter(value);
  };

  const handleReviewCreator = () => {
    if (!userToken) {
      setShowLoginModal(true);
    }
    else if (userToken) {
      if (creatorDetails?.isFollowed == 0) {
        Toast.fire({
          icon: "error",
          title: "Please follow the creator",
        });
      }else{
      let reviewdata = {
        authToken: userToken,
        rating_review_id: reviewRatings?.myRatingId
      }
      setShowReviewModal(true);
      if (reviewRatings?.isRated == 1) {
        dispatch(GetOneReviewRating(reviewdata))
      }
    }
    }
  };

  const handleEditReview = (reviewId) => {
    if (reviewId) {
      setReviewId(reviewId)
      let reviewdata = {
        authToken: userToken,
        rating_review_id: reviewId
      }
      setShowEditReviewModal(true);
      dispatch(GetOneReviewRating(reviewdata))
    }
  };

  const handleViewReview = (reviewId) => {
    if (reviewId) {
      let reviewdata = {
        authToken: userToken,
        rating_review_id: reviewId
      }

      dispatch(GetOneReviewRating(reviewdata)).then(() => {
        setShowViewReviewModal(true);
      })
    }
  };


  const handleRatingChange = async (newRating) => {
    if (!userToken) {
      setShowLoginModal(true);
      setRating(0)
    }
    else {
      try {
        setRating(newRating)
        const data = {
          authToken: userToken,
          creator_id: id,
          title: "",
          description: "",
          rating: newRating
        };
        const filterData = {
          authToken: userToken,
          id: id,
          filter: filter,
        };
        const response = await dispatch(CreateReviewRating(data)).unwrap();
        if (response?.settings?.success == 1) {
          Toast.fire({
            icon: "success",
            title: response?.settings?.message
          });
          dispatch(GetReviewRating(filterData))
        } else {
          Toast.fire({
            icon: "danger",
            title: response?.settings?.message
          });
        }
      } catch (error) {

      }
    }
  };


  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(`/creatordetail/${id}`);
    }
  };

  const filterLabels = {
    most_helpful: "Most Helpful",
    most_favourable: "Most Favorable",
    most_critical: "Most Critical",
    most_recent: "Most Recent",
    all: "All Ratings",
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown if clicked outside
      }
    };

    // Add event listener for clicks outside of the dropdown
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  return (
    <>
      {isLoading === true ? <Loader /> :
        (
          <>

            <div className="mobileHide">
              <SubHeader />
            </div>
            <div className="main">
              <div className="mbBack desktopHide">
                <button onClick={handleBack} className="back-button">
                  <span>
                    <BiArrowBack style={{ color: "white" }} />
                  </span>
                </button>
                <h3 style={{ margin: '0' }}> RATING & REVIEW</h3>
                <div></div>
              </div>
              <Container>
                <div className="boxWrapper">
                  <div className="innerboxWrapper">
                    <div className={styles.actionBar}>
                      <button onClick={handleBack} className="back-button mobileHide">
                        <span>
                          <BiArrowBack style={{ color: "white" }} />
                        </span>
                      </button>
                      <h2 className="mobileHide">RATING & REVIEW</h2>
                      <div></div>
                    </div>

                    <div className="rating-summary">
                      <div>
                        <div className="rating-score">{Number(reviewRatings?.avgRatings).toFixed(1)}</div>
                        <div className="outOf">Out of 5</div>
                      </div>
                      <div className="rating-stars">
                        {reviewRatings?.ratingPerStar?.length > 0 && [...reviewRatings?.ratingPerStar]
                          .sort((a, b) => b.star - a.star) // Sort the cloned array
                          .map((rating, index) => {
                            const totalRatings = reviewRatings.ratingPerStar.reduce(
                              (total, item) => total + parseInt(item.count || 0, 10),
                              0
                            );
                            const percentage = totalRatings
                              ? (parseInt(rating.count || 0, 10) / totalRatings) * 100
                              : 0;

                            return (
                              <div key={index} className="rating-lines">
                                <div className="star-icons">{"★".repeat(rating.star)}</div>
                                <div className="progress-bar">
                                  <span style={{ width: `${percentage}%` }}></span>
                                </div>
                              </div>
                            );
                          })}
                        <div className="rating-details">{reviewRatings?.totalRatingCount} Ratings</div>
                      </div>
                    </div>
                    <div className="rate-section">
                      <h5>Tap to Rate</h5>
                      <div className="feedback">
                        <div className="tap-rating">
                          {/* <input type="radio" name="rating" id="rating-5" />
                            <label htmlFor="rating-5"><BsFillStarFill style={{ fontSize: "30px" , color : "#F19937" }} /></label>
                            <input type="radio" name="rating" id="rating-4" />
                            <label htmlFor="rating-4"><BiStar style={{ fontSize: "30px" , color : "#F19937" }} /></label>
                            <input type="radio" name="rating" id="rating-3" />
                            <label htmlFor="rating-3"><BiStar style={{ fontSize: "30px" , color : "#F19937" }} /></label>
                            <input type="radio" name="rating" id="rating-2" />
                            <label htmlFor="rating-2"><BiStar style={{ fontSize: "30px" , color : "#F19937" }} /></label>
                            <input type="radio" name="rating" id="rating-1" />
                            <label htmlFor="rating-1"><BiStar style={{ fontSize: "30px" , color : "#F19937" }} /></label> */}
                          {[5, 4, 3, 2, 1].map((star) => (
                            <React.Fragment key={star}>
                              <input
                                type="radio"
                                name="rating"
                                id={`component1-rating-${star}`} // Unique ID
                                checked={rating === star}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  handleRatingChange(star);
                                }}
                              />
                              <label htmlFor={`component1-rating-${star}`}>
                                {rating >= star ? (
                                  <BsFillStarFill style={{ fontSize: "30px", color: "#F19937" }} />
                                ) : (
                                  <BiStar style={{ fontSize: "30px", color: "#F19937" }} />
                                )}
                              </label>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="rate-buttons">
                      <button className="btn-rate-action" onClick={() => handleReviewCreator()}>Write a Review</button>
                      <Link to="/contact-us" className="btn-rate-action" style={{ textDecoration: "none", color: "white" }}>App Support</Link>
                    </div>

                    {/* <div className="sort-options">
                        Sort by
                        <select id="sort"  value={filter} onChange={handleFilterChange}>

                          <option value="most_helpful">Most Helpful</option>
                          <option value="most_favourable">Most Favorable</option>
                          <option value="most_critical">Most Critical</option>
                          <option value="most_recent">Most Recent</option>
                          <option value="all">All Ratings</option>
                        </select>
                      </div> */}
                    <div className="text-end">
                      <div className="sort-dropdown" ref={dropdownRef}>
                        <button className="sort-btn" onClick={toggleSidebar}>
                          Sort by {filterLabels[filter]} <span className="dropdown-icon"><img src={iconSort} alt="" /></span>
                        </button>
                        {isOpen && <ul className="sort-options">
                          <li style={{ color: '#0195F6' }}>Sort By</li>
                          <li onClick={() => handleFilterChange("most_helpful")}>Most Helpful</li>
                          <li onClick={() => handleFilterChange("most_favourable")}>Most Favorable</li>
                          <li onClick={() => handleFilterChange("most_critical")}>Most Critical</li>
                          <li onClick={() => handleFilterChange("most_recent")}>Most Recent</li>
                          <li onClick={() => handleFilterChange("all")}>All Ratings</li>
                        </ul>}
                      </div>
                    </div>

                    {/* <div class="sort-options">
                          <NavDropdown
                            id="nav-dropdown-dark-example"
                            title="Sort by Most Helpful"
                            menuVariant="dark"
                          >
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                              Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                              Separated link
                            </NavDropdown.Item>
                          </NavDropdown>
                      </div> */}

                    <div className="reviews">
                      <Row className="" style={{ display: reviewRatings?.ratingData?.length == 0 && "flex", justifyContent: reviewRatings?.ratingData?.length == 0 && "center" }}>
                        {reviewRatings?.ratingData?.length > 0 ? reviewRatings?.ratingData?.map((review, index) => (
                          <Col sm={6}>
                            <div className="review-item" onClick={(e) => {
                              e.stopPropagation();
                              handleViewReview(review?.iRatingReviewId)
                            }} style={{ cursor: "pointer" }}>
                              <div className="review-header">
                                <div className="user-info">
                                  <img
                                    src={review?.customer?.vProfileImage}
                                    alt="no image"
                                  />
                                  {review?.is_admin_created == 1 ? review?.is_admin_name : review?.customer?.vName}
                                  <div style={{ color: "#AFAFAF", fontSize: "18px", marginTop: "10px", fontWeight: "400" }}>{`${review?.tReviewTitle?.slice(0, maxCharactersforTitle)}${review?.tReviewTitle.length > maxCharactersforTitle ? ' ...' : ''}`}</div>
                                </div>
                                {review?.isEditable == 1 && <div className="edit" onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditReview(review?.iRatingReviewId)
                                }}>Edit</div>}
                              </div>
                              <div className="review-body">
                                {/* <div className="star">★★★★★</div> */}
                                <div className="review-overall-rating">
                                  <StarRating
                                    review={{
                                      rating: review?.dRating,
                                    }}
                                  />
                                  <div className="date mt-1"> {review?.dtCreatedAt} </div>
                                </div>
                                {/* {review?.tReviewDescription || "NA"}{" "} */}
                                <div className="response">
                                  <div className="creator-response">
                                    {`${review?.tReviewDescription?.slice(0, (review?.response ? maxCharacters : maxCharactersWithoutCreatorResponse))}${review?.tReviewDescription.length > (review?.response ? maxCharacters : maxCharactersWithoutCreatorResponse) ? ' ...' : ''}`}
                                  </div>
                                </div>
                                {review?.tReviewDescription?.length > (review?.response ? maxCharacters : maxCharactersWithoutCreatorResponse) && (
                                  <span
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleViewReview(review?.iRatingReviewId)
                                    }}
                                    style={{ color: '#0195F6', cursor: 'pointer', marginLeft: '5px' }}
                                  >
                                    {review?.tReviewDescription?.length > (review?.response ? maxCharacters : maxCharactersWithoutCreatorResponse) ? 'more' : 'more'}
                                  </span>
                                )}
                              </div>
                              {review?.response && <div className="response">
                                <div className="creator-response">
                                  <strong>Creator Response:</strong>
                                  <br />
                                  {review?.response
                                    ? `${review.response.slice(0, maxCharacters)}${review.response.length > maxCharacters ? ' ...' : ''}`
                                    : "-"}
                                </div>
                              </div>}
                              {review?.response?.length > maxCharacters && (
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleViewReview(review?.iRatingReviewId)
                                  }}
                                  style={{ color: '#0195F6', cursor: 'pointer', marginLeft: '5px' }}
                                >
                                  {review?.response != null ? review?.response?.length > maxCharacters ? 'more' : 'more' : ""}
                                </span>
                              )}
                            </div>
                          </Col>
                        )) :
                          (filter && filter != "all" && reviewRatings?.ratingData?.length == 0 ? <div className="review-item review-item-2">

                            <div className="no-review">
                              <img
                                src={Norating}
                                alt="no image"
                              />
                              {/* {/* {review?.customer?.vName}<br /> */}
                              <h4>No Reviews in Selected Filter.</h4>
                            </div>


                          </div> :
                            <div className="review-item review-item-2">

                              <div className="no-review">
                                <img
                                  src={Norating}
                                  alt="no image"
                                />
                                {/* {/* {review?.customer?.vName}<br /> */}
                                <h4>No Rating or Reviews Yet.</h4>
                              </div>

                            </div>
                          )
                        }
                      </Row>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </>
        )}
      <LoginModal
        show={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        id={id}
        userToken={userToken}
      />
      <ReviewModal show={showReviewModal} handleClose={() => setShowReviewModal(false)} id={id} filter={filter} {...(reviewRatings?.isRated === 1 && review ? { data: review } : {})} />
      <ViewReviewModal show={showViewReviewModal} handleClose={() => setShowViewReviewModal(false)} data={review} />
      <EditReviewModal show={showEditReviewModal} handleClose={() => setShowEditReviewModal(false)} id={id} reviewId={reviewId} filter={filter} data={review} setRating={setRating} />
    </>
  );
};
