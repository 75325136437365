import { Col, Container, Nav, Row } from "react-bootstrap";
import styles from "../FooterSection/FooterSection.module.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FooterData1 } from "./FooterData1";
import { useTranslation } from "react-i18next";
import SocialLinks from "./SocialLinks";

// -------function for footer section------------
function FooterSection() {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <section className={styles.FooterSection}>
        <Container>
          <Row>
            <Col lg={12}>
              {location.pathname == "/sign-up" ||
                location.pathname == "/login" ||
                location.pathname == "/about" ||
                location.pathname == "/create-user" ||
                location.pathname == "/signUp_done" ||
                location.pathname == "/new-subscription" ||
                location.pathname == "/reset-password" ? (
                ""
              ) : (
                <div className={styles.socialLinks}>
                  {SocialLinks.map((item, index) => (
                    <Link to={item.link} key={index}>
                      <img src={item.image} alt="social-image" />
                    </Link>
                  ))}
                </div>
              )}

              <div className={styles.bottomSection}>
                {FooterData1.map((item, index) => (
                  <NavLink
                    className={styles.bottomNavLink}
                    to={item.link}
                    key={index}
                  >
                    {item.text}
                    {index === FooterData1.length - 1 ? "" : <> | </>}
                  </NavLink>
                ))}
              </div>
            </Col>
          </Row>
          <div className={styles.copyText}>
            <p>
              {t("COPYRIGHT")} &copy; {currentYear} {t("ALLRIGHTRESERVED")}
            </p>
          </div>
        </Container>
      </section>

    </>
  );
}
export default FooterSection;
