import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReportCreator } from "../../store/slices/CreatorSlice";
import { Toast } from "../../utils/Toaster";
import { useFormik } from "formik";
import * as Yup from "yup";

const ReportModal = ({ show, handleClose, id }) => {
    const dispatch = useDispatch();
    const { userToken } = useSelector((state) => state.user);

    // Formik initialization
    const formik = useFormik({
        initialValues: {
            title: "",
            report: ""
        },
        validationSchema: Yup.object({
            title: Yup.string().trim().required("Title is required."),
            report: Yup.string().trim().required("Description is required.")
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const data = {
                authToken: userToken,
                creator_id: id,
                title: values.title,
                description: values.report
            };

            try {
                const response = await dispatch(ReportCreator(data)).unwrap();
                if (response?.settings?.success == 1) {
                    Toast.fire({
                        icon: "success",
                        title: response?.settings?.message
                    });
                    formik.resetForm();
                    handleClose();
                } else {
                    Toast.fire({
                        icon: "danger",
                        title: response?.settings?.message
                    });
                }
            } catch (error) {
                Toast.fire({
                    icon: "danger",
                    title: "Failed to submit report. Please try again."
                });
            } finally {
                setSubmitting(false);
            }
        }
    });

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className="report-modal"
        >
            <Modal.Header className="modal-header-custom">
                <Button
                    onClick={() => {
                        handleClose();
                        formik.resetForm();
                    }}
                    className="cancel-btn"
                    variant="link"
                    disabled={formik.isSubmitting}
                >
                    Cancel
                </Button>
                <Modal.Title className="modal-title-custom">Report</Modal.Title>
                <Button
                    className="submit-btn"
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting}
                >
                    {formik.isSubmitting ? "Submitting..." : "Submit"}
                </Button>
            </Modal.Header>
            <Modal.Body className="modal-body-custom">
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <div className="form-container">

                        <Form.Group className="form-group" controlId="formTitle">
                            {/* <Form.Label className="form-label-custom">Title <span style={{ color: "#FA626B" }}>*</span></Form.Label> */}
                            {formik?.values?.title == "" && <span style={{ color: "#FA626B" , left : "72px" , position : "absolute" , marginTop :" 10px"  }}>*</span>}
                            <Form.Control
                                name="title"
                                type="text"
                                className={`form-input ${formik.touched.title && formik.errors.title ? "is-invalid" : ""}`}
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                                placeholder="Title"
                                style={{ color: "white" }}
                            />
                            {formik.touched.title && formik.errors.title && (
                                <div className="invalid-feedback">{formik.errors.title}</div>
                            )}
                        </Form.Group>

                        <Form.Group className="form-group" controlId="formReport">
                            {/* <Form.Label className="form-label-custom">Report <span style={{ color: "#FA626B" }}>*</span></Form.Label> */}
                            {formik?.values?.report == "" &&<span style={{ color: "#FA626B" , left : "90px" , position : "absolute" , marginTop :" 10px"  }}>*</span>}
                            <Form.Control
                                name="report"
                                as="textarea"
                                className={`form-input ${formik.touched.report && formik.errors.report ? "is-invalid" : ""}`}
                                rows={6}
                                value={formik.values.report}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                                placeholder="Report"
                                style={{ borderBottom: "0" }}
                            />
                            {formik.touched.report && formik.errors.report && (
                                <div className="invalid-feedback">{formik.errors.report}</div>
                            )}
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ReportModal;
