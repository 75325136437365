import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../Subscription/SubscriptionDetail.module.css";
import "../../../assets/Styles/Common.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import paypal from "../../../assets/Images/paypal.svg";
import { useEffect, useState } from "react";
import visa from "../../../assets/Images/paymentmethods.png";
import stripe from "../../../assets/Images/stripeLogo.png";
import SubHeader from "../../HeaderSection/SubHeader";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../../utils/Toaster";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { useTranslation } from "react-i18next";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { PLAN, TRANSACTION_TYPE, SESION_TRANSACTION_TYPE } from "../../../utils/Constants";
import Loader from "../../../utils/Loader/Loader";
import $ from "jquery";
import Swal from "sweetalert2";


//--------function for paypal order create on sale-----------
function FitnessPlanPaypal() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const details = location.state;
    const [orderID, setOrderID] = useState("");
    const [planDetails, setPlanDetails] = useState("");
    const { userToken, currentUser } = useSelector((state) => state.user);
    const { followedCreator } = useSelector((state) => state.creatorList);
    const [isLoading, setIsLoading] = useState(false);
    const plan_id = localStorage.getItem("plan_id")
    const plan_type = localStorage.getItem("plan_type")
    const queryParams = new URLSearchParams(window.location.search);
    const sessionId = queryParams.get("session_id");
    const paymentType = queryParams.get("type");

    // --------function for open component top of the page ----------
    useEffect(() => {
        $("document").ready(function () {
            $(window).scrollTop(0);
        });
    }, []);

    //---------Design paypal button-----------
    const buttonStyle = {
        layout: 'horizontal',
        color: 'blue',
        shape: 'pill',
        label: 'buynow',
        size: 'medium',
        tagline: false,
        height: 45,
    };

    // ---------function for workout plan details api calling---------- 
    useEffect(() => {
        const id = details && details.planDetails && details.planDetails.workout_id || plan_id;
        const type = details && details.planDetails && details.planDetails.plan_type == "workout_plan" || plan_type == "workout_plan" ? PLAN.WORKOUT_PLAN : PLAN.STREAM_WORKOUT_PLAN;
        async function getPlanDetails() {
            setIsLoading(true);
            const workoutPlan = await SublyApi.workoutPlanDetails(id, type, (currentUser && currentUser.customer_id) ? currentUser.customer_id : "");
            setIsLoading(false);
            if (workoutPlan && workoutPlan.settings && workoutPlan.settings.success && workoutPlan.settings.success == STATUS_CODES.SUCCESS_API) {
                setPlanDetails(workoutPlan && workoutPlan.settings.data);
            }
        }
        getPlanDetails();
    }, []);


    useEffect(() => {
        const handlePaymentSuccess = async () => {
            if (!sessionId) return;

            let data = {
                session_id: sessionId,
                transaction_type: TRANSACTION_TYPE.SALE,
                transaction_sub_type: planDetails?.plan_type == "workout_plan" || plan_type == "workout_plan" ? PLAN.WORKOUT_PLAN : PLAN.STREAM_WORKOUT_PLAN,
                plan_type: planDetails?.plan_type || plan_type,
                transaction_sub_type_id: planDetails.workout_id || plan_id
            };

            try {
                setIsLoading(true);

                const res = await SublyApi.planPaymentSuccess(data, userToken);

                if (res.settings.success == 1) {
                    await SublyApi.salePlanInfo(userToken, data?.transaction_sub_type_id, (currentUser && currentUser.customer_id) ? currentUser.customer_id : "")
                    Toast.fire({
                        icon: "success",
                        title: res.settings.message,
                    });
                    //   navigate("/subscription-tab");
                    Swal.fire({
                        // title: `THANK YOU FOR YOUR PURCHASE!`,
                        html: `
                        <p style="text-align: center;">Your plan has been successfully added to “My Profile > My Plan” in the 3fitstyle app. Read below for Steps to Access Your Plan:</p>
                        
                        <p style="text-align: center;"><b style="color : #fff; font-weight : 900;">Log in to the 3FIT App</b><br>
                        • If you don’t have the app, download it from the <a style="color : #D5D4D4;" href="https://apps.apple.com/us/app/3fitstyle/id6449520820" target="_blank">App Store</a> or <a style="color : #D5D4D4;" href="https://play.google.com/store/apps/details?id=com.threefitstyle&pcampaignid=web_share" target="_blank">Google Play</a>.</p>
                
                        <p style="text-align: center;"><b style="color : #fff; font-weight : 900;">Use the Same Login Method</b><br>
                        • If you purchased using Google, sign in with Google on the app.<br>
                        • If you used a username and password, log in with the same credentials.</p>
                
                        <p style="text-align: center;"><b style="color : #fff; font-weight : 900;">Find Your Plan</b><br>
                        • Go to My Profile > My Plan in the app to view your workout plan.</p>
                
                        <p style="text-align: center;"><b style="color : #fff; font-weight : 900;">⚠️ Important:</b><br>
                        <span style="font-weight: normal;">Content is only accessible in the 3fitstyle app. This website is for payments only, kindly.</span></p>
                
                        <p style="text-align: center;">Thank you!<br>3Fitstyle Team</p>
                    `,
                        icon: "success",
                        allowOutsideClick: false,
                        customClass: "swalAlertBox",
                        backdrop: true,
                        didOpen: () => {
                            document.querySelectorAll(".custom-list").forEach(el => {
                                el.style.listStyleType = "none"; // Removes default bullets
                                el.style.paddingLeft = "50px"; // Aligns list items properly
                            });
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/plan-tab");
                        }
                    });
                } else {
                    Toast.fire({
                        icon: "error",
                        title: "Payment failed. Please try again.",
                    });
                }
            } catch (error) {
                console.error("Payment verification failed:", error);
                Toast.fire({
                    icon: "error",
                    title: "Something went wrong. Please try again.",
                });
            } finally {
                setIsLoading(false);
            }
        };

        // Handle Payment Cancellation
        if (paymentType == "cancel") {
            Toast.fire({
                icon: "error",
                title: "Payment failed. Please try again.",
            });
            return;
        }

        handlePaymentSuccess();
    }, [sessionId]);




    async function ManageSessionExpire() {
        const requestData = new FormData();
        requestData.append("transaction_type", SESION_TRANSACTION_TYPE.SALE);
        requestData.append("plan_id", planDetails && planDetails.workout_id);
        requestData.append("plan_type", planDetails && planDetails.plan_type == "workout_plan"
            ? PLAN.WORKOUT_PLAN
            : PLAN.STREAM_WORKOUT_PLAN);
        return await SublyApi.sessionManage(requestData, userToken).then((response) => {
            if (response.settings.success == STATUS_CODES.SUCCESS_API) {
                return true;
            }
            else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
                Toast.fire({
                    icon: "error",
                    title: t("SESSION_EXPIRE"),
                });
                dispatch(userLogoutClear());
                navigate("/login");
            } else {
                return false;
            }
        }
        );
    }

    //---------creates a paypal order-----------
    const createOrder = async (data, actions) => {
        const res = await ManageSessionExpire();
        if (res) {
            return actions.order
                .create({
                    purchase_units: [
                        {
                            amount: {
                                currency_code: planDetails && planDetails.currency,
                                value: planDetails && planDetails.amount,
                            },
                        },
                    ],
                })
                .then((orderID) => {

                    setOrderID(orderID);
                    return orderID;
                });
        } else {
            return false;
        }
    };


    //--------Save details of sale purchase api integration--------- 
    const onApprove = (data, actions) => {
        return actions.order.capture().then(async function (paymentData) {
            // Handle successful payment
            let requestData = new FormData();
            requestData.append("transaction_type", TRANSACTION_TYPE.SALE);
            requestData.append("transaction_sub_type", planDetails && planDetails.plan_type == "workout_plan" ? PLAN.WORKOUT_PLAN : PLAN.STREAM_WORKOUT_PLAN);
            requestData.append("plan_type", planDetails && planDetails.plan_type);
            requestData.append("transaction_sub_type_id", planDetails && planDetails.workout_id);
            requestData.append("order_id", paymentData.id);
            requestData.append("transaction_id", paymentData.purchase_units[0].payments.captures[0].id);
            requestData.append("payment_json", JSON.stringify(paymentData));
            await SublyApi.salePurchase(requestData, userToken).then(async (response) => {
                if (response.success == STATUS_CODES.SUCCESS_API) {
                    if (requestData.get("plan_type") == "workout_plan") {
                        await SublyApi.salePlanInfo(userToken, requestData.get('transaction_sub_type_id'), (currentUser && currentUser.customer_id) ? currentUser.customer_id : "").then(async (response) => {
                            if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
                                Swal.fire({
                                    title: `THANK YOU FOR YOUR PURCHASE!`,
                                    html: `
                        <p style="text-align: center;">Your plan has been successfully added to “My Profile > My Plan” in the 3fitstyle app. Read below for Steps to Access Your Plan:</p>
                        
                        <p style="text-align: center;"><b style="color : #fff; font-weight : 900;">Log in to the 3FIT App</b><br>
                        • If you don’t have the app, download it from the <a style="color : #D5D4D4;" href="https://apps.apple.com/us/app/3fitstyle/id6449520820" target="_blank">App Store</a> or <a style="color : #D5D4D4;" href="https://play.google.com/store/apps/details?id=com.threefitstyle&pcampaignid=web_share" target="_blank">Google Play</a>.</p>
                
                        <p style="text-align: center;"><b style="color : #fff; font-weight : 900;">Use the Same Login Method</b><br>
                        • If you purchased using Google, sign in with Google on the app.<br>
                        • If you used a username and password, log in with the same credentials.</p>
                
                        <p style="text-align: center;"><b style="color : #fff; font-weight : 900;">Find Your Plan</b><br>
                        • Go to My Profile > My Plan in the app to view your workout plan.</p>
                
                        <p style="text-align: center;"><b style="color : #fff; font-weight : 900;">⚠️ Important:</b><br>
                        <span style="font-weight: normal;">Content is only accessible in the 3fitstyle app. This website is for payments only, kindly.</span></p>
                
                        <p style="text-align: center;">Thank you!<br>3Fitstyle Team</p>
                    `,
                                    icon: "success",
                                    allowOutsideClick: false,
                                    customClass: "swalAlertBox",
                                    backdrop: true,
                                    didOpen: () => {
                                        document.querySelectorAll(".custom-list").forEach(el => {
                                            el.style.listStyleType = "none"; // Removes default bullets
                                            el.style.paddingLeft = "50px"; // Aligns list items properly
                                        });
                                    }
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        navigate("/plan-tab");
                                    }
                                });
                            } else if (response && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
                                Toast.fire({
                                    icon: "error",
                                    title: t("SESSION_EXPIRE"),
                                });
                                dispatch(userLogoutClear());
                                navigate("/login");
                            }
                        });
                    } else {
                        Swal.fire({
                            title: `THANK YOU FOR YOUR PURCHASE!`,
                            html: `
                                <p>Your plan has been successfully added to “My Profile > My Plan” in the 3fitstyle app.</p>
                                
                                <p>Next Steps to Access Your Plan:</p>
                                <ul>
                                    <li><b>1. Log in to the 3fitstyle App</b><br>
                                        <b>• If you don’t have the app, download it from the <b>App Store or Google Play</b>.
                                    </li>
                                    <li><b>2. Use the Same Login Method</b><br>
                                        <b>• If you purchased using Google, sign in with Google on the app.</b><br>
                                        <b>• If you used a username and password, log in with the same credentials.</b>
                                    </li>
                                    <li><b>3. Find Your Plan</b><br>
                                        <b>• Go to My Profile > My Plan in the app to view your workout plan.</b>
                                    </li>
                                </ul>
                        
                                <p>⚠ Important: Content is only accessible in the 3FIT app. This website is for payments only.</p>
                                
                                <p>Thank you!<br>3Fitstyle Team</p>
                            `,
                            icon: "success",
                            allowOutsideClick: false,
                            customClass: "swalAlertBox",
                            backdrop: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate("/plan-tab");
                            }
                        });
                    }
                } else if (response && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
                    Toast.fire({
                        icon: "error",
                        title: t("SESSION_EXPIRE"),
                    });
                    dispatch(userLogoutClear());
                    navigate("/login");
                } else {
                    Toast.fire({
                        icon: "error",
                        title: response.message,
                    });
                }
            });
        });
    };


    const makePayment = async () => {
        setIsLoading(true)
        const currentUrl = new URL(window.location.href);
        const params = new URLSearchParams(currentUrl.search);
        params.delete("type");

        const newRedirectUrl = `${currentUrl.origin}${currentUrl.pathname}${params.toString() ? "?" + params.toString() : ""}`;

        let data = {
            id: details?.planDetails?.workout_id || planDetails?.workout_id,
            type: details?.planDetails?.plan_type || planDetails?.plan_type,
            platform_type: "web",
            redirect_url: newRedirectUrl, // Updated URL without "type"
        };

        const res = await SublyApi.stripeCheckout(data, userToken)
        if (res.settings) {
            setIsLoading(false)
            localStorage.setItem("plan_id", details?.planDetails?.workout_id || planDetails?.workout_id)
            localStorage.setItem("plan_type", details?.planDetails?.plan_type || planDetails?.plan_type)
            window.location.href = res.settings.data.url
        }
    }


    return (
        <>
            {isLoading === true ? <Loader /> :
                (<>


                    <SubHeader />
                    <div className="main">

                        {planDetails ?
                            <Container>
                                <div className="boxWrapper">
                                    <div className="innerboxWrapper">
                                        <div className={styles.subDetail}>
                                            <div className={styles.subscriptionPayBox}>
                                                <div className={styles.summaryTexts}>
                                                    <h2>{t("SALE_SUMMARY")}</h2>
                                                </div>
                                                <div className={styles.payTexts}>
                                                    <p style={{ textAlign: "center" }}>
                                                        {t("PLAN_TEXT")}
                                                        <br />
                                                        {planDetails?.workout_image && (
                                                            <img
                                                                src={planDetails.workout_image}
                                                                alt={planDetails?.workout_name}
                                                                style={{ width: "50px", height: "60px", borderRadius: "5px", objectFit: "cover", display: "block", margin: "5px auto" }}
                                                            />
                                                        )}
                                                    </p>
                                                    <div className={styles.planDetails} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                        {/* {planDetails?.workout_image && (
                                                            <img
                                                                src={planDetails.workout_image}
                                                                alt={planDetails?.workout_name}
                                                                style={{ width: "50px", height: "60px", borderRadius: "5px", objectFit: "cover" }}
                                                            />
                                                        )} */}
                                                        <p
                                                        // className={styles.planName}
                                                        >{planDetails?.workout_name || t("EXCLUSIVE")}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.timeTexts}>
                                                    <p>{t("PRICE_TEXT")}</p>
                                                    <p>{planDetails && planDetails.currency_symbol}{planDetails && planDetails.amount}</p>
                                                </div>
                                                <div className={styles.priceTexts}>
                                                    <p>{t("TOTAL_PRICE")}</p>
                                                    <p>{planDetails && planDetails.currency_symbol}{planDetails && planDetails.amount}</p>
                                                </div>
                                            </div>

                                            <div className={styles.subscriptionDetailText}>
                                                <p>
                                                    {t("SUBSCRIPTION_NOTE")}<span> {t("SUBSCRIPTION_NOTE_PARA")}</span>
                                                </p>
                                            </div>

                                            {/* {planDetails && planDetails.acess_level && planDetails.acess_level == ACCESS_LEVEL.LOCK ? */}

                                            {planDetails &&
                                                planDetails.amount == "0" ?
                                                "" :
                                                <>
                                                    {followedCreator?.is_main_app_show == "No" &&
                                                        <>
                                                            <div className={styles.backButton}>
                                                                <div className={styles.payPal}>
                                                                    <PayPalScriptProvider options={{ clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
                                                                        <PayPalButtons
                                                                            createOrder={createOrder}
                                                                            onApprove={onApprove}
                                                                            style={buttonStyle}
                                                                        />
                                                                    </PayPalScriptProvider>
                                                                </div>
                                                            </div>
                                                            <div className={styles.payPalImg}>
                                                                <img src={visa} alt="logo" />
                                                            </div>
                                                            <div className={styles.poweredImg}>
                                                                <h4>{t("POWERED_BY")}</h4>
                                                                <img src={paypal} alt="logo" />
                                                            </div>
                                                            <h4 className="text-center text-white">
                                                                OR
                                                            </h4>
                                                            <div className={styles.backButton}>
                                                                <button onClick={() => makePayment()} className={styles.paymentButton} style={{ border: "none" }}>
                                                                    <img src={stripe} alt="Stripe Logo" className={styles.stripeLogo} />
                                                                    Buy Now
                                                                </button>
                                                            </div>
                                                            <div className={styles.payPalImg}>
                                                                <img src={visa} alt="logo" />
                                                            </div>
                                                            <div className={styles.poweredImg}>
                                                                {/* <h4>{t("POWERED_BY")}</h4> */}
                                                                {/* <img src={paypal} alt="logo" /> */}
                                                            </div>
                                                        </>
                                                    }
                                                    {followedCreator?.is_main_app_show == "Yes" &&
                                                        <>
                                                            <div className={styles.backButton}>
                                                                <button onClick={() => makePayment()} className={styles.paymentButton} style={{ border: "none" }}>
                                                                    <img src={stripe} alt="Stripe Logo" className={styles.stripeLogo} />
                                                                    Buy Now
                                                                </button>
                                                            </div>
                                                            <div className={styles.payPalImg}>
                                                                <img src={visa} alt="logo" />
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            }
                                            {/* : ""} */}
                                        </div>
                                    </div>
                                </div>
                            </Container> : ""}
                    </div>
                </>)}
        </>

    );
}
export default FitnessPlanPaypal;
