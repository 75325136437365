import { Container, Row, Col, Form } from "react-bootstrap";
import styles from "./Profile.module.css";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../assets/Styles/Common.css";
import UserProfile from "./UserProfile";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customerDeatail, setProfileTabValue, userLogoutClear } from "../../store/slices/UserSlice";
import Loader from "../../utils/Loader/Loader";
import moment from 'moment';
import { STATUS_CODES } from "../../utils/StatusCode";
import HeaderSub from "../HeaderSection/HeaderSub";
import SubscriptionData from "../Subscription/SubscriptionData";
import $ from "jquery";
import PurchaseData from "../Plan/PurchaseData/PurchaseData";
import SubHeader from "../HeaderSection/SubHeader";
import SimpleSlider from "./FollowedCreatorSlider";
import { getFollowedCreator, goTOCreatorApp } from "../../store/slices/CreatorSlice";
import { getSubscriptionData } from "../../store/slices/SubscriptionSlice";
import { BsChevronCompactLeft } from "react-icons/bs";

// --------function for get profile details--------
function SubscriptionTab() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userToken, isLoading, updateUser, salePlanVisible, profileTabValue } = useSelector((state) => state.user);
    const { purchasePlanList } = useSelector((state) => state.planList);
    const { followedCreator } = useSelector((state) => state.creatorList);
    const [customerData, setCustomerData] = useState("");
    const { id } = useParams();
    const sidebarRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);

    // Effect to handle resize event and update the isMobile state
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 575);
        };

        // Add resize event listener
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };


    let defaultTabValue = "info";
    if (id == 1) {
        defaultTabValue = "subscription";
    } else if (id == 2) {
        defaultTabValue = "sale";
    } else if (id == 3) {
        defaultTabValue = "info";
    } else {
        if (!id) {
            defaultTabValue = profileTabValue;
        }
    }
    // const defaultTabValue = (id == 1) ? "subscription" : ((id == 2) ? "sale" : "info");
    const [defaultTab, setDefaultTab] = useState(defaultTabValue);

    // --------function for open component top of the page ----------
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // --------function for open component top of the page ----------
    useEffect(() => {
        $("document").ready(function () {
            $(window).scrollTop(0);
        });
    }, []);

    // --------function for get profile details api call--------
    useEffect(() => {
        dispatch(getFollowedCreator(userToken))
        // dispatch(customerDeatail(userToken)).then((responsejson) => {
        //   const response = responsejson.payload;
        //   if (response && response.settings && response.settings.success && response.settings.success == STATUS_CODES.SUCCESS_API) {
        //     setCustomerData(response.data[0]);
        //   }
        //   else if (response && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
        //     // Toast.fire({
        //     //   icon: "error",
        //     //   title: t("SESSION_EXPIRE"),
        //     // });
        //     dispatch(userLogoutClear())
        //     navigate("/login");
        //   }
        // })
    }, [])

    const handleNavigate = () => {
        navigate("/creator")
    }

    const handleNavigateTabs = (key) => {
        if (key == "info") {
            navigate("/profile");
        }
        else if (key == "subscription") {
            navigate("/subscription-tab")
        }
        else if (key == "plan") {
            navigate("/plan-tab")
        }
        else if (key == "followed") {
            navigate("/followedCreator-tab")
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target) &&
                isSidebarOpen
            ) {
                setSidebarOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isSidebarOpen]);

    const handleNavigateToWebsite = () => {
        let data = {
            authToken: userToken,
            creator_id: followedCreator?.get_admin_creators?.iAdminId
        }
        dispatch(goTOCreatorApp(data))
    }


    return (
        <>
            <HeaderSub />
            {isLoading === true ? <Loader /> : ""}
            <div className="main">
                <Container>
                    <div className="boxWrapper">
                        <div className="innerboxWrapper">
                            <div className={`sidebar ${isSidebarOpen ? "open" : ""}`} ref={sidebarRef}>
                                <div className="menu-item" onClick={() => handleNavigateTabs("info")}>
                                    Basic Info
                                </div>
                                <div className="menu-item" onClick={() => handleNavigateTabs("subscription")}>
                                    Subscription
                                </div>
                                {(salePlanVisible && salePlanVisible.value == '2' && purchasePlanList == "") ?
                                    "" : <div className="menu-item" onClick={() => handleNavigateTabs("plan")}>
                                        My Plan
                                    </div>}
                                <div className="menu-item" onClick={() => handleNavigateTabs("followed")}>
                                    Followed Creators
                                </div>
                            </div>

                            {/* Toggle Button */}
                            <button className="sidebar-toggle" onClick={toggleSidebar}>
                                {!isSidebarOpen && <BsChevronCompactLeft />}</button>
                            <UserProfile />


                            <div className="profileTabs">
                                <Tabs
                                    defaultActiveKey="subscription"
                                    id="uncontrolled-tab-example"
                                    className="tabSection tabClass"
                                    onSelect={(k) => handleNavigateTabs(k)}
                                    style={{ display: isMobile && "none" }}
                                >
                                    <Tab eventKey="info" title="Basic Info">
                                        <div className="ManageTopSpace">
                                            <div className={styles.basicInfo}>
                                                <Form>
                                                    <div className={styles.userInfo}>
                                                        <Form.Group
                                                            className="mb-2"
                                                        >
                                                            <Form.Label className={styles.formLabel}>
                                                                {t("PROFILE_FULLNAME")}
                                                            </Form.Label>
                                                            <div className="capitalLetters">
                                                                <p>{updateUser && updateUser.customer_full_name && updateUser.customer_full_name.charAt(0).toUpperCase() + updateUser.customer_full_name.slice(1).toLowerCase()}</p>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className={styles.userInfo}>
                                                        <Form.Group
                                                            className="mb-2"
                                                        >
                                                            <Form.Label className={styles.formLabel}>
                                                                {t("PROFILE_USERNAME")}
                                                            </Form.Label>
                                                            <p>{updateUser && updateUser.customer_user_name && updateUser.customer_user_name}</p>
                                                        </Form.Group>
                                                    </div>
                                                    <div className={styles.userInfo}>
                                                        <Form.Group
                                                            className="mb-2"
                                                        >
                                                            <Form.Label className={styles.formLabel}>
                                                                {t("EMAIL")}
                                                            </Form.Label>
                                                            <p>{updateUser && updateUser.customer_email && updateUser.customer_email}</p>
                                                        </Form.Group>
                                                    </div>

                                                    <div className={styles.userInfo}>
                                                        <Form.Group
                                                            className="mb-2"
                                                        >
                                                            <Form.Label className={styles.formLabel}>
                                                                {t("PROFILE_DOB")}
                                                            </Form.Label>
                                                            <p>
                                                                {updateUser && updateUser.dob ?
                                                                    <>
                                                                        {moment(updateUser.dob).format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                    </> : "NA"
                                                                }
                                                            </p>
                                                        </Form.Group>
                                                    </div>
                                                    <div className={styles.userInfo}>
                                                        <Form.Group
                                                            className="mb-2"
                                                        >
                                                            <Form.Label className={styles.formLabel}>
                                                                {t("COUNTRY_TEXT")}
                                                            </Form.Label>
                                                            <p>{updateUser && updateUser.customer_country_name ? updateUser.customer_country_name : "NA"}</p>
                                                        </Form.Group>
                                                    </div>

                                                    <div className={styles.userInfo}>
                                                        <Form.Group
                                                            className="mb-2"
                                                        >
                                                            <Form.Label className={styles.formLabel}>
                                                                {t("PROFILE_GENDER")}
                                                            </Form.Label>
                                                            <p>{updateUser && updateUser.customer_gender && updateUser.customer_gender}</p>
                                                        </Form.Group>
                                                    </div>

                                                    <div className={styles.userInfo}>
                                                        <Form.Group
                                                            className="mb-2"
                                                        >
                                                            <Form.Label className={styles.formLabel}>
                                                                {t("PROFILE_HEIGHT")}
                                                            </Form.Label>
                                                            <p>{updateUser && updateUser.customer_height && (updateUser.customer_height != "" && updateUser.customer_height != 'NaN') ? updateUser.customer_height + " CM" : "NA"}</p>
                                                        </Form.Group>
                                                    </div>

                                                    <div className={styles.userInfo}>
                                                        <Form.Group
                                                            className="mb-2"
                                                        >
                                                            <Form.Label className={styles.formLabel}>
                                                                {t("PROFILE_WEIGHT")}
                                                            </Form.Label>
                                                            <p>{updateUser && updateUser.customer_weight && (updateUser.customer_weight != "" && updateUser.customer_weight != "0") ? updateUser.customer_weight + " KG" : "NA"}</p>
                                                        </Form.Group>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="subscription" title="Subscription">
                                        <SubscriptionData />
                                    </Tab>
                                    {(salePlanVisible && salePlanVisible.value == '2' && purchasePlanList == "") ?
                                        "" :
                                        <Tab eventKey="plan" title="My Plan">
                                            <div className="ManageTopSpace">
                                                <PurchaseData />
                                            </div>
                                        </Tab>
                                    }
                                    <Tab eventKey="followed" title="Followed Creators">
                                        <SimpleSlider data={followedCreator?.data} />
                                        <div className={styles.switchCreator}>
                                            {followedCreator?.is_main_app_show == "Yes" && <Link className={styles.btnSwitch} to={`https://staging.3fitstyle.com/frontend?token=${userToken}`} target="_blank" onClick={handleNavigateToWebsite}>Switch Back to 3fitstyle website</Link>}
                                            <button className={styles.btnSwitch} onClick={handleNavigate}>Switch Creators</button>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>

                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}
export default SubscriptionTab;

