import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const PrevArrow1 = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className="prevArrow1"
      // style={{ width:"30px", height:"30px", backgroundColor:"rgb(255 255 255 / 63%)", position:"absolute", top:"40%", right:"0" ,borderRadius:"50px",lineHeight:"30px", zIndex:"9", marginRight:"10" , cursor:"pointer"}}
      onClick={onClick}
    >
      {"<"}
    </div>
  );
};

const NextArrow1 = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className="nextArrow1"
      // style={{ width:"30px", height:"30px", backgroundColor:"rgb(255 255 255 / 63%)", position:"absolute", top:"40%",left:"10", borderRadius:"50px",zIndex:"9", lineHeight:"30px", cursor:"pointer"}}
      onClick={onClick}
    >
      {">"}
    </div>
  );
};


export default function SimpleSlider({ data }) {

  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: data.length > 2,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <PrevArrow1 />,
    nextArrow: <NextArrow1 />,
    autoplay: true,
    autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          arrows: false
        }
      }

    ]

  };

  const handleNavigate = (id) => {
    navigate(`/detailpage/${id}`)
  }

  return (
    <Slider {...settings} className="sliderThree">
      {data?.map((item, index) => {
        if (item?.eIsPrivate == "Yes") {
          return (
            <div key={index} className="sliderThreeWrapper">
              <div className="sliderThree_Box">
                <img src={item?.vCreatorCategoryImage} style={{ cursor: "pointer" }} onClick={() => {
                  handleNavigate(item?.iCreatorCategoryId)
                }} />
                <div className="sliderThree_Content">
                  {item?.eTitleShowInApp == "Yes" && <h3 onClick={(e) => {
                    e.stopPropagation();
                    handleNavigate(item?.iCreatorCategoryId)
                  }}>{item?.vCreatorCategoryTitle}</h3>}
                  {item?.eSubtitleShowInApp == "Yes" && <h4 onClick={(e) => {
                    e.stopPropagation();
                    handleNavigate(item?.iCreatorCategoryId)
                  }}>{item?.vCreatorCategorySubTitle}</h4>}
                </div>
              </div>
            </div>
          )
        }
      })}
    </Slider>
  );
}