import { Col, Container, Row } from "react-bootstrap";
import styles from "../../../appComponents/Plan/Plan.module.css";
import SubHeader from "../../HeaderSection/SubHeader";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { STATUS_CODES } from "../../../utils/StatusCode";
import SublyApi from "../../../helpers/Api";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../utils/Loader/Loader";
import $ from "jquery";
import { Toast } from "../../../utils/Toaster";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { BiArrowBack } from "react-icons/bi";

//-----function for plan image details---------- 
function PurchasePlanDetail() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [planDetails, setPlanDetails] = useState("");
    const { id, type, trasId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { userToken } = useSelector((state) => state.user);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);

    // Effect to handle resize event and update the isMobile state
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 575);
        };

        // Add resize event listener
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // --------function for open component top of the page ----------
    useEffect(() => {
        $("document").ready(function () {
            $(window).scrollTop(0);
        });
    }, []);

    // ---------function for workout plan details api calling---------- 
    useEffect(() => {
        async function getPlanDetails() {
            setIsLoading(true);
            const details = await SublyApi.planPurchaseDetails(id, type, trasId, userToken);
            setIsLoading(false);
            if (details && details.settings && details.settings.success && details.settings.success == STATUS_CODES.SUCCESS_API) {
                setPlanDetails(details && details.settings.data);
            } else if (details.settings.success == STATUS_CODES.INVALID_TOKEN) {
                Toast.fire({
                    icon: "error",
                    title: t("SESSION_EXPIRE"),
                });
                dispatch(userLogoutClear());
                navigate("/login");
            }
        }
        getPlanDetails();
    }, []);

    const handleBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate("/creator");
        }
    };

    return (
        <>
            {isLoading === true ? <Loader /> :
                (
                    <>
                        <div className="mobileHide">
                            <SubHeader />
                        </div>

                        <div className="main paddingNone">
                            <div className="mbBack desktopHide">
                                <button onClick={handleBack} className="back-button">
                                    <span>
                                        <BiArrowBack style={{ color: "white" }} />
                                    </span>
                                </button>
                            </div>
                            <Container>
                                <div className="boxWrapper" style={{ padding: !isMobile && "30px 0px 60px" }}>
                                    <div className='innerboxWrapper'>
                                        <div className={styles.actionBar}>
                                            <button onClick={handleBack} className="back-button mobileHide">
                                                <span>
                                                    <BiArrowBack style={{ color: "white" }} />
                                                </span>
                                            </button>
                                        </div>
                                        {planDetails ?
                                            <div className="img-content-wrapper">
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className={styles.creatorSingleImage}>
                                                                    <img src={planDetails.plan_image} alt="plan-image" />

                                                                    {planDetails &&
                                                                        planDetails.amount == "0" ?
                                                                        <div className={styles.lockIconDetail}>
                                                                            <p>{t("FREE")}</p>
                                                                        </div> : <div className={styles.lockIconDetail}>
                                                                            <span>{planDetails.currency_symbol}{planDetails.amount}</span>
                                                                        </div>}

                                                                    {planDetails && planDetails.show_in_app && planDetails.show_in_app.toLocaleLowerCase() == "no" ? "" : (
                                                                        planDetails.plan_name && (
                                                                            <div className={styles.creatorSingleImageText}>
                                                                                <h2>{planDetails.plan_name}</h2>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className={styles.planDetailRight}>
                                                                    {planDetails.plan_level && (
                                                                        <>
                                                                            <div className={styles.detailsOptions}>
                                                                                <h2>{planDetails.plan_level}</h2>
                                                                                <p>{t("FITNESS_LEVEL")}</p>
                                                                            </div>
                                                                            {(planDetails.good_for_name == "" && planDetails.equipment_name == "" && planDetails.description == "") ?
                                                                                "" :
                                                                                <div className={styles.borderSet}>
                                                                                </div>}
                                                                        </>
                                                                    )}

                                                                    {planDetails.good_for_name && (
                                                                        <>
                                                                            <div className={styles.detailsOptions}>
                                                                                <h2>{t("GOOD_FOR")}</h2>
                                                                                <p>{planDetails.good_for_name}</p>
                                                                            </div>
                                                                            {(planDetails.equipment_name == "" && planDetails.description == "") ?
                                                                                "" :
                                                                                <div className={styles.borderSet}>
                                                                                </div>}
                                                                        </>
                                                                    )}

                                                                    {planDetails.equipment_name && (
                                                                        <>
                                                                            <div className={styles.detailsOptions}>
                                                                                <h2>{t("EQUIPMENT")}</h2>
                                                                                <p>{planDetails.equipment_name}</p>
                                                                            </div>
                                                                            {planDetails.description == "" ?
                                                                                "" :
                                                                                <div className={styles.borderSet}>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    )}

                                                                    {planDetails.description &&
                                                                        <div className={`${styles.detailsOptions} ${styles.endOption}`}>
                                                                            <h2>{t("OVERVIEW")}</h2>
                                                                            <p>{planDetails.description}</p>
                                                                        </div>
                                                                    }

                                                                    <div className={styles.purchaseButton}>
                                                                        <button>
                                                                            {t("PURCHASED")}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                            </div> : ""}
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </>
                )}
        </>
    );
}
export default PurchasePlanDetail;