import loader from "../../assets/Images/loader.gif";
import styles from "./Loader.module.css";

//-----function for loader------
function Loader() {
    return (
        <div className={styles.loaderContainer}>
      <div className={styles.cutsomLoader}></div>
    </div>
    );
}
export default Loader;