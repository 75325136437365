import styles from "./CreatePage.module.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bg1 from "../../assets/Images/bg1.jpg";
import bg2 from "../../assets/Images/bg2.jpg";
import { useEffect, useState } from "react";
import HeaderSection from "../HeaderSection/HeaderSection";
import { Col, Container, Row } from "react-bootstrap";
import playstore from "../../assets/Images/playstore.png";
import appstore from "../../assets/Images/appstore.png";
// import TrainerData from "./TrainerData";
import SublyApi from "../../helpers/Api";
import HomePageSlider from "../../commonComponents/HomePageSlider/HomePageSlider";
import { HOME_IMAGE } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setBgImages } from "../../store/slices/UserSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import $ from "jquery";
import React from "react";
import SimpleSlider from "./slider";
import SimpleSlider2 from "./Slider2";
import SimpleSlider3 from "./Slider3";
import { getCreatorList } from "../../store/slices/CreatorSlice";
import Loader from "../../utils/Loader/Loader";
import SubHeader from "../HeaderSection/SubHeader";
import FooterSection from "../FooterSection/FooterSection";
import { Toast } from "../../utils/Toaster";



// -------------function for home page-----------
function CreatePage() {
  const { t } = useTranslation();
  const [packageList, setPackageList] = useState("");
  const [backgroundImage, setBackgroundImage] = useState();
  const [bgIndex, setBgIndex] = useState(0);
  const dispatch = useDispatch();
  const { backgroundImageRes } = useSelector((state) => state.user);
  const { creatorList } = useSelector((state) => state.creatorList);
  const [isLoading, setIsLoading] = useState(false);
  const { userToken, currentUnit, updateUser } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    if (location.state?.showPopup) {
      // Show the popup
      Toast.fire({
        icon: "warning",
        title: location.state.message,
      });

      // Clear the state immediately after showing the popup
      setTimeout(() => {
        navigate(location.pathname, { replace: true, state: {} });
      }, 0);
    }
  }, [location, navigate]);


  // -------- function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  //--------- function for get home image slider api calling ----------
  useEffect(() => {
    async function imageSlider() {
      dispatch(setBgImages(HOME_IMAGE.IMAGE_TYPE)).then((responsejson) => {
        const response = responsejson.payload;
        if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
          setBackgroundImage(response.settings.data[0].webImage);
        }
      });
    }
    imageSlider();
  }, []);

  //---------function for home background image change---------
  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImage((previousImage) => {
        if (backgroundImageRes.length > 0) {
          let lastImgIndex = backgroundImageRes.length - 1;
          if (lastImgIndex == bgIndex) {
            setBgIndex(0);
            const webImage = backgroundImageRes[0].webImage;
            return webImage;
          } else {
            const newBgIndex = bgIndex + 1;
            setBgIndex(newBgIndex);
            const webImage = backgroundImageRes[newBgIndex].webImage;
            return webImage;
          }
        } else {
          setBackgroundImage(bg1);
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [bgIndex]);

  //---------function for subscription api calling----------
  useEffect(() => {
    async function subscriptionPackageList() {
      const details = await SublyApi.getPackageList();
      details && setPackageList(details.data);
    }
    subscriptionPackageList();
  }, []);

  useEffect(() => {
    setIsLoading(true);

    async function creatorList() {
      try {
        await dispatch(getCreatorList({ authToken: userToken || null }));
      } finally {
        setIsLoading(false);
      }
    }

    creatorList();
  }, [dispatch]);


  // const renderSliders = () => {
  //   return creatorList ? creatorList?.map((category, index) => {
  //     category?.map((cat, i) => {
  //       const firstItem = cat;
  //       const title = firstItem?.vCreatorCategoryTitle || "Untitled Category";

  //       console.log(firstItem, "FirstItem")

  //       if (["Featured", "Sportlight", "Rectangle"].includes(firstItem.eType)) {
  //         return (
  //           <div className={styles.textSection} key={index}>
  //             <Container>
  //               <div className={styles.middileSection}>
  //                 <div className="slide-main">
  //                   <h2 className="slider-title">{title}</h2>
  //                   <h2 className="slider-viewall">View All</h2>
  //                 </div>
  //                 <SimpleSlider data={firstItem.creator} />
  //               </div>
  //             </Container>
  //           </div>
  //         );
  //       } else if (firstItem.eType === "Normal" && firstItem.eShowAs === "Grid") {
  //         return (
  //           <div className={styles.textSection} key={index}>
  //             <Container>
  //               <div className={styles.middileSection}>
  //                 <div className="slide-main">
  //                   <h2 className="slider-title">{title}</h2>
  //                   <h2 className="slider-viewall">View All</h2>
  //                 </div>
  //                 <SimpleSlider2 data={firstItem.creator} />
  //               </div>
  //             </Container>
  //           </div>
  //         );
  //       }
  //       return null;
  //     })
  //   }) : null;
  // };

  const renderSliders = () => {
    if (!creatorList || !Array.isArray(creatorList)) return null;

    return creatorList.map((category, categoryIndex) => {
      if (!Array.isArray(category)) return null;
      if (category[0]?.eType == "Featured") {
        if (category[0]?.eIsPrivate == "Yes" && category[0]?.creator?.length > 0) {
          return (
          <div className={styles.sectionOne} key={`${categoryIndex}`}>
            <div className="featuredSlider">
              <div className="titleBar" style={{ justifyContent: "end" }}>
                {/* <h2 className="slider-title">{category[0].vCreatorCategoryTitle}</h2> */}
                <Link className="viewall" to={`/detailpage/${category[0]?.iCreatorCategoryId}`}> View All</Link>
              </div>
              <SimpleSlider data={category[0]?.creator} eType={category[0]?.eType} />
            </div>

          </div>)
        }
      } else if (category[0]?.eType == "Normal" && category[0]?.eShowAs == "Grid") {
        if (category[0]?.eIsPrivate == "Yes" && category[0]?.creator?.length > 0) {
        return (
          <div className={styles.sectionTwo}>

            <div className={styles.middileSection}>
              <div className="titleBar">

                <h2>{ category[0]?.eTitleShowInApp == "Yes" ? category[0].vCreatorCategoryTitle : ""}</h2>
                <Link className="viewall" to={`/detailpage/${category[0]?.iCreatorCategoryId}`}> View All</Link>
              </div>
              <SimpleSlider2 data={category[0]?.creator} />
            </div>

          </div>
        )
      }
      } else if (category[0]?.eType == "Sportlight") {
        if (category[0]?.eIsPrivate == "Yes" && category[0]?.creator?.length > 0) {
        return (<div className={styles.sectionThree} key={`${categoryIndex}`}>


          <div className="titleBar">

            <h2 className="slider-title">{category[0]?.eTitleShowInApp == "Yes" ? category[0]?.vCreatorCategoryTitle : ""}</h2>
            {/* <h2 className="slider-viewall">View All</h2> */}
          </div>
          <SimpleSlider data={category[0]?.creator} eType={category[0]?.eType} />


        </div>)
        }
      } else if (category[0]?.eShowAs == "Rectangle") {
        return (<div className={styles.sectionThree} key={`${categoryIndex}`}>

          <div className={styles.middileSection}>
            <SimpleSlider3 data={category} />
          </div>

        </div>)
      }


      // return category.map((cat, catIndex) => {
      //   console.log("CAT", cat)
      //   const firstItem = cat;
      //   const title = firstItem?.vCreatorCategoryTitle || "Untitled Category";
      //   console.log("TITLE", title)

      //   if ((["Featured", "Sportlight"].includes(firstItem.eType))) {
      //     return (
      //       <div className={styles.textSection} key={`${categoryIndex}-${catIndex}`}>
      //         <Container>
      //           <div className={styles.middileSection}>
      //             <div className="slide-main">
      //               <h2 className="slider-title">{title}</h2>
      //               <h2 className="slider-viewall">View All</h2>
      //             </div>
      //             <SimpleSlider data={firstItem.creator} />
      //           </div>
      //         </Container>
      //       </div>
      //     );
      //   } else if (firstItem.eType === "Normal" && firstItem.eShowAs === "Grid") {
      //     return (
      //       <div className={styles.textSection} key={`${categoryIndex}-${catIndex}`}>
      //         <Container>
      //           <div className={styles.middileSection}>
      //             <div className="slide-main">
      //               <h2 className="slider-title">{title}</h2>
      //               <h2 className="slider-viewall">View All</h2>
      //             </div>
      //             <SimpleSlider2 data={firstItem.creator} />
      //           </div>
      //         </Container>
      //       </div>
      //     );
      //   }
      //   return null;
      // });
    });
  };

  return (
    <>
      {isLoading === true ? <Loader /> :
        (
          <>
            <SubHeader />
            <div className="main paddingNone">
              <Container>
                <div className="boxWrapper">
                  <div className="innerboxWrapper">
                    <div className="heading mobileHide">
                      <h2>{t("CREATORHEADING1")}</h2>
                    </div>
                    {renderSliders()}
                  </div>
                </div>
              </Container>
            </div>
            <FooterSection />
          </>
        )}
    </>
  );
}
export default CreatePage;
