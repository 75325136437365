import styles from "../Plan/Plan.module.css";
import "../../assets/Styles/Common.css";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FitnessPlan from "./FitnessPlanSection/FitnessPlan";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE } from "../../utils/Constants";
import { getWorkoutPlan } from "../../store/slices/PlanListSlice";
import Loader from "../../utils/Loader/Loader";
import $ from "jquery"
import SubHeader from "../HeaderSection/SubHeader";
import { GetExclusivePage } from "../../store/slices/UserSlice";
import FooterSection from "../FooterSection/FooterSection";

//------function for plan section------------
function Plan() {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.planList);
  const { exclusivePageData } = useSelector((state) => state.user);
  const { userToken, currentUnit, updateUser } = useSelector((state) => state.user);

  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  //---------function for plan list api calling----------
  useEffect(() => {
    async function planList() {
      await dispatch(
        getWorkoutPlan({
          page: PAGE.PAGEONE,
          id:
            currentUser && currentUser.customer_id
              ? currentUser.customer_id
              : "",
        })
      );
    }
    planList();
  }, []);


  useEffect(() => {
    async function getExclusviePage() {
      try {
        await dispatch(GetExclusivePage({ authToken: userToken || null })).then((res) => {
        });
      } finally {
        // setIsLoading(false); 
      }
    }

    getExclusviePage();
  }, [dispatch]);

  return (
    <>
      {isLoading ? <Loader /> :
        (
          <>
            <SubHeader />
            <div className="main">
              <Container>
                <div className="boxWrapper">
                  <div className="innerboxWrapper">


                    <div className="heading">
                      <h2>{exclusivePageData?.exclusive_title}</h2>
                    </div>
                    <p>{exclusivePageData?.exclusive_description}</p>
                    <div className={styles.planHeading}>


                    </div>
                    <FitnessPlan />



                  </div>
                </div>
              </Container>
            </div>
            <FooterSection />
          </>
        )}
    </>
  );
}

export default Plan;
