import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";

const initialState = {
  reviewRatings: "",
  review: "",
  isLoading: false,
  success: false,
  error: null,
};

// Thunk for get creator list
export const GetReviewRating = createAsyncThunk(
  "getReviewRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getReviewRating(data, data.authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const CreateReviewRating = createAsyncThunk(
  "CreateReviewRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.createReviewRating(data, data.authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const GetOneReviewRating = createAsyncThunk(
  "GetOneReviewRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getOneReviewRating(data, data.authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const EditReviewRating = createAsyncThunk(
  "EditReviewRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.editReviewRating(data, data.authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const DeleteReviewRating = createAsyncThunk(
  "DeleteReviewRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.deleteReviewRating(data, data.authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get purchased plan list
// export const getPurchasedPlan = createAsyncThunk(
//   "planList/getPurchasedPlan",
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await SublyApi.saleDetailsShow(data.page, data.userToken);
//       return response;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const reviewRatingSlice = createSlice({
  name: "reviewRating",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // ------Builder for creator list--------
    builder.addCase(GetReviewRating.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(GetReviewRating.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        state.reviewRatings = response.settings.data;
        state.success = true;
      } else {
        state.success = false;
        state.reviewRatings = [];
      }
    });

    builder.addCase(GetReviewRating.rejected, (state, action) => {
      state.isLoading = false;
    });


    // ------Builder for createreview --------
    builder.addCase(CreateReviewRating.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(CreateReviewRating.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
    });

    builder.addCase(CreateReviewRating.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for getonereview --------
    builder.addCase(GetOneReviewRating.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(GetOneReviewRating.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        state.review = response.settings.data;
        state.success = true;
      } else {
        state.success = false;
        state.reviewRatings = [];
      }
    });

    builder.addCase(GetOneReviewRating.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for editReview --------
    builder.addCase(EditReviewRating.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(EditReviewRating.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
    });

    builder.addCase(EditReviewRating.rejected, (state, action) => {
      state.isLoading = false;
    });
    // ------Builder for deleteReview --------
    builder.addCase(DeleteReviewRating.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(DeleteReviewRating.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
    });

    builder.addCase(DeleteReviewRating.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});

export default reviewRatingSlice.reducer;

