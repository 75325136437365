import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";

const initialState = {
    subscriptionDetail: "",
    packageDetail: "",
    isLoading: false,
    success: false,
    error: null,
};

// Thunk for get workOut plan list
export const getSubscriptionData = createAsyncThunk(
    "subscriptionList",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SublyApi.subscriptionDetail(data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getSubscriptionDetail = createAsyncThunk(
    "getSubscriptionDetail",
    async ({ id, authToken }, { rejectWithValue }) => {
      try {
        const response = await SublyApi.PackageDetail(id, authToken);
        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );


export const subscriptionSlice = createSlice({
    name: "subscriptionList",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // ------Builder for subscription list-------
        builder.addCase(getSubscriptionData.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getSubscriptionData.fulfilled, (state, action) => {
            const response = action.payload;
            state.isLoading = false;
            if (response && response.success == STATUS_CODES.SUCCESS_API) {
                state.subscriptionDetail = response;
                state.success = true;
            } else {
                state.success = false;
                state.subscriptionDetail = [];
            }
        });

        builder.addCase(getSubscriptionData.rejected, (state, action) => {
            state.isLoading = false;
            state.subscriptionDetail = [];
        });
        // ------Builder for subscription detail-------
        builder.addCase(getSubscriptionDetail.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getSubscriptionDetail.fulfilled, (state, action) => {
            const response = action.payload.data;
            state.isLoading = false;
                state.packageDetail = response;
                state.success = true;
        });

        builder.addCase(getSubscriptionDetail.rejected, (state, action) => {
            state.isLoading = false;
            state.subscriptionDetail = [];
        });

    },
});

export default subscriptionSlice.reducer;

