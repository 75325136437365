import React, { useState } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReportCreator } from "../../store/slices/CreatorSlice";
import { Toast } from "../../utils/Toaster";
import { useFormik } from "formik";
import * as Yup from "yup";
import StarsRating from 'react-star-rate';
import { CreateReviewRating, DeleteReviewRating, EditReviewRating, GetReviewRating } from "../../store/slices/ReviewRatingSlice";
import { Await } from "react-router-dom";
import { StarRating } from "../../commonComponents/StarRating/RenderStar";

const ViewReviewModal = ({ show, data , handleClose }) => {

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className="viewReview-modal"
        >
            <Modal.Body className="modal-body-custom">

                
                    <div className="view-review">
                        <div className="review-header">
                            <div className="user-info">
                                <img
                                    src={data?.customer?.vProfileImage}
                                    alt="no image"
                                />
                                {data?.is_admin_created == 1 ? data?.is_admin_name : data?.customer?.vName}
                                <div style={{color:"#AFAFAF", fontSize:"18px", marginTop:"10px", fontWeight:"400"}}>{data?.tReviewTitle || "NA"}</div>
                            </div>
                            {/* {data?.isEditable == 1 && <div className="edit" onClick={() => handleEditReview(data?.iRatingReviewId)}>Edit</div>} */}
                        </div>
                        <div className="review-body">
                            {/* <div className="star">★★★★★</div> */}
                            <div className="review-overall-rating">
                                <StarRating
                                    review={{
                                        rating: data?.dRating,
                                    }}
                                />
                               <div className="date mt-1"> {data?.dtCreatedAt}</div>
                            </div>
                            {data?.tReviewDescription || "NA"}{" "}
                        </div>
                        {data?.response &&<div className="response cstm-mt-40">
                          <div className="creator-response">
                            <strong>Creator Response:</strong>
                            <br />
                            {data?.response}
                          </div>
                        </div>}
                    </div>
                
            </Modal.Body>
        </Modal>
    );
};

export default ViewReviewModal;
