import React, { useState } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReportCreator } from "../../store/slices/CreatorSlice";
import { Toast } from "../../utils/Toaster";
import { useFormik } from "formik";
import * as Yup from "yup";
import StarsRating from 'react-star-rate';
import { CreateReviewRating, DeleteReviewRating, EditReviewRating, GetReviewRating } from "../../store/slices/ReviewRatingSlice";
import { Await } from "react-router-dom";
import { StarRating } from "../../commonComponents/StarRating/RenderStar";

const DescriptionModal = ({ show, data, handleClose, creatorDetails, title }) => {

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className="desc-modal"
        >
            <Modal.Header style={{ borderBottom: "none", color: "#828282" }}>

                {creatorDetails != null ? <Modal.Title>{creatorDetails?.eWebsiteNameShowInApp == "Yes" && <h4>{creatorDetails?.vWebsiteName}</h4>}
                    {creatorDetails?.eWebsiteSubTitleShowInApp == "Yes" && <h6>{creatorDetails?.vWebsiteSubTitle}</h6>}</Modal.Title>
                    :
                    <Modal.Title>
                       <h4>{title}</h4>
                    </Modal.Title>
                }
            </Modal.Header>
            <Modal.Body className="modal-body-custom">
                {data?.split("\n").map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </Modal.Body>
        </Modal>
    );
};

export default DescriptionModal;
