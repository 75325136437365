import { Accordion, Container } from "react-bootstrap";
import styles from "../FaqPage/FaqPage.module.css";
import SublyApi from "../../helpers/Api";
import { useEffect, useState } from "react";
import { newlineText } from "../../commonComponents/NewLineText";
import SubHeader from "../HeaderSection/SubHeader";
import Loader from "../../utils/Loader/Loader";
import { STATUS_CODES } from "../../utils/StatusCode";
import { useTranslation } from "react-i18next";
import FooterSection from "../FooterSection/FooterSection";

//----------function for FAQ----------- 
function FaqPage() {
    const { t } = useTranslation();
    const [faqList, setFaqList] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    // --------function for open component top of the page ----------
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //---------function for get FAQ list api calling---------- 
    useEffect(() => {
        async function faqListGet() {
            setIsLoading(true);
            const details = await SublyApi.getFaqList();
            setIsLoading(false);
            if (details && details.settings && details.settings.success && details.settings.success == STATUS_CODES.SUCCESS_API) {
                setFaqList(details && details.data);
            }
        }
        faqListGet();
    }, []);

    return (
        <>
            <SubHeader />
            {isLoading === true ? <Loader /> :
                (
                    <>

                        <div className="main">

                            <Container>
                                <div className="boxWrapper">
                                    <div className='innerboxWrapper'>
                                        <h3 className={styles.faqText}>{t("FAQ")}</h3>
                                        <div className="faqBlock">
                                            <Accordion>
                                                {faqList.length > 0 &&
                                                    faqList.map((item, index) => (
                                                        <Accordion.Item eventKey={item.faq_id} key={index}>
                                                            <Accordion.Header >{item.faq_question}</Accordion.Header>
                                                            <Accordion.Body>
                                                                {newlineText(item.faq_answer)}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ))}
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <FooterSection />
                    </>
                )
            }
        </>
    );
}
export default FaqPage;