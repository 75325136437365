import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import PageNotFound from "../appComponents/PageNotFound/PageNotFound";
import Login from "../authComponent/SignUpLoginPage/LoginPage";
import ContactUs from "../appComponents/ContactUs/ContactUs";
import ForgotPassword from "../authComponent/ForgotPassword/ForgotPassword";
import HomePage from "../appComponents/HomePage/HomePage";
import FooterSection from "../appComponents/FooterSection/FooterSection";
import Profile from "../appComponents/Profile/Profile";
import EditProfile from "../appComponents/Profile/EditProfile";
import SignupFlow from "../authComponent/SignUpLoginPage/SignupFlow";
import { useDispatch, useSelector } from "react-redux";
import ResetPassword from "../authComponent/ForgotPassword/ReserPassword";
import FaqPage from "../appComponents/FaqPage/FaqPage";
import PrivacyPolicy from "../appComponents/Content/PrivacyPolicy";
import AboutUs from "../appComponents/Content/AboutUs";
import TermsCondition from "../appComponents/Content/TermsCondition";
import Plan from "../appComponents/Plan/Plan";
import FitnessPlanDetail from "../appComponents/Plan/FitnessPlanDetail/FitnessPlanDetail";
import Subscription from "../appComponents/Subscription/Subscription";
import SubscriptionDetail from "../appComponents/Subscription/SubscriptionDetail";
import FitnessPlanPaypal from "../appComponents/Plan/FitnessPlanDetail/FitnessPlanPaypal";
import ScrollToTop from "../helpers/ScrollToTop";
import PurchasePlanDetail from "../appComponents/Plan/PurchaseData/PurchaseDetails";
import CreatePage from "../appComponents/CreatePage/CreatePage";
import DetailPage from "../appComponents/CreatePage/DetailPage";
import { ReviewRating } from "../appComponents/ReviewRatings/ReviewRating";
import { CreatorDetail } from "../appComponents/CreatePage/CreatorDetail";
import { customerDeatail } from "../store/slices/UserSlice";
import SubscriptionTab from "../appComponents/Profile/SubscriptionTab";
import PlanTab from "../appComponents/Profile/PlanTab";
import FollowedTab from "../appComponents/Profile/FollowedTab";
import ChangePassword from "../appComponents/Profile/ChangePasswordTab";
import CreaterNotFound from "../appComponents/CreaterNotFound/CreaterNotFound";
import SubscriptionView from "../appComponents/Subscription/SubscriptionView";
import FitnessPlanView from "../appComponents/Plan/FitnessPlanDetail/FitnessPlanView";
import GoogleAuthCallback from "../authComponent/SignUpLoginPage/GoogleAuthCallback";
import LoginHandler from "../authComponent/SignUpLoginPage/LoginHandler";
import About from "../authComponent/SignUpLoginPage/About";

//-------Create a component for manage routing--------
function Routers() {
  const dispatch = useDispatch();
  const { currentUser, salePlanVisible , CreatorVisible } = useSelector((state) => state.user);
  const { subscriptionDetail } = useSelector((state) => state.subscriptionList);
  // const userToken = new URLSearchParams(location.search).get("id");

  // const excludeFooterRoutes = ["/frontendlive/creatordetail/:id", "/frontendlive/detailpage/:id", "/frontendlive/reviewrating/:id", "/frontendlive/creator", "/frontendlive/exclusive", "/frontendlive/subscription" ,"/frontendlive/profile" , "/frontendlive/followedCreator-tab" , "/frontendlive/plan-tab" , "/frontendlive/subscription-tab","/frontendlive/edit-profile" , "/frontendlive/purchase/details" , "/frontendlive/privacy-policy" , "/frontendlive/about-us" , "/frontendlive/terms-condition" , "/frontendlive/faq"];
  const excludeFooterRoutes = ["/creatordetail/:id", "/detailpage/:id", "/reviewrating/:id", "/creator", "/exclusive", "/subscription" ,"/profile" , "/followedCreator-tab" , "/plan-tab" , "/subscription-tab","/edit-profile" , "/purchase/details" , "/privacy-policy" , "/about-us" , "/terms-condition" , "/faq"];

  const shouldShowFooter = !excludeFooterRoutes.some((route) =>
    window.location.pathname.startsWith(route.replace(":id", ""))
  );




  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    if (token) {
      localStorage.setItem("userToken", token);
      dispatch(customerDeatail(token));
    }
  }, [dispatch, window.location.pathname.includes("subscription") || window.location.pathname.includes("profile")  ? JSON.stringify(currentUser) : currentUser]);

  // useEffect(() => {
  //   const urlParts = window.location.href.split("/?");
  //   if (urlParts.length > 1) {
  //     const token = urlParts[1]; // Get the part after '/?'
  //     if (token) {
  //       localStorage.setItem("userToken", token);
  //       dispatch(customerDeatail(token));
  //     }
  //   }
  // }, [dispatch, window.location.pathname.includes("paypal") || window.location.pathname.includes("subscription") ? JSON.stringify(currentUser) : currentUser]);

  return (
    <Router basename={"/"}>
     {/* <Router basename={"/"}> */}
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={Object.keys(currentUser).length == 0 ? <HomePage /> : <Navigate to="/profile" />} />
        <Route exact path="/login" element= {<Login />}/>
        <Route
          exact
          path="/profile"
          element={
            // localStorage.getItem("userToken") || Object.keys(currentUser).length !== 0 ? (
              <Profile />
            // ) : (
              // <Navigate to="/login" />
            // )
          }
        />
         <Route
          exact
          path="/Signup"
          element={
            <About/>
          }
        />
        <Route
          exact
          path="/d/:shar?"
          element={
            <Navigate to="/" />
          }
        />

        <Route exact path="/edit-profile" element={Object.keys(currentUser).length !== 0 ? (<EditProfile />) : (<Navigate to="/login" />)} />
        <Route exact path="/sign-up" element={<SignupFlow />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route
          exact
          path="/subscription"
          element={
            Object.keys(currentUser).length !== 0 ? (
              // is_subscribed =1(subscribed) & is_subscribed=0(non subscribed)
              currentUser && currentUser.is_subscribed == "1" ? (
                subscriptionDetail &&
                  subscriptionDetail.data &&
                  subscriptionDetail.data.iStatus == "1" ? (
                  <Navigate to="/profile" />
                ) : (
                  <Subscription />
                )
              ) : (
                <Subscription />
              )
            ) : (
              <Subscription />
            )
          }
        />
        <Route
          exact
          path="/creators"
          element={CreatorVisible && CreatorVisible.value == "1" ? <CreatePage /> : (<Navigate to="/" />)}
        />
        <Route
          exact
          path="/detailpage/:id"
          element={<DetailPage />}
        />
        <Route
          exact
          path="/creatordetail/:id"
          element={<CreatorDetail />}
        />
        <Route
          exact
          path="/reviewrating/:id"
          element={<ReviewRating />}
        />
        <Route
          exact
          path="/creater-not-found"
          element={<CreaterNotFound />}
        />
        <Route
          exact
          path="/subscription/detail"
          element={
            // iStatus = 1 (subscribed) and iStatus =2 (non subscribed)
            // Object.keys(currentUser).length !== 0 ? (
            //   currentUser && currentUser.is_subscribed == "1" ? (
            //     subscriptionDetail &&
            //       subscriptionDetail.data &&
            //       subscriptionDetail.data.iStatus == "1" ? (
            //       <Navigate to="/profile/1" />
            //     ) : (
            //       <SubscriptionDetail />
            //     )
            //   ) : (
                <SubscriptionDetail />
            //   )
            // ) : (
              // <Navigate to="/profile" />
            // )
          }
        />
        <Route
          exact
          path="/subscription/view"
          element={
            // iStatus = 1 (subscribed) and iStatus =2 (non subscribed)
            // Object.keys(currentUser).length !== 0 ? (
            //   currentUser && currentUser.is_subscribed == "1" ? (
            //     subscriptionDetail &&
            //       subscriptionDetail.data &&
            //       subscriptionDetail.data.iStatus == "1" ? (
            //       <Navigate to="/profile/1" />
            //     ) : (
            //       <SubscriptionDetail />
            //     )
            //   ) : (
                <SubscriptionView />
            //   )
            // ) : (
              // <Navigate to="/profile" />
            // )
          }
        />
        <Route exact path="/reset-password" element={Object.keys(currentUser).length == 0 ? <ResetPassword /> : <Navigate to="/profile" />} />
        <Route exact path="/faq" element={<FaqPage />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/exclusive" element={salePlanVisible && salePlanVisible.value == "1" ? <Plan /> : (<Navigate to="/" />)} />
        <Route exact path="/exclusive/details/:id/:type" element={<FitnessPlanDetail />} />
        <Route
          exact
          path="/purchase/details/:id/:type/:trasId"
          element={<PurchasePlanDetail />}
        />
        <Route exact path="/exclusive/paypal"
          element={
            // Object.keys(currentUser).length != 0 ? (
              <FitnessPlanPaypal />
            // ) : (
              // <Navigate to="/login" />
            // )
          }
        />
        <Route exact path="/exclusive/view/:id/:type"
          element={
            // Object.keys(currentUser).length != 0 ? (
              <FitnessPlanView/>
            // ) : (
              // <Navigate to="/login" />
            // )
          }
        />
        <Route exact path="/terms-condition" element={<TermsCondition />} />
        <Route exact path="/subscription-tab" element={<SubscriptionTab />} />
        <Route exact path="/plan-tab" element={<PlanTab />} />
        <Route exact path="/followedCreator-tab" element={<FollowedTab />} />
        <Route exact path="/changePassword-tab" element={<ChangePassword />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>

      {shouldShowFooter && <FooterSection />}

    </Router>
  );
}
export default Routers;
