import "../../appComponents/HomePage/HomePage.module.css";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//-------function for show trainer images in home page----------- 
function HomePageSlider({ packageList }) {

  // Configure the slider settings
  const settings = {
    arrowLeft: true,
    dots: false,
    arrows: false,
    infinite: true,
    centerPadding: 20,
    speed: 500,
    slidesToShow: 3,
    // slidesToShow:
    //   packageList && packageList.get_success_story && packageList.get_success_story.length < 3
    //     ? packageList.get_success_story.length
    //     : 3,
    // variableWidth:true,
    // variableWidth: packageList && packageList.get_success_story && packageList.get_success_story.length > 3
    //   ? false
    //   : true,
    slidesToScroll: 5,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },

      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },

      {
        breakpoint: 414,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },

      {
        breakpoint: 567,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
    ]
  };
  return (


    <Slider {...settings} className="trainerSlider" >
      {packageList &&  packageList.length > 0
        ? packageList.map((item, index) => (
          <div className="trainerBoxWrapper">
            <div key={index} className="trainerBox">
              <img src={item.vImage} alt={`Slide ${index + 1}`} className="img-fluid" />
            </div>
          </div>
        ))
        : ""}
    </Slider>


  );
}

export default HomePageSlider;
