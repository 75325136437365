import { Button, Collapse, Container, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import styles from "../HeaderSection/HeaderSection.module.css";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/Images/white_logo.png";
import { useTranslation } from "react-i18next";
import profile from "../../assets/Images/picture.png";
import { useDispatch, useSelector } from "react-redux";
import { salesVisibility } from "../../store/slices/UserSlice";
import { useEffect, useRef, useState } from "react";
import { STATUS_CODES } from "../../utils/StatusCode";

// -------------function for header section-----------
function HeaderSection() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentUser, salePlanVisible, CreatorVisible } = useSelector((state) => state.user);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [open, setOpen] = useState(false);
  const collapseRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (collapseRef.current && !collapseRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);


  // --------function for Global setting for sales plan visibility status api call--------
  useEffect(() => {
    dispatch(salesVisibility()).then((responsejson) => {
      const response = responsejson.payload;
    })
  }, []);

  return (
    <>
      <header className={styles.mainHeader}>
        <Navbar expand="sm">
          <Container className={styles.headerContainer}>
            <Navbar.Brand to="/">
              <NavLink to="/">
                {Object.keys(currentUser).length == 0 ?
                  <img src={logo} alt="logo" className={styles.logo_brand} />
                  :
                  <img src={profile} alt="logo" className={styles.logo_user} />
                }
              </NavLink>
            </Navbar.Brand>
            <Button className="buttonToggle"
              //  onClick={handleShow}
              onClick={(event) => {
                setOpen(!open);
              }} aria-controls="example-collapse-text" aria-expanded={open}
            >
              <span className="navbar-toggler-icon"></span>
            </Button>
            <Navbar.Collapse id="mainmenu">
              <Nav className="ms-auto">
                {CreatorVisible && CreatorVisible.value == 1 ? <NavLink className={styles.customNavLink} to="/creator">{t("Creators")}</NavLink> : ""}
                {salePlanVisible && salePlanVisible.value == "1" ?
                  <NavLink className={styles.customNavLink} to="/exclusive">{t("SALE")}</NavLink>
                  : ""}
                <NavLink className={styles.customNavLink} to="/subscription">{t("SUBSCRIPTION")}</NavLink>
              </Nav>
            </Navbar.Collapse>
            <Collapse in={open} >
              <div className={styles.socialBar} ref={collapseRef}>
                {CreatorVisible && CreatorVisible.value == 1 ? <Link to="/creator" style={{ borderBottom: "solid 1px  #3A3A3A" }}>
                  {t("Creators")}
                </Link> : ""}
                {salePlanVisible && salePlanVisible.value == "1" ?
                  <Link to="/exclusive" style={{ borderBottom: "solid 1px #3A3A3A" }}>
                    {t("SALE")}
                  </Link> : ""}
                <Link to="/subscription">
                  {t("SUBSCRIPTION")}
                </Link>
              </div>
            </Collapse>  
          </Container>
        </Navbar>
      </header>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header>
          <div className={styles.menuLogos}>
            {Object.keys(currentUser).length == 0 ?
              <img src={logo} alt="logo" className={styles.logo_brand} />
              :
              <img src={profile} alt="logo" className={styles.logo_user} />
            }
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className={styles.mobileMenu}>
            {CreatorVisible && CreatorVisible.value == 1 ? <NavLink className={styles.customNavLink} to="/creator">{t("Creators")}</NavLink> : ""}
            {salePlanVisible && salePlanVisible.value == "1" ?
              <NavLink className={styles.customNavLink} to="/exclusive">{t("SALE")}</NavLink>
              : ""}
            <NavLink className={styles.customNavLink} to="/subscription">{t("SUBSCRIPTION")}</NavLink>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>

    </>
  );
}
export default HeaderSection;