import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const PrevArrow1 = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className= "prevArrow1"
      // style={{ width:"30px", height:"30px", backgroundColor:"rgb(255 255 255 / 63%)", position:"absolute", top:"40%", right:"0" ,borderRadius:"50px",lineHeight:"30px", zIndex:"9", marginRight:"10" , cursor:"pointer"}}
      onClick={onClick}
    >
      {"<"}
    </div>
  );
};

const NextArrow1 = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className= "nextArrow1"
      // style={{ width:"30px", height:"30px", backgroundColor:"rgb(255 255 255 / 63%)", position:"absolute", top:"40%",left:"10", borderRadius:"50px",zIndex:"9", lineHeight:"30px", cursor:"pointer"}}
      onClick={onClick}
    >
      {">"}
    </div>
  );
};



export default function SimpleSlider({data , eType}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);
  
  // Effect to handle resize event and update the isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: data.length > 2,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <PrevArrow1 />,
    nextArrow: <NextArrow1 />,
    autoplay : eType != "Sportlight" && true,
    autoplaySpeed : 10000,
    responsive: [
      {
        breakpoint: 1024,
        settings: { 
          slidesToShow:2         
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: (eType == "Featured") || (data.length == 1 && eType !== "Featured") ? 1 : 2,
          dots:true,
          arrows:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: (eType == "Featured") || (data.length == 1 && eType !== "Featured") ? 1 : 2, 
          dots:true,
          arrows:false
        }
      }
     
    ]
  };

  const handleNavigate = (id) => {
    navigate(`/creatordetail/${id}`)
  }


  
  return (
    <Slider {...settings} className="sliderOne">
      {data?.map((item, index) => (
        <div key={index} className="sliderOneWrapper" onClick={(e) => {
          e.stopPropagation();
          handleNavigate(item?.iAdminId)
        }}>
          <div className="sliderOne_Box">
            {eType == "Featured" && <span className="badge">Featured</span> }
            <img src={item.vWebsiteImage} style={{cursor:"pointer"}} onClick={(e) => {
              e.stopPropagation();
              handleNavigate(item?.iAdminId)
            }}/>
            <div className={isMobile && eType != "Featured" && data?.length != 1  ?  "sliderOne_Content2" : "sliderOne_Content"}>
              {item?.eWebsiteNameShowInApp == "Yes" &&<h3 style={{cursor:"pointer"}} className={isMobile && eType != "Featured" && data?.length != 1  ? "exampleClass" : ""} onClick={(e) => {
              e.stopPropagation();
              handleNavigate(item?.iAdminId)
            }}>{item.vWebsiteName.toUpperCase()}</h3>}
              {item?.eWebsiteSubTitleShowInApp == "Yes" &&<h4 style={{cursor:"pointer"}} className={isMobile && eType != "Featured" && data?.length != 1  ? "exampleClass2" : ""} onClick={(e) => {
              e.stopPropagation();
              handleNavigate(item?.iAdminId)
            }}>{item.vWebsiteSubTitle.toUpperCase()}</h4>}
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}