import styles from "./GoogleSocialLogin.module.css";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SOCIAL_NETWORK_TYPE } from "../../utils/Constants";
import { checkSocialLogin } from "../../store/slices/UserSlice";
import { Toast } from "../../utils/Toaster";
import { useEffect, useState } from "react";
import google from "../../assets/Images/google_logo.png";
import axios from "axios";

//---------function for google social api call----------
function GoogleSocial({ loadFrom, setSocialDetail }) {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState([]);

  // --------function for open component top of the page ----------
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const subdomain = searchParams.get("subdomain");

    if (subdomain) {
      localStorage.setItem("subdomain", subdomain);
    }
  }, []);


  const getSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    return parts.length > 2 ? parts[0] : "www";
  };





  // const login = useGoogleLogin({
  //   ux_mode: "redirect",
  //   flow: "auth-code", // Use auth-code flow instead of implicit
  //   redirect_uri: "https://staging.3fitstyle.com/auth/callback", // Force correct redirect URI
  //   onSuccess: (tokenResponse) => {
  //     console.log("Google Login Success", tokenResponse);
  //     setUser(tokenResponse)
  //     // const subdomain = getSubdomain();
  //     // localStorage.setItem("subdomain", subdomain);

  //     // // Redirect to auth.3fitstyle.com with token
  //     // window.location.href = `https://staging.3fitstyle.com/auth/callback?token=${tokenResponse.code}`;
  //   },
  //   onError: () => {
  //     console.error("Login Failed");
  //   },
  // });




  // Social Login with google
  const login = useGoogleLogin({
    // ux_mode: "redirect",
    // flow: "auth-code", 
    onSuccess: (codeResponse) => {
      // localStorage.setItem("subdomain", subdomain);
      setUser(codeResponse)
    },

    onError: (error) => console.log("Login Failed:", error),
  });

  const handleGoogleLogin = () => {
    const subdomain = window.location.hostname.split(".")[0];
    const redirectUri = `https://staging.3fitstyle.com`;

    const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=588739677851-qloq4522uqvha606o69j0bd1aa9lsstm.apps.googleusercontent.com&scope=openid%20profile%20email&redirect_uri=storagerelay%3A%2F%2Fhttps%2Fstaging.3fitstyle.com%3Fid%3Dauth168813&prompt=select_account&response_type=token&include_granted_scopes=true&enable_granular_consent=true&service=lso&o2v=2&ddm=1&flowName=GeneralOAuthFlow`;


    window.location.href = googleLoginUrl;
  };

  //   useEffect(() => {
  //     console.log("INNNNNNNN")
  //     // Extract the subdomain from the URL parameter
  //     const urlParams = new URLSearchParams(window.location.search);
  //     console.log("urlParams" , urlParams)
  //     const subdomain = urlParams.get("subdomain") || "www";

  //     // Trigger Google login only on auth.staging.3fitstyle.com
  //     login();
  //     console.log("OUTTTT")
  // }, []);

  // useEffect(() => {
  //   console.log("INNNNNNNN");

  //   const urlParams = new URLSearchParams(window.location.search);
  //   console.log("urlParams", urlParams);
  //   const subdomain = urlParams.get("subdomain") || "www";
  //   console.log("Extracted subdomain:", subdomain);

  //   // Ensure the login only runs if on auth.staging.3fitstyle.com
  //   if (window.location.hostname === "auth.staging.3fitstyle.com") {
  //       login();
  //       console.log("Google login triggered");
  //   } else {
  //       console.log("Not on auth.staging.3fitstyle.com, skipping login");
  //   }
  // }, [window.location.search]); // Add dependency on URL search params
  const urlParams = new URLSearchParams(window.location.search);
  const subdomain = urlParams.get("subdomain") || ""; // Default to 'www' if missing

  // Initialize Google login
  // const login1 = useGoogleLogin({
  //   flow: "auth-code",
  //   ux_mode: "redirect", // Forces full-page redirect instead of popup
  //   redirect_uri: "https://staging.3fitstyle.com",
  //   onSuccess: (tokenResponse) => {
  //     console.log("Google Login Success", tokenResponse);

  //     // Redirect back to the original subdomain with token
  //     window.location.href = `https://${subdomain}.staging.3fitstyle.com/dashboard?token=${tokenResponse.code}`;
  //   },
  //   onError: (error) => {
  //     console.error("Google Login Failed", error);
  //   },
  // });
  
  // useEffect(() => {
  //   const queryParams = new URLSearchParams(window.location.search);
  //   const subdomain = queryParams.get("subdomain") || "";
  //   console.log("subdomain" , subdomain)
  //   if(subdomain){
  //   login1();
  //   }
  // }, [subdomain , login1()]);


  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          responseGoogle(res.data);
        })
        .catch((err) => err);
    }
  }, [user]);

  // Social Login with Google
  // const responseGoogle = async (response) => {
  //   let userData = response;
  //   if (userData) {
  //     let userProfile = (userData.picture) ? userData.picture : "";
  //     dispatch(checkSocialLogin({ id: userData.id, social_network_type: SOCIAL_NETWORK_TYPE.SOCIAL_NETWORK_GOOGLE })).then((responsejson) => {
  //       const response = responsejson.payload;
  //       const subdomain = localStorage.getItem("subdomain");
  //       if (response && response.settings.success == '1') {
  //         Toast.fire({
  //           icon: "success",
  //           title: response.settings.message,
  //         });
  //         if (subdomain) {
  //           window.location.href = `https://${subdomain}.${process.env.REACT_APP_MAIN_URL}${process.env.REACT_APP_PARAM_URL}profile`;
  //         } else {
  //           navigate("/profile");
  //         }
  //       } else if (response && response.settings.success == '2') {
  //         if ((loadFrom) && loadFrom == 'signup') {
  //           setSocialDetail({
  //             name: userData.name,
  //             email: userData.email,
  //             picture: userProfile,
  //             social_network_id: userData.id,
  //             social_network_type: SOCIAL_NETWORK_TYPE.SOCIAL_NETWORK_GOOGLE,
  //             isSocialLogin: true,
  //           })
  //         } else {

  //           navigate("/sign-up", {
  //             state: {
  //               name: userData.name,
  //               email: userData.email,
  //               picture: userProfile,
  //               social_network_id: userData.id,
  //               social_network_type: SOCIAL_NETWORK_TYPE.SOCIAL_NETWORK_GOOGLE,
  //               isSocialLogin: true,
  //             },
  //           });
  //         }

  //       }
  //       else {
  //         Toast.fire({
  //           icon: "error",
  //           title: response && response.settings.message,
  //         });
  //       }
  //     });
  //   }
  // };

  const responseGoogle = async (response) => {
    let userData = response;
    if (userData) {
      let userProfile = userData.picture ? userData.picture : "";
      
      dispatch(checkSocialLogin({ 
        id: userData.id, 
        social_network_type: SOCIAL_NETWORK_TYPE.SOCIAL_NETWORK_GOOGLE 
      })).then((responsejson) => {
        const response = responsejson.payload;
        const subdomain = localStorage.getItem("subdomain");
  
        if (response && response.settings.success == "1") {
          Toast.fire({
            icon: "success",
            title: response.settings.message,
          });
  
          if (subdomain) {
            // window.location.href = `https://${subdomain}/frontendlive/profile`;
            // window.open(`https://${subdomain}.${process.env.REACT_APP_MAIN_URL}${process.env.REACT_APP_PARAM_URL}profile?token=${response.data[0].customer_auth_token}`, '_blank');
            window.location.href = `https://${subdomain}.${process.env.REACT_APP_MAIN_URL}${process.env.REACT_APP_PARAM_URL}profile?token=${response.data[0].customer_auth_token}`;
            
          } else {
            navigate("/profile");
          }
  
        } else if (response && response.settings.success == "2") {
          if (loadFrom && loadFrom === "signup") {
            setSocialDetail({
              name: userData.name,
              email: userData.email,
              picture: userProfile,
              social_network_id: userData.id,
              social_network_type: SOCIAL_NETWORK_TYPE.SOCIAL_NETWORK_GOOGLE,
              isSocialLogin: true,
            });
          } else {
            // Navigate to sign-up with subdomain check
            if (subdomain) {
              window.location.href = `https://${subdomain}.${process.env.REACT_APP_MAIN_URL}${process.env.REACT_APP_PARAM_URL}sign-up?name=${encodeURIComponent(userData.name)}&email=${encodeURIComponent(userData.email)}&picture=${encodeURIComponent(userProfile)}&social_network_id=${userData.id}&social_network_type=${SOCIAL_NETWORK_TYPE.SOCIAL_NETWORK_GOOGLE}&isSocialLogin=true`;
            } else {
              navigate("/sign-up", {
                state: {
                  name: userData.name,
                  email: userData.email,
                  picture: userProfile,
                  social_network_id: userData.id,
                  social_network_type: SOCIAL_NETWORK_TYPE.SOCIAL_NETWORK_GOOGLE,
                  isSocialLogin: true,
                },
              });
            }
          }
        } else {
          Toast.fire({
            icon: "error",
            title: response && response.settings.message,
          });
  
          // Redirect to subdomain error page if applicable
          if (subdomain) {
            window.location.href = `https://${subdomain}/${process.env.REACT_APP_PARAM_URL}/error`;
          }
        }
      });
    }
  };
  

  // const handleGoogleLoginRedirect = () => {
  //   const subdomain = window.location.hostname.split(".")[0]; // Extract "nirav"
  //   // window.location.href = `https://${process.env.REACT_APP_MAIN_URL}${process.env.REACT_APP_PARAM_URL}sign-up?subdomain=${subdomain}`;
  //   window.location.href = `http://localhost:3000/frontendlive/sign-up?subdomain=${subdomain}`;
  // };



  return (
    <>
      <div className={styles.SocialLoginBtn}>
        <button onClick={() => login()}>
          <img src={google} alt="google" />
          {location.pathname == "/login" ?
            <p>{t("SIGN_IN_WITH_GOOGLE")}</p> : <p>{t("SIGN_UP_WITH_GOOGLE")}</p>}
        </button>
      </div>
    </>
  );
}
export default GoogleSocial;
