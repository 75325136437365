import { useTranslation } from "react-i18next";
import styles from "../Subscription/SubscriptionData.module.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../../assets/Styles/Common.css";
import { useEffect, useState } from "react";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Toast } from "../../utils/Toaster";
import { STATUS_CODES } from "../../utils/StatusCode";
import { userLogoutClear, updateSubcription } from "../../store/slices/UserSlice";
import { getSubscriptionData } from "../../store/slices/SubscriptionSlice";

//---------function for subscription data----------
function  SubscriptionData() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken, currentUser } = useSelector((state) => state.user);
  const [isChecked, setIsChecked] = useState(false);
  const { subscriptionDetail } = useSelector((state) => state.subscriptionList);

  const currentDate = new Date();

  useEffect(() => {
    getSubscriptionDetail();
  }, []);

  async function getSubscriptionDetail() {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token") || userToken;
    await dispatch(getSubscriptionData(token)).then((responsejson) => {
      const response = responsejson.payload;
      if (response && response.success == STATUS_CODES.SUCCESS_API) {

        // compare with date and time both then if (new Date(new Date(response.data.dtEndDate).toDateString()) < new Date(currentDate.toDateString()))
        // only compare with date only

        // if (new Date(response.data.dtEndDate) < currentDate) {
        //   if (response.data.subscribe_by == "other" && response.data.iStatus == 1) {
        //     dispatch(updateSubcription({ is_subscribed: 0 }));
        //   }
        // } else {
        //   if (Number(currentUser.is_subscribed) == 0) {
        //     dispatch(updateSubcription({ is_subscribed: 1 }));
        //   }
        // }
      }
      else if (response && response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: t("SESSION_EXPIRE"),
        });
        dispatch(userLogoutClear());
        navigate("/login");
      }
      else {
        dispatch(updateSubcription({ is_subscribed: 0 }));
      }
    });
  }

  const handlePopup = () => {
    Swal.fire({
      // title: 'Cancel Subscription',
      text: `If you confirm and end your subscription now, you can still access the app untill ${moment(
        subscriptionDetail && subscriptionDetail.data && subscriptionDetail.data.dtEndDate
      ).format("MMM DD,YYYY")}. For help or more information, contact us at support@3fitstyle.com`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#e8e8e8',
      cancelButtonColor: '#d33',
      confirmButtonText: "Not Now",
      confirmButtonTextColor: "#000",
      allowOutsideClick: false,
      cancelButtonText: "Confirm",
      customClass: "swalAlert"
    }).then((result) => {
      if (result.isConfirmed) {
        setIsChecked(false); 
      } else {
        removeSubscriptionHandle()
        setIsChecked(true);
      }
    });
  };

  const handleFreePopup = () => {
    let subscribeEndDate = getFreeTrialEndDate();
    Swal.fire({
      // title: 'Cancel Subscription',
      text: `If you confirm and end your subscription now, you can still access the app untill ${subscribeEndDate}. For help or more information, contact us at support@3fitstyle.com`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#e8e8e8',
      cancelButtonColor: '#d33',
      confirmButtonText: "Not Now",
      confirmButtonTextColor: "#000",
      allowOutsideClick: false,
      cancelButtonText: "Confirm",
      customClass: "swalAlert"
    }).then((result) => {
      if (result.isConfirmed) {
        setIsChecked(false); // Keep the checkbox checked
      } else {
        removeSubscriptionHandle()
        setIsChecked(true); // Uncheck the checkbox
      }
    });
  };

  async function removeSubscriptionHandle() {
    await SublyApi.subscriptionRemove(userToken).then((response) => {
      if (response && response.success == STATUS_CODES.SUCCESS_API) {
        Toast.fire({
          icon: "success",
          title: response.message,
        });
        getSubscriptionDetail();
      }
      else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: t("SESSION_EXPIRE"),
        });
        dispatch(userLogoutClear());
        navigate("/login");
      }
      else {
        Toast.fire({
          icon: "error",
          title: response && response.message,
        });
      }
    });
  }

  function getFreeTrialEndDate() {
    let subscribeEndDate = subscriptionDetail.data.dtStartDate;
    if (subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.free_trial == '0') {
      if (subscriptionDetail.data.plan_data.trial_duration_type.toLocaleLowerCase() == 'day') {
        subscribeEndDate = moment(
          subscriptionDetail && subscriptionDetail.data.dtStartDate
        ).add(subscriptionDetail.data.plan_data.trial_duration, 'days').format("MMMM DD")
      }
      if (subscriptionDetail.data.plan_data.trial_duration_type.toLocaleLowerCase() == 'month') {
        subscribeEndDate = moment(
          subscriptionDetail && subscriptionDetail.data.dtStartDate
        ).add(subscriptionDetail.data.plan_data.trial_duration, 'months').format("MMMM DD")
      }
      if (subscriptionDetail.data.plan_data.trial_duration_type.toLocaleLowerCase() == 'week') {
        subscribeEndDate = moment(
          subscriptionDetail && subscriptionDetail.data.dtStartDate
        ).add(subscriptionDetail.data.plan_data.trial_duration, 'weeks').format("MMMM DD")
      }
      if (subscriptionDetail.data.plan_data.trial_duration_type.toLocaleLowerCase() == 'year') {
        subscribeEndDate = moment(
          subscriptionDetail && subscriptionDetail.data.dtStartDate
        ).add(subscriptionDetail.data.plan_data.trial_duration, 'years').format("MMMM DD")
      }
    }
    return subscribeEndDate;
  }

  // Show when user cancel the free subscription 
  // free_trial 1 means normal & free_trial:0 means have free trial
  // iStatus 1 means subscribe active iStatus:2 means cancel
  function FreeTrialPlanCancel() {
    let subscribeEndDate = getFreeTrialEndDate();

    return <>
      <div className={styles.subscriptionDataDetails}>
        <div className={styles.SubscriptionCurrency}>
          <h1>
            {t("PREMINUM")}
          </h1>
        </div>
        <div className={`${styles.subscriptionDatas} ${styles.subscriptionRemoveBorder}`}>
          <h4>
            <span>Your subscription is expiring soon.</span><br></br>
            Your {subscriptionDetail.data.vSubscriptionTitle} subscription
            <br></br>expires on {subscribeEndDate},
          </h4>
        </div>
        <div className={styles.cancelTrialButton}>
          <div className={styles.logoutBtn}>
            <button onClick={() => navigate("/subscription")}>
              {t("PROFILE_PRIMIUM")}
            </button>
            <div className={styles.buttonBottonSpace}>
            </div>
          </div>
        </div>
      </div>
    </>
  }

  // Show when user cancel the normal subscription 
  function NormalPlanCancel() {
    return <>
      <div className={styles.subscriptionDataDetails}>
        <div className={styles.SubscriptionCurrency}>
          <h1>
            {t("PREMINUM")}
          </h1>
        </div>
        <div className={styles.subscriptionDatas}>
          <h6>Current Subscription</h6>
          <p>
            {subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration} { }
            {subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration > 1
              ? (subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration_type.toLowerCase()) + 's'
              : (subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration_type.toLowerCase())}</p>
        </div>
        <div className={styles.subscriptionDatas}>
          <h6>{t("PURCHASED_DATE")}</h6>
          <p>
            {moment(
              subscriptionDetail && subscriptionDetail.data && subscriptionDetail.data.dtStartDate
            ).format("MMMM DD, YYYY")}
          </p>
        </div>
        <div className={styles.subscriptionDatas}>
          <h6>Subscription Expires In</h6>
          <p>{subscriptionDetail &&
            subscriptionDetail.data &&
            subscriptionDetail.data.endsIn
            ? subscriptionDetail.data.endsIn.toLowerCase().replace("left", "")
            : ""}</p>
        </div>
        <div className={styles.subscriptionDatas}>
          <div className={styles.cancelButtonAlign}>
            <div className={styles.logoutBtn}>
              <button onClick={() => navigate("/subscription")}>
                {t("PROFILE_PRIMIUM")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  }

  // Show when subscriprion is not active or not cancel
  function ShowSubscriptionInfo() {
    if (subscriptionDetail.data && subscriptionDetail.data.plan_data && (subscriptionDetail.data.plan_data.duration_type.toLowerCase() == 'year' && parseInt(subscriptionDetail.data.plan_data.duration) > 99)) {
      // Show when user purchase life time plan greator then 100 years
      return <LifetimePlanSubscribe></LifetimePlanSubscribe>
    } else {
      return <>
        <div>
          <div className={styles.subscriptionDataDetails}>
            <div className={styles.SubscriptionCurrency}>
              <h1>
                {t("PREMINUM")}
              </h1>
            </div>
            <div className={styles.subscriptionDatas}>
              <h6>{t("SUBSCRPTION_EXPIRE")}</h6>
              <p>
                {subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration} { }
                {subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration > 1
                  ? (subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration_type.toLowerCase()) + 's'
                  : (subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration_type.toLowerCase())}
              </p>

            </div>
            <div className={styles.subscriptionDatas}>
              <h6>{t("PURCHASED_DATE")}</h6>
              <p>
                {moment(
                  subscriptionDetail && subscriptionDetail.data.dtStartDate
                ).format("MMMM DD, YYYY")}
              </p>
            </div>
            <div className={styles.subscriptionDatas}>
              <h6>{t("SUBSCRIPTION_IN")}</h6>
              <p>
                {subscriptionDetail && subscriptionDetail.data && subscriptionDetail.data.endsIn ?
                  subscriptionDetail.data.endsIn.toLowerCase().replace("left", "") : ''}
              </p>
            </div>
            <div className={styles.subscriptionDatas}>
              <div className={styles.cancelButtonAlign}>
                <div className={styles.logoutBtn}>
                  <button onClick={() => navigate("/subscription")}>
                    {t("PROFILE_PRIMIUM")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    }
  }


  // Show when user show normal subscription 
  function NormalPlanSubscribe() {
    if (subscriptionDetail.data && subscriptionDetail.data.plan_data && (subscriptionDetail.data.plan_data.duration_type.toLowerCase() == 'year' && parseInt(subscriptionDetail.data.plan_data.duration) > 99)) {
      // Show when user purchase life time plan greator then 100 years
      return <LifetimePlanSubscribe></LifetimePlanSubscribe>
    } else {
      return <>
        <div>
          <div className={styles.subscriptionDataDetails}>
            <div className={styles.SubscriptionCurrency}>
              <h1>
                {t("PREMINUM")}
              </h1>
            </div>
            <div className={styles.subscriptionDatas}>
              <h6>{t("SUBSCRPTION_EXPIRE")}</h6>
              <p>
                {subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration} { }
                {subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration > 1
                  ? (subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration_type.toLowerCase()) + 's'
                  : (subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration_type.toLowerCase())}
              </p>

            </div>
            <div className={styles.subscriptionDatas}>
              <h6>{t("PURCHASED_DATE")}</h6>
              <p>
                {moment(
                  subscriptionDetail && subscriptionDetail.data.dtStartDate
                ).format("MMMM DD, YYYY")}
              </p>
            </div>
            <div className={styles.subscriptionDatas}>
              <h6>{t("SUBSCRIPTION_IN")}</h6>
              <p>
                {subscriptionDetail && subscriptionDetail.data && subscriptionDetail.data.endsIn ?
                  subscriptionDetail.data.endsIn.toLowerCase().replace("left", "") : ''}
              </p>
            </div>
            <div className={styles.subscriptionDatas}>
              <h6>{t("NEXT_BILL_DATE")}</h6>
              <p>
                {moment(
                  subscriptionDetail && subscriptionDetail.data.dtEndDate
                ).format("MMMM DD, YYYY")}
              </p>
            </div>
            <div className={styles.subscriptionDatas}>
              <h6>{t("NEXT_BILLING_AMOUNT")}</h6>
              <p>${subscriptionDetail && subscriptionDetail.data.fAmount} {subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.subcription_currency}</p>
            </div>
          </div>
          <div className={styles.subscriptionBtns}>
            <div className={styles.subscriptionBtn}>
              <button onClick={handlePopup}>
                {t("CANCEL_SUBSCRIPTION_BUTTON")}
              </button>
            </div>
          </div>
          <p className={styles.cancelText}>{t("CANCEL_TEXT")} {moment(
            subscriptionDetail && subscriptionDetail.data.dtEndDate
          ).format("MMMM DD, YYYY")}.</p>
        </div>
      </>
    }
  }

  // Show when user show admin subscription 
  function AdminPlanSubscribe() {
    if (subscriptionDetail.data && subscriptionDetail.data.isLifetimePlan == "1") {
      // Show when user purchase life time plan greator then 100 years
      return <LifetimePlanSubscribe></LifetimePlanSubscribe>
    } 
    else {
      return <>
        <div>
          <div className={styles.subscriptionDataDetails}>
            <div className={styles.SubscriptionCurrency}>
              <h1>
                {t("PREMINUM")}
              </h1>
            </div>
            <div className={styles.subscriptionDatas}>
              <h6>{t("SUBSCRPTION_EXPIRE")}</h6>
              <p>
                {subscriptionDetail.data && subscriptionDetail.data.vSubscriptionTitle.toLowerCase()}
              </p>

            </div>
            <div className={styles.subscriptionDatas}>
              <h6>{t("PURCHASED_DATE")}</h6>
              <p>
                {moment(
                  subscriptionDetail && subscriptionDetail.data && subscriptionDetail.data.dtStartDate
                ).format("MMMM DD, YYYY")}
              </p>
            </div>
            <div className={styles.subscriptionDatas}>
              <h6>{t("SUBSCRIPTION_IN")}</h6>
              <p>
                {subscriptionDetail && subscriptionDetail.data && subscriptionDetail.data.endsIn ?
                  subscriptionDetail.data.endsIn.toLowerCase().replace("left", "") : ''}
              </p>
            </div>
          </div>
        </div>
      </>
    }
  }

  // Show when user purchase life time plan greator then 100 years
  function LifetimePlanSubscribe() {
    if (!subscriptionDetail) return false;
    return <>
      <div className={styles.subscriptionDataDetails}>
        <div className={styles.SubscriptionCurrency}>
          <h1>
            {t("PREMINUM")}
          </h1>
        </div>
        <div className={styles.subscriptionDatas}>
          <h5>You have a Lifetime Membership-a one-time purchase lasting 100 years with no need for further subscription.
            <br></br>No renewals required</h5>
        </div>
        <div className={styles.subscriptionDatas}>
          <h6>Purchased Date</h6>
          <p>
            {moment(
              subscriptionDetail && subscriptionDetail.data.dtStartDate
            ).format("MMMM DD, YYYY")}
          </p>
        </div>
        {subscriptionDetail.data && subscriptionDetail.data.subscribe_by == "other" ?
          <>
            <div className={styles.subscriptionDatas}>
              <h6>Lifetime Membership</h6>
              <p>{(subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.currency_symbol ? subscriptionDetail.data.plan_data.currency_symbol : "$")}{subscriptionDetail.data && subscriptionDetail.data.plan_data.subscription_amount} {subscriptionDetail.data && subscriptionDetail.data.plan_data.subcription_currency}</p>
            </div>
            <div className={styles.subscriptionDatas}>
              <h6>Total Price</h6>
              <p>{(subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.currency_symbol ? subscriptionDetail.data.plan_data.currency_symbol : "$")}{subscriptionDetail.data && subscriptionDetail.data.plan_data.subscription_amount} {subscriptionDetail.data && subscriptionDetail.data.plan_data.subcription_currency}</p>
            </div>
          </>
          : <div className={styles.subscriptionDatas}>
            <h6>Lifetime Membership</h6>
            <p>Subscription By Admin</p>
          </div>}
      </div>
    </>
  }

  // Show when user take free subscription
  function FreePlanSubscribe() {
    let subscribeEndDate = getFreeTrialEndDate();
    return <>
      <div className={styles.subscriptionDataDetails}>
        <div className={styles.SubscriptionCurrency}>
          <h1>
            {t("PREMINUM")}
          </h1>
        </div>

        <div className={`${styles.subscriptionDatas} ${styles.subscriptionRemoveBorder}`}>
          <h4>
            You're in {subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.trial_duration}-{subscriptionDetail.data && subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.trial_duration_type}{(subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.trial_duration > 1) ? 's' : ""} trial.<br></br>
            Starting {moment(
              subscriptionDetail && subscriptionDetail.data.dtStartDate
            ).format("MMMM DD")}, your plan will renew for the regular price of {subscriptionDetail && (subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.currency_symbol ? subscriptionDetail.data.plan_data.currency_symbol : "$") + `${subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.subscription_amount}  ${subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.subcription_currency} `}
            per {parseInt(subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration) > 1 ? subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration + ' ' + subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration_type.toLocaleLowerCase() + 's' : subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.duration_type.toLocaleLowerCase()}, until canceled.
            <br></br>
            (Renews until you cancel)
          </h4>
        </div>
        <div className={styles.cancelTrialButton}>
          <div className={styles.subscriptionBtn}>
            <button onClick={handleFreePopup}>
              Cancel Free Trial
            </button>
          </div>
          <p className={styles.cancelText}>If you cancel now, you can still access your subscription until {subscribeEndDate}.</p>
        </div>
      </div>
    </>
  }

  // Show upgrade premium button
  function UpgradePlanButton() {
    return (
      <div className={styles.subscriptionPart}>
        <h2 className="text-white">{t("PROFILE_SUBSCRIPTION")}</h2>
        <div className={styles.logoutBtn}>
          <button onClick={() => navigate("/subscription")}>
            {t("PROFILE_PRIMIUM")}
          </button>
        </div>
      </div>
    )
  }


  // function CheckSubscriptionStatus() {
  //   // isTrial:1 Free trial 
  //   // plan_data.free_trial:1 means normal plan type
  //   // plan_data.free_trial:0 means Free plan type
  //   // iStatus:1 means Active 
  //   // iStatus:2 means Cancel 
  //   // check user have subscription or not
  //   if (currentUser && Number(currentUser.is_subscribed) !== 0 && subscriptionDetail && subscriptionDetail.success !== "0") {

  //     // check user have subscription or not
  //     if (subscriptionDetail.data && subscriptionDetail.data.subscribe_by == "other") {
  //       // check user subscription end date day cross with current day

  //       if (new Date(subscriptionDetail.data.dtEndDate) < currentDate) {
  //         return <UpgradePlanButton></UpgradePlanButton>
  //       }
  //       else if (subscriptionDetail.data.iStatus == "1") {
  //         // check user subscription active 
  //         if (subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.free_trial == "1") {
  //           // when user subscription active & take normal plan
  //           return <NormalPlanSubscribe></NormalPlanSubscribe>
  //         } else {
  //           // when user subscription Active & take free plan
  //           if (subscriptionDetail.data.isTrial && subscriptionDetail.data.isTrial == "1") {
  //             return <FreePlanSubscribe></FreePlanSubscribe>
  //           } else {
  //             // when free user subscription convert in normal plan
  //             return <NormalPlanSubscribe></NormalPlanSubscribe>
  //           }
  //         }

  //       } else if (subscriptionDetail.data.iStatus == "2") {
  //         if (subscriptionDetail.data.plan_data && subscriptionDetail.data.plan_data.free_trial == "0" && subscriptionDetail.data.isTrial == '1') {
  //           // when user subscription Free Cancel 
  //           return <FreeTrialPlanCancel></FreeTrialPlanCancel>
  //         } else {
  //           // when user subscription Cancel normal plan
  //           return <NormalPlanCancel></NormalPlanCancel>
  //         }
  //       } else {
  //         // when user subscription Cancel normal plan
  //         return <ShowSubscriptionInfo></ShowSubscriptionInfo>
  //       }


  //     } else {
  //       // when subscription given by admin
  //       return <AdminPlanSubscribe></AdminPlanSubscribe>
  //     }
  //   } else {
  //     // upgrade button purchase new one
  //     return <UpgradePlanButton></UpgradePlanButton>
  //   }
  // }
  function CheckSubscriptionStatus() {
    if (!currentUser || !subscriptionDetail || subscriptionDetail.success === "0") {
      // User does not have a subscription
      return <UpgradePlanButton />;
    }
  
    const { data } = subscriptionDetail;
  
    if (!data) {
      return <UpgradePlanButton />;
    }
  
    if (data.subscribe_by === "other") {
      const subscriptionEndDate = new Date(data.dtEndDate);
      const isSubscriptionExpired = subscriptionEndDate < currentDate;
  
      if (isSubscriptionExpired) {
        return <UpgradePlanButton />;
      }
  
      if (data.iStatus === "1") {
        // Subscription is active
        if (data.plan_data?.free_trial === "1") {
          // Active normal plan
          return <NormalPlanSubscribe />;
        } else {
          // Free plan subscription logic
          return data.isTrial === "1" ? <FreePlanSubscribe /> : <NormalPlanSubscribe />;
        }
      } else if (data.iStatus === "2") {
        // Subscription is canceled
        if (data.plan_data?.free_trial === "0" && data.isTrial === "1") {
          return <FreeTrialPlanCancel />;
        }
        return <NormalPlanCancel />;
      } else {
        return <ShowSubscriptionInfo />;
      }
    }
  
    // Subscription given by admin
    return <AdminPlanSubscribe />;
  }
  
  return (
    <>
      <CheckSubscriptionStatus></CheckSubscriptionStatus>
    </>
  );
}
export default SubscriptionData;
