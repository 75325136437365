import { Container } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "../../Subscription/SubscriptionDetail.module.css";
import "../../../assets/Styles/Common.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import paypal from "../../../assets/Images/paypal.svg";
import { useEffect, useState } from "react";
import visa from "../../../assets/Images/paymentmethods.png";
import SubHeader from "../../HeaderSection/SubHeader";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../../utils/Toaster";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { useTranslation } from "react-i18next";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { PLAN, TRANSACTION_TYPE, SESION_TRANSACTION_TYPE } from "../../../utils/Constants";
import Loader from "../../../utils/Loader/Loader";
import $ from "jquery";
import Swal from "sweetalert2";
import stripe from "../../../assets/Images/stripeLogo.png";

//--------function for paypal order create on sale-----------
function FitnessPlanView() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const details = location.state;
    const { id, type } = useParams();
    const [orderID, setOrderID] = useState("");
    const [planDetails, setPlanDetails] = useState("");
    const { userToken, currentUser } = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const user_id = queryParams.get("user_id");
    const token = queryParams.get("token");
    const sessionId = queryParams.get("session_id");
    const paymentType = queryParams.get("type");
    const plan_type = localStorage.getItem("plan_type");
    const plan_id = localStorage.getItem("plan_id")


    const notifyiOSApp = () => {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.paymentSuccessful) {
            window.webkit.messageHandlers.paymentSuccessful.postMessage("payment_success");
        } else {
            window.paymentSuccessful.postMessage("payment_success");
        }
    };

    // --------function for open component top of the page ----------
    useEffect(() => {
        $("document").ready(function () {
            $(window).scrollTop(0);
        });
    }, []);

    //---------Design paypal button-----------
    const buttonStyle = {
        layout: 'horizontal',
        color: 'blue',
        shape: 'pill',
        label: 'buynow',
        size: 'medium',
        tagline: false,
        height: 45,
    };

    // ---------function for workout plan details api calling---------- 
    useEffect(() => {
        const userId = user_id
        async function getPlanDetails() {
            setIsLoading(true);
            const workoutPlan = await SublyApi.workoutPlanDetails(id, type, userId);
            setIsLoading(false);
            if (workoutPlan && workoutPlan.settings && workoutPlan.settings.success && workoutPlan.settings.success == STATUS_CODES.SUCCESS_API) {
                setPlanDetails(workoutPlan && workoutPlan.settings.data);
            }
        }
        getPlanDetails();
    }, []);

    useEffect(() => {
        const handlePaymentSuccess = async () => {
            if (!sessionId) return;

            let data = {
                session_id: sessionId,
                transaction_type: TRANSACTION_TYPE.SALE,
                transaction_sub_type: planDetails?.plan_type == "workout_plan" || plan_type == "workout_plan" ? PLAN.WORKOUT_PLAN : PLAN.STREAM_WORKOUT_PLAN,
                plan_type: planDetails?.plan_type || plan_type,
                transaction_sub_type_id: planDetails.workout_id || plan_id
            };

            try {
                setIsLoading(true);

                const res = await SublyApi.planPaymentSuccess(data, token);

                if (res.settings.success == 1) {
                    Toast.fire({
                        icon: "success",
                        title: res.settings.message,
                    });
                    //   navigate("/subscription-tab");
                    Swal.fire({
                        title: `THANK YOU FOR PURCHASING THIS PLAN`,
                        icon: "success",
                        allowOutsideClick: false,
                        customClass: "swalAlertBox",
                        backdrop: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            notifyiOSApp();
                        }
                    });
                } else {
                    Toast.fire({
                        icon: "error",
                        title: "Payment failed. Please try again.",
                    });
                }
            } catch (error) {
                console.error("Payment verification failed:", error);
                Toast.fire({
                    icon: "error",
                    title: "Something went wrong. Please try again.",
                });
            } finally {
                setIsLoading(false);
            }
        };

        // Handle Payment Cancellation
        if (paymentType == "cancel") {
            Toast.fire({
                icon: "error",
                title: "Payment failed. Please try again.",
            });
            return;
        }

        handlePaymentSuccess();
    }, [sessionId]);


    async function ManageSessionExpire() {
        const requestData = new FormData();
        requestData.append("transaction_type", SESION_TRANSACTION_TYPE.SALE);
        requestData.append("plan_id", planDetails && planDetails.workout_id);
        requestData.append("plan_type", planDetails && planDetails.plan_type == "workout_plan"
            ? PLAN.WORKOUT_PLAN
            : PLAN.STREAM_WORKOUT_PLAN);
        return await SublyApi.sessionManage(requestData, token).then((response) => {
            if (response.settings.success == STATUS_CODES.SUCCESS_API) {
                return true;
            }
            else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
                Toast.fire({
                    icon: "error",
                    title: t("SESSION_EXPIRE"),
                });
                dispatch(userLogoutClear());
                navigate("/login");
            } else {
                return false;
            }
        }
        );
    }

    //---------creates a paypal order-----------
    const createOrder = async (data, actions) => {
        const res = await ManageSessionExpire();
        if (res) {
            return actions.order
                .create({
                    purchase_units: [
                        {
                            amount: {
                                currency_code: planDetails && planDetails.currency,
                                value: planDetails && planDetails.amount,
                            },
                        },
                    ],
                })
                .then((orderID) => {

                    setOrderID(orderID);
                    return orderID;
                });
        } else {
            return false;
        }
    };




    //--------Save details of sale purchase api integration--------- 
    const onApprove = (data, actions) => {
        return actions.order.capture().then(async function (paymentData) {
            // Handle successful payment
            let requestData = new FormData();
            requestData.append("transaction_type", TRANSACTION_TYPE.SALE);
            requestData.append("transaction_sub_type", planDetails && planDetails.plan_type == "workout_plan" ? PLAN.WORKOUT_PLAN : PLAN.STREAM_WORKOUT_PLAN);
            requestData.append("plan_type", planDetails && planDetails.plan_type);
            requestData.append("transaction_sub_type_id", planDetails && planDetails.workout_id);
            requestData.append("order_id", paymentData.id);
            requestData.append("transaction_id", paymentData.purchase_units[0].payments.captures[0].id);
            requestData.append("payment_json", JSON.stringify(paymentData));
            await SublyApi.salePurchase(requestData, token).then(async (response) => {
                if (response.success == STATUS_CODES.SUCCESS_API) {
                    if (requestData.get("plan_type") == "workout_plan") {
                        await SublyApi.salePlanInfo(token, requestData.get('transaction_sub_type_id'), (currentUser && currentUser.customer_id) ? currentUser.customer_id : "").then(async (response) => {
                            if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
                                Swal.fire({
                                    title: `THANK YOU FOR PURCHASING THIS PLAN`,
                                    icon: "success",
                                    allowOutsideClick: false,
                                    customClass: "swalAlertBox",
                                    backdrop: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        notifyiOSApp();
                                    }
                                });
                            } else if (response && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
                                Toast.fire({
                                    icon: "error",
                                    title: t("SESSION_EXPIRE"),
                                });
                                dispatch(userLogoutClear());
                                navigate("/login");
                            }
                        });
                    } else {
                        Swal.fire({
                            title: `THANK YOU FOR PURCHASING THIS PLAN`,
                            icon: "success",
                            allowOutsideClick: false,
                            customClass: "swalAlertBox",
                            backdrop: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                notifyiOSApp()
                            }
                        });
                    }
                } else if (response && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
                    Toast.fire({
                        icon: "error",
                        title: t("SESSION_EXPIRE"),
                    });
                    dispatch(userLogoutClear());
                    navigate("/login");
                } else {
                    Toast.fire({
                        icon: "error",
                        title: response.message,
                    });
                }
            });
        });
    };



    const makePayment = async () => {
        setIsLoading(true);
        const currentUrl = new URL(window.location.href);
        const params = new URLSearchParams(currentUrl.search);
        params.delete("type");

        const newRedirectUrl = `${currentUrl.origin}${currentUrl.pathname}${params.toString() ? "?" + params.toString() : ""}`;
        let data = {
            id: planDetails?.workout_id,
            type: planDetails?.plan_type,
            platform_type: "app",
            redirect_url: newRedirectUrl,
        }

        const res = await SublyApi.stripeCheckout(data, token)
        if (res.settings) {
            setIsLoading(false)
            localStorage.setItem("plan_id", planDetails?.workout_id)
            localStorage.setItem("plan_type", planDetails?.plan_type)
            window.location.href = res.settings.data.url
        }
    }



    return (
        <>
            {/* <SubHeader /> */}
            {isLoading === true ? <Loader /> :
                (<>


                    <div className="main">

                        {planDetails ?
                            <Container>
                                <div className="boxWrapper">
                                    <div className="innerboxWrapper">
                                        <div className={styles.subDetail}>
                                            <div className={styles.subscriptionPayBox}>
                                                <div className={styles.summaryTexts}>
                                                    <h2>{t("SALE_SUMMARY")}</h2>
                                                </div>
                                                <div className={styles.payTexts}>
                                                <p style={{ textAlign: "center" }}>
                                                        {t("PLAN_TEXT")}
                                                        <br />
                                                        {planDetails?.workout_image && (
                                                            <img
                                                                src={planDetails.workout_image}
                                                                alt={planDetails?.workout_name}
                                                                style={{ width: "50px", height: "60px", borderRadius: "5px", objectFit: "cover", display: "block", margin: "5px auto" }}
                                                            />
                                                        )}
                                                    </p>
                                                    {planDetails && planDetails.workout_name ?
                                                        <p>{planDetails && planDetails.workout_name}</p> :
                                                        <p>{t("EXCLUSIVE")}</p>
                                                    }
                                                </div>
                                                <div className={styles.timeTexts}>
                                                    <p>{t("PRICE_TEXT")}</p>
                                                    <p>{planDetails && planDetails.currency_symbol}{planDetails && planDetails.amount}</p>
                                                </div>
                                                <div className={styles.priceTexts}>
                                                    <p>{t("TOTAL_PRICE")}</p>
                                                    <p>{planDetails && planDetails.currency_symbol}{planDetails && planDetails.amount}</p>
                                                </div>
                                            </div>

                                            <div className={styles.subscriptionDetailText}>
                                                <p>
                                                    {t("SUBSCRIPTION_NOTE")}<span> {t("SUBSCRIPTION_NOTE_PARA")}</span>
                                                </p>
                                            </div>

                                            {/* {planDetails && planDetails.acess_level && planDetails.acess_level == ACCESS_LEVEL.LOCK ? */}

                                            {planDetails &&
                                                planDetails.amount == "0" ?
                                                "" :
                                                <>
                                                    <div className={styles.backButton}>
                                                        {/* <div className={styles.payPal}> */}
                                                            {/* <PayPalScriptProvider options={{ clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
                                                                <PayPalButtons
                                                                    createOrder={createOrder}
                                                                    onApprove={onApprove}
                                                                    style={buttonStyle}
                                                                />
                                                            </PayPalScriptProvider> */}
                                                            <button onClick={() => makePayment()} className={styles.paymentButton} style={{border : "none"}}>
                                                                    <img src={stripe} alt="Stripe Logo" className={styles.stripeLogo} />
                                                                    Buy Now
                                                                </button>
                                                        {/* </div> */}
                                                    </div>
                                                    <div className={styles.payPalImg}>
                                                        <img src={visa} alt="logo" />
                                                    </div>
                                                    <div className={styles.poweredImg}>
                                                        {/* <h4>{t("POWERED_BY")}</h4> */}
                                                        {/* <img src={paypal} alt="logo" /> */}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Container> : ""}
                    </div>
                </>)}
        </>
    );
}
export default FitnessPlanView;
