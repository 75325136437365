import styles from "../CreatePage/DetailPage.module.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bg1 from "../../assets/Images/bg1.jpg";
import bg2 from "../../assets/Images/bg2.jpg";
import { useEffect, useState } from "react";
import HeaderSection from "../HeaderSection/HeaderSection";
import { Col, Container, Row } from "react-bootstrap";
import playstore from "../../assets/Images/playstore.png";
import appstore from "../../assets/Images/appstore.png";
// import TrainerData from "./TrainerData";
import SublyApi from "../../helpers/Api";
import HomePageSlider from "../../commonComponents/HomePageSlider/HomePageSlider";
import { HOME_IMAGE } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setBgImages } from "../../store/slices/UserSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import $ from "jquery";
import { getCreatorCategoryList } from "../../store/slices/CreatorSlice";
import Loader from "../../utils/Loader/Loader";
import { BiArrowBack } from "react-icons/bi";
import SubHeader from "../HeaderSection/SubHeader";
import FooterSection from "../FooterSection/FooterSection";



// -------------function for home page-----------
function DetailPage() {
  const { t } = useTranslation();
  const [packageList, setPackageList] = useState("");
  const [backgroundImage, setBackgroundImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [bgIndex, setBgIndex] = useState(0);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { backgroundImageRes } = useSelector((state) => state.user);
  const creatorCategoryList = useSelector((state) => state.creatorList.creatorCategoryList);
  const { userToken, currentUnit, updateUser } = useSelector((state) => state.user);
  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);


  useEffect(() => {
    if (id) {
      setIsLoading(true)
      dispatch(
        getCreatorCategoryList({ id, authToken: userToken || null })
      ).then(() => {
        setIsLoading(false)
      })
    }
  }, [id]);




  //---------function for get home image slider api calling----------
  useEffect(() => {
    async function imageSlider() {
      dispatch(setBgImages(HOME_IMAGE.IMAGE_TYPE)).then((responsejson) => {
        const response = responsejson.payload;
        if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
          setBackgroundImage(response.settings.data[0].webImage);
        }
      });
    }
    imageSlider();
  }, []);

  //---------function for home background image change---------
  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImage((previousImage) => {
        if (backgroundImageRes.length > 0) {
          let lastImgIndex = backgroundImageRes.length - 1;
          if (lastImgIndex == bgIndex) {
            setBgIndex(0);
            const webImage = backgroundImageRes[0].webImage;
            return webImage;
          } else {
            const newBgIndex = bgIndex + 1;
            setBgIndex(newBgIndex);
            const webImage = backgroundImageRes[newBgIndex].webImage;
            return webImage;
          }
        } else {
          setBackgroundImage(bg1);
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [bgIndex]);

  //---------function for subscription api calling----------
  useEffect(() => {
    async function subscriptionPackageList() {
      const details = await SublyApi.getPackageList();
      details && setPackageList(details.data);
    }
    subscriptionPackageList();
  }, []);


  const maxLength = 100;

  // Toggling the visibility of full text
  const toggleText = () => {
    setShowFullText((prevState) => !prevState);
  };

  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate("/creator");
    }
  };

  const handleNavigate = (id) => {
    navigate(`/creatordetail/${id}`)
  }

  return (
    <>
      {isLoading === true ? <Loader /> :
        (
          <>

            <div className="mobileHide">
              <SubHeader />
            </div>
            <div className={`main ${creatorCategoryList?.CreatorCategoryTitle != "Featured" && "paddingNone"}`}>
              <div className="mbBack desktopHide">
                <button onClick={handleBack} className="back-button">
                  <span>
                    <BiArrowBack style={{ color: "white" }} />
                  </span>
                </button>
              </div>
              <Container>
                <div className="boxWrapper">
                  <div className="innerboxWrapper">

                    <button onClick={handleBack} className="back-button mobileHide">
                      <span>
                        <BiArrowBack style={{ color: "white" }} />
                      </span>
                    </button>

                    {creatorCategoryList?.CreatorCategoryTitle != "Featured" &&
                      <div className="img-content-wrapper">
                        <Row className="align-items-center">
                          <Col sm={6}>
                            <div className={styles.creatorSingleImage}>
                              <img src={creatorCategoryList?.CreatorCategoryImage} alt="" />
                              <div className={styles.creatorSingleImageText}>
                                {creatorCategoryList?.TitleShowInApp == "Yes" && <h2>{creatorCategoryList?.CreatorCategoryTitle?.toUpperCase()}</h2>}
                                {creatorCategoryList?.SubtitleShowInApp == "Yes" && <h4>{creatorCategoryList?.CreatorCategorySubTitle?.toUpperCase()}</h4>}
                              </div>
                            </div>
                          </Col>

                          {creatorCategoryList?.CreatorCategoryTitle !== "Featured" && (
                            <Col sm={6}>

                              <p>
                                {/* {showFullText || creatorCategoryList?.Description?.length <= maxLength
                    ? creatorCategoryList?.Description
                    : `${creatorCategoryList?.Description?.slice(0, maxLength)}...`}
                  {creatorCategoryList?.Description?.length > maxLength && (
                    <button
                      onClick={toggleText}
                      style={{
                        background: "none",
                        border: "none",
                        color: "white",
                        cursor: "pointer",
                        paddingLeft: "5px",
                      }}
                    >
                      {showFullText ? "Read Less" : "Read More"}
                    </button>
                  )} */}
                                {creatorCategoryList?.Description}
                              </p>

                            </Col>
                          )}

                        </Row>
                      </div>
                    }
                    <div className={styles.trainerSection}>
                      <div
                        className={`${creatorCategoryList?.CreatorCategoryTitle === "Featured" ? "" : ""}`}
                      >
                        <div className="creatorDetailimages">
                          <Row>
                            {creatorCategoryList?.creator?.map((creator, index) => {
                              return (
                                <Col lg={3} md={3} sm={4} xs={4}>
                                  <div className="imagesBox">
                                    <img src={creator?.WebsiteImage} style={{ cursor: "pointer" }} onClick={() => {
                                      handleNavigate(creator?.iAdminId)
                                    }}></img>
                                    <div className="imagesBox_content">
                                      {creator?.WebsiteNameShowInApp == "Yes" && <h3>{creator?.WebsiteName}</h3>}
                                      {creator?.WebsiteSubTitleShowInApp == "Yes" && <h4 className="text-white">{creator?.WebsiteSubTitle}</h4>}
                                    </div>
                                  </div>
                                </Col>
                              )
                            })}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <FooterSection />
          </>
        )}
    </>
  );
}
export default DetailPage;
