import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";

const initialState = {
  creatorList: "",
  creatorCategoryList: "",
  creatorDetails: "",
  followedCreator: "",
  isLoading: false,
  success: false,
  error: null,
};

// Thunk for get creator list
export const getCreatorList = createAsyncThunk(
  "CreatorList",
  async (authToken, { rejectWithValue }) => {
    try {
      const response = await SublyApi.CategoryWiseCreators(authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getCreatorCategoryList = createAsyncThunk(
  "CreatorCategoryList",
  async ({ id, authToken }, { rejectWithValue }) => {
    try {
      const response = await SublyApi.CreatorCategory(id, authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getCreatorDetails = createAsyncThunk(
  "CreatorDetails",
  async ({ id, authToken }, { rejectWithValue }) => {
    try {
      const response = await SublyApi.CreatorDetail(id, authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const followCreator = createAsyncThunk(
  "followCreator",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.FollowCreator(data.creator_id, data.authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const UnfollowCreator = createAsyncThunk(
  "UnfollowCreator",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.UnfollowCreator(data.creator_id, data.authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const ReportCreator = createAsyncThunk(
  "ReportCreator",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.reportCreator(data, data.authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getFollowedCreator = createAsyncThunk(
  "getFollowedCreator",
  async (authToken, { rejectWithValue }) => {
    try {
      const response = await SublyApi.FollowedCreator(authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const goTOCreatorApp = createAsyncThunk(
  "goTOCreatorApp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.GoToCreatorApp(data.creator_id, data.authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get purchased plan list
// export const getPurchasedPlan = createAsyncThunk(
//   "planList/getPurchasedPlan",
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await SublyApi.saleDetailsShow(data.page, data.userToken);
//       return response;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const creatorListSlice = createSlice({
  name: "createList",
  initialState,
  reducers: {
    updateCreatorDetails: (state, action) => {
      state.creatorDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    // ------Builder for creator list--------
    builder.addCase(getCreatorList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCreatorList.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        state.creatorList = response.settings.data;
        state.success = true;
      } else {
        state.success = false;
        state.creatorList = [];
      }
    });

    builder.addCase(getCreatorList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for creator category list--------

    builder.addCase(getCreatorCategoryList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCreatorCategoryList.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        state.creatorCategoryList = response.settings.data;
        state.success = true;
      } else {
        state.success = false;
        state.creatorCategoryList = [];
      }
    });

    builder.addCase(getCreatorCategoryList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for creator detail list--------

    builder.addCase(getCreatorDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCreatorDetails.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        state.creatorDetails = response.settings.data;
        state.success = true;
      } else {
        state.success = false;
        state.creatorCategoryList = [];
      }
    });

    builder.addCase(getCreatorDetails.rejected, (state, action) => {
      state.isLoading = false;
    });


    // ------Builder for follow creator--------

    builder.addCase(followCreator.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(followCreator.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
    });

    builder.addCase(followCreator.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for Unfollow creator--------

    builder.addCase(UnfollowCreator.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(UnfollowCreator.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
    });

    builder.addCase(UnfollowCreator.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for Report creator--------

    builder.addCase(ReportCreator.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(ReportCreator.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
    });

    builder.addCase(ReportCreator.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for followd creator--------

    builder.addCase(getFollowedCreator.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getFollowedCreator.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        state.followedCreator = response.settings;
        state.success = true;
      } else {
        state.success = false;
        state.creatorCategoryList = [];
      }
    });

    builder.addCase(getFollowedCreator.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for go to app--------

    builder.addCase(goTOCreatorApp.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(goTOCreatorApp.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
    });

    builder.addCase(goTOCreatorApp.rejected, (state, action) => {
      state.isLoading = false;
    });

  },
});


export const { updateCreatorDetails } = creatorListSlice.actions;
export default creatorListSlice.reducer;

