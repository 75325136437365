import styles from "../Subscription/Subscription.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SublyApi from "../../helpers/Api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HomePageSlider from "../../commonComponents/HomePageSlider/HomePageSlider";
import SubHeader from "../HeaderSection/SubHeader";
import { STATUS_CODES } from "../../utils/StatusCode";
import Loader from "../../utils/Loader/Loader";
import $ from "jquery";
import FooterSection from "../FooterSection/FooterSection";
import { GetHomePage } from "../../store/slices/UserSlice";

//---------function for subscription page---------- 
function Subscription() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, userToken } = useSelector((state) => state.user);
  const [packageList, setPackageList] = useState("");
  const [sliderTitle, setSliderTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { homePageData } = useSelector((state) => state.user);
   const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);
  
    // Effect to handle resize event and update the isMobile state
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 575);
      };
  
      // Add resize event listener
      window.addEventListener("resize", handleResize);
  
      // Clean up the event listener when the component is unmounted
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  //---------function for subscription api calling----------
  useEffect(() => {
    async function subscriptionPackageList() {
      setIsLoading(true);
      const details = await SublyApi.getPackageList();
      setIsLoading(false);
      if (details && details.settings && details.settings.success && details.settings.success == STATUS_CODES.SUCCESS_API) {
        setPackageList(details && details.data);
        setSliderTitle(details && details?.data?.get_trainer_title);
      }
    }
    subscriptionPackageList();
    dispatch(GetHomePage({ authToken: userToken || null }))
  }, []);

  return (
    <>
      {isLoading === true ? <Loader /> :
        (
          <>
            <SubHeader />
            <div className="main">
              <Container>
                <div className="boxWrapper">
                  <div className="innerboxWrapper">

                    {packageList ?
                      <div>

                        {packageList && packageList.get_static_content.length >= 1 && (
                          <div className="text-center">
                            <div className={styles.subscriptionOffer}>
                              <div className="heading">
                                <h2>{packageList.get_static_content[0].title}</h2>
                              </div>
                              <p>{packageList.get_static_content[0].content}</p>
                            </div>
                          </div>
                        )}
                        {packageList && packageList.get_all_packages.length < 3 ? (
                          <Row className={styles.subscriptionInner}>
                            {packageList && packageList.get_all_packages.length > 0 &&
                              packageList.get_all_packages.map((item, index) => (
                                <Col
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  className={styles.subRow}
                                  key={item.index}
                                >
                                  <div
                                    className={styles.subscriptionbox}
                                    style={{
                                      background: `${item.color_code}`,
                                    }}
                                  >
                                    {item?.isTag == 1 ? <span className={styles.label}>{item?.vtagName}</span> : <span className={styles.label} style={{
                                      background: `${item.color_code}`,
                                    }}>

                                    </span>}
                                    <p className={styles.timeSection}>
                                      {item.package_name}
                                    </p>
                                    <div className={styles.subText}>
                                      <p>{item.package_description}</p>
                                    </div>
                                    <div className={styles.amountSection}>
                                      {item.subscription_amount &&
                                        <p>
                                          $<span>{item.subscription_amount}</span>
                                        </p>}
                                      <p className={styles.monthText}></p>
                                    </div>
                                    <div className={styles.subBtn}>
                                      {Object.keys(currentUser).length == 0 ? (
                                        <button
                                          onClick={() => {
                                            navigate("/login", {
                                              state: {
                                                packageName: true,
                                                packageDetail: item,
                                              },
                                            });
                                          }}
                                        >
                                          {t("SUBSCRIBE")}
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() =>
                                            navigate(`/subscription/detail`, {
                                              state: {
                                                packageName: true,
                                                packageDetail: item,
                                              },
                                            })

                                          }
                                        >
                                          {t("SUBSCRIBE")}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              ))}
                          </Row>
                        ) : (
                          <Row className={styles.subscriptionInners}>
                            {packageList && packageList.get_all_packages.length > 0 &&
                              packageList.get_all_packages.map((item, index) => (
                                <>
                                  <Col
                                    lg={4}
                                    md={3}
                                    sm={6}
                                    xs={6}
                                    className={styles.subRow}
                                    key={item.index}
                                  >
                                    <div
                                      className={styles.subscriptionbox}
                                      style={{
                                        background: `${item.color_code}`,
                                      }}
                                    >
                                       {item?.isTag == 1 ? <span className={styles.label}>{item?.vtagName}</span> : <span className={styles.label} style={{
                                      background: `${item.color_code}`,
                                    }}>

                                    </span>}
                                      <p className={styles.timeSection}>
                                        {item.package_name}
                                      </p>

                                      <div className={styles.subText}>
                                        <p>{item.package_description}</p>
                                      </div>
                                      <div className={styles.amountSection}>
                                        {item.subscription_amount &&
                                          <p>
                                            $<span>{item.subscription_amount}</span>
                                          </p>}
                                        <p className={styles.monthText}></p>
                                      </div>
                                      <div className={styles.subBtn}>
                                        {Object.keys(currentUser).length == 0 ? (
                                          <button
                                            onClick={() => {
                                              navigate("/login", {
                                                state: {
                                                  packageName: true,
                                                  packageDetail: item,
                                                },
                                              });
                                            }}
                                          >
                                            {t("SUBSCRIBE")}
                                          </button>
                                        ) : (
                                          <button
                                            onClick={() =>
                                              navigate(`/subscription/detail`, {
                                                state: {
                                                  packageName: true,
                                                  packageDetail: item,
                                                },
                                              })
                                            }
                                          >
                                            {t("SUBSCRIBE")}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </>
                              ))}
                          </Row>
                        )}

                        {!isMobile &&<div className={styles.borderBottom}>
                          <span></span>
                        </div>}



                        {packageList && packageList.get_static_content.length > 1 && (
                          <div className="text-center">
                            <div className={styles.subTerms}>
                              <h2>{packageList.get_static_content[1].title}</h2>
                              <p>{packageList.get_static_content[1].content}</p>
                            </div>
                            <div className={styles.borderBottom}>
                              <span></span>
                            </div>
                          </div>
                        )}


                        <div className="sliderContent">
                          {packageList &&
                            <div className={styles.subBorder}>
                              <div className={styles.storyTittle}>

                              </div>
                              <div className={styles.middle_hadding}>
                                <h2>{sliderTitle}</h2>
                              </div>
                              <HomePageSlider packageList={homePageData?.middleImage} />
                            </div>
                          }
                        </div>
                        <div>
                          {packageList &&
                            <div className={styles.borderBottom}>
                              <span></span>
                            </div>
                          }
                        </div>

                        <div>
                          {packageList && packageList.get_subscription_terms.length > 0 &&
                            packageList.get_subscription_terms.map((item, index) => (
                              <div key={index}>
                                <div className={styles.subTerms}>
                                  <div className="heading">
                                    <h2>{item.title}</h2>
                                  </div>
                                  <p>{item.content}</p>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div> : ""
                    }

                  </div>
                </div>
              </Container>
            </div>
            <FooterSection />
          </>
        )}
    </>
  );
}
export default Subscription;
