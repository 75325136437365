import { useNavigate } from "react-router-dom";
import "../PageNotFound/PageNotFound.css";
import { useTranslation } from "react-i18next";

function CreaterNotFound() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
        <div className="main">
            <section className="notFoundWrapper">
                <h1 className="findError">{t("TITTLE_TEXT")}</h1>
                <h1 className="notFound">Requested creator is not longer available.</h1>
                <div className="signUpBtn">
                <button onClick={()=>{
                    navigate("/creator")
                }}>Back to Creator</button>
                </div>
            </section>
            </div>
        </>
    );
}
export default CreaterNotFound;