import { Col, Container, Form, Row } from "react-bootstrap";
import styles from "./CreateUser.module.css";
import "../../assets/Styles/Common.css";
import { useTranslation } from "react-i18next";
import ProfileImg from "../../assets/Images/user.png";
import { AiFillCamera } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import SignupDone from "./SignupDone";
import {
  checkUser,
  setSignupData1,
  checkEmailAvalibity,
  signUp,
} from "../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../utils/Loader/Loader";
import $ from "jquery";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toaster";

function CreateUser({ setSwitchTo, setPreviousValue }) {
  const { signupData1, isLoading } = useSelector((state) => state.user);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [profilePreview, setProfilePreview] = useState(ProfileImg);
  const [profileImage, setProfileImage] = useState("");
  const [imageError, setImageError] = useState("");
  const [loaderState, setLoaderState] = useState(false);
  const [isSignupButton, setSignupButton] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  //----- function for Upload update profile image-----
  function onImageChange(e) {
    const image = e.target.files[0];
    if (image && !image.name.match(/\.(jpg|jpeg|png|gif|webp|avif|apng)$/)) {
      setImageError("Select valid image.");
      Toast.fire({
        icon: "error",
        title: "Please Select only image. ",
      });
      setImageError("");
      return false;
    }
    if (e.target.files.length !== 0) {
      setProfilePreview(URL.createObjectURL(e.target.files[0]));
      setProfileImage(e.target.files[0]);
    }
    setImageError("");
    dispatch(setSignupData1({ profile: e.target.files[0] }));
  }

  // function for checkuser availbility api integration
  function onInputChnage(formdata) {
    let requestData = new FormData();
    requestData.append("user_name", formdata.target.value);
    dispatch(checkUser(requestData)).then((responsejson) => {
      const response = responsejson.payload;
      if (response.data.is_available == 0) {
        setError(t("ALREADY_USERNAME"));
      } else {
        setError("");
      }
    });

    setValue(formdata.target.name, formdata.target.value.replace(/\s/g, ''));
  }

  // function for final signup api
  const onSubmit = async (formdata) => {
    if (error == "" && imageError == "") {
      setLoaderState(true);
      let requestData = new FormData();
      requestData.append("user_name", formdata.userName.trim());
      requestData.append("name", signupData1.fullName.trim());
      requestData.append("email", signupData1.email.trim());
      requestData.append("profile_pic", profileImage);

      if (signupData1.dob == undefined || signupData1.dob == 'undefined') {
        requestData.append("dob", "");
      } else {
        requestData.append("dob", signupData1.dob);
      }
      requestData.append("country_id", signupData1.country_id);
      requestData.append("gender", signupData1.gender);
      requestData.append("height", signupData1.height);

      if ((signupData1.socialUser) && (signupData1.socialUser.isSocialLogin == true)) {
        requestData.append("social_network_id", signupData1.socialUser.social_network_id);
        requestData.append("social_network_type", signupData1.socialUser.social_network_type);
        // requestData.append("profile_pic", signupData1.socialUser.picture);
      } else {
        requestData.append("password", signupData1.password);
        requestData.append("confirm_password", signupData1.confirmPassword);
      }

      requestData.append("weight", signupData1.weight);
      requestData.append("device_type", "");
      requestData.append("device_token", "");

      await dispatch(checkEmailAvalibity(requestData)).then((responsejson) => {
        const response = responsejson.payload;
        requestData.append("email", signupData1.email.trim());
        if (response.data.is_available == 0) {
          setError(t("ALREADY_EMAIL"));
          setSwitchTo(0);
          setLoaderState(false);
          return false;
        } else {
          if (response.data.is_available == STATUS_CODES.SUCCESS_API) {
            requestData.append("user_name", formdata.userName.trim());
            dispatch(checkUser(requestData)).then((responsejson) => {
              const responseuser = responsejson.payload;
              const subdomain = localStorage.getItem("subdomain");
              requestData.append("subdomain", subdomain ? subdomain : "");
              if (responseuser && responseuser.data.is_available == STATUS_CODES.SUCCESS_API) {
                setSignupButton(true);
                dispatch(signUp(requestData)).then((responsejson) => {
                  const response = responsejson.payload;
                  if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
                    setSignupButton(false);
                    dispatch(setSignupData1(formdata));
                    setError("");
                    setIsOpen(true);
                    setLoaderState(false);
                    if(subdomain){
                    localStorage.setItem("userToken" , response.data[0].customer_auth_token)
                    }
                  } else {
                    // setError(t("SOME_WRONG"));
                    setSignupButton(false);
                    setLoaderState(false);
                  }
                });
              } else {
                setError(t("ALREADY_USERNAME"));
                setLoaderState(false);
              }
            });
          }
        }
      });
    }
  };

  return (
    <>
      {isLoading === true || loaderState === true ? <Loader /> : ""}

      <section className={`${styles.SignUpWrapper} LoginPage`}>
        <Container>
          <Row>
            <Col lg={8} className="m-auto">
              <div className={styles.FormBlock}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles.FormSection}>

                    <div className={styles.imageTitle}>
                      <h2>{t("PROFILE_PIC")}</h2>
                      <p>{t("PROFILE_PARA")}</p>

                      <div className="profileImgCls">
                        <div className="profileImageSet">
                          <img src={profilePreview} />
                          <input
                            id="uploadImage"
                            name="image"
                            type="file"
                            style={{
                              display: "none",
                            }}
                            accept="image/*"
                            onChange={onImageChange}
                          />
                          <label htmlFor="uploadImage">
                            <AiFillCamera />
                          </label>
                        </div>
                      </div>
                      {imageError && <p> <span className="errorMsg">{imageError}</span></p>}
                    </div>
                    <div className={styles.SignupTitle}>
                      <div className={styles.userHeading}>
                        <h2>{t("CREATE_USERNAME")}</h2>
                        <span>*</span>
                      </div>
                      <p>{t("CREATE_PARA")}</p>
                    </div>
                    <Form.Group className={styles.FormGroup}>
                      <Form.Control
                        className="form-control"
                        placeholder="username"
                        {...register("userName", {
                          onChange: (formdata) => onInputChnage(formdata),
                          required: {
                            value: true,
                            message: `${t("USERNAME")}`,
                          },
                          minLength: {
                            value: 3,
                            message: `${t("USERNAME_LENGTH")}`,
                          },
                          maxLength: {
                            value: 30,
                            message: `${t("USERNAME_MAXLENGTH")}`,
                          },
                          pattern: {
                            value: /(?!^\d+$)^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/,
                            message: `${t("USERNAME_WRONG")}`,
                          },
                        })}
                      />
                      {error && <span className="errorMsg">{error}</span>}

                      {(!error) && errors.userName && (
                        <span className="errorMsg">
                          {errors.userName.message}
                        </span>
                      )}
                    </Form.Group>


                    <div className={styles.signUpBtn}>
                      <button type="button" onClick={() => { setSwitchTo(1); setPreviousValue(true) }}>{t("PREVIOUS")}</button>
                      <button disabled={isSignupButton} type="submit" onClick={() => { handleSubmit() }}>{t("DONE")}</button>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <SignupDone isOpen={isOpen} setIsOpen={setIsOpen} />
      </section>
    </>
  );
}

export default CreateUser;
