import { Button, Collapse, Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import styles from "../HeaderSection/SubHeader.module.css";
import { Link, NavLink } from "react-router-dom";
import logo1 from "../../assets/Images/black_Icon.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { salesVisibility } from "../../store/slices/UserSlice";
import { STATUS_CODES } from "../../utils/StatusCode";

// -------------function for header section-----------
function HeaderSub() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentUser, salePlanVisible ,CreatorVisible , userToken , updateUser } = useSelector((state) => state.user);
    const [open, setOpen] = useState(false);
    const collapseRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // --------function for Global setting for sales plan visibility status api call--------
  useEffect(() => {
    dispatch(salesVisibility()).then((responsejson) => {
      const response = responsejson.payload;
    })
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (collapseRef.current && !collapseRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <header className={styles.subHeader}>
            <Navbar expand="md">
            <Container className={styles.headerContainer}>
              <Navbar.Brand>
                <NavLink to="/">
                  {Object.keys(currentUser).length == 0 ? (
                    <div className={styles.logoImg}>
                      <img src={logo1} alt="logo" />
                    </div>
                  ) : (
                    <div className={styles.logoImgdiv}>

                    </div>

                  )}
                </NavLink>
              </Navbar.Brand>
              <Button className="buttonToggle" onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}>
              <span className="navbar-toggler-icon"></span>
            </Button>
            <Navbar.Collapse id="mainmenu">

              <Nav className="ms-auto activeShow">
                {!userToken && CreatorVisible && CreatorVisible.value == "1" ? <NavLink className={styles.customNavLink} to="/creator">{t("Creators")}</NavLink> : ""}
                {salePlanVisible?.value == "1" ?
                  <NavLink className={styles.customNavLink} to="/exclusive">{t("SALE")}</NavLink>
                  : ""}
                {Object.keys(currentUser).length !== 0 ?
                  (currentUser && currentUser.is_subscribed == "0"  ? <NavLink className={styles.customNavLink} to="/subscription">
                    {t("SUBSCRIPTION")}
                  </NavLink> : "") :
                  <NavLink className={styles.customNavLink} to="/subscription">
                    {t("SUBSCRIPTION")}
                  </NavLink>}
              </Nav>
            </Navbar.Collapse>
            <Collapse in={open}>
              <div className={styles.socialBar} ref={collapseRef}>
                {!userToken && CreatorVisible && CreatorVisible.value == "1" ?
                  <Link to="/creator" style={{ borderBottom: "solid 1px #3A3A3A" }}>
                    {t("Creators")}
                  </Link> : ""}
                {salePlanVisible && salePlanVisible.value == "1" ?
                  <Link to="/exclusive" style={{ borderBottom: currentUser && currentUser.is_subscribed == "0" && "solid 1px #3A3A3A" }}>
                    {t("SALE")}
                  </Link> : ""}
                {Object.keys(currentUser).length !== 0 ?
                  (currentUser && currentUser.is_subscribed == "0"  ? <Link to="/subscription">
                    {t("SUBSCRIPTION")}
                  </Link> : "") :
                  <Link to="/subscription">
                    {t("SUBSCRIPTION")}
                  </Link>}
              </div>
            </Collapse>
              </Container>
            </Navbar>
            <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header>
          <div className={styles.menuLogos}>
            {Object.keys(currentUser).length == 0 ? (

              <img src={logo1} alt="logo" className={styles.logo_brand} />

            ) : (
              updateUser &&
              updateUser.customer_profile_image && (

                <img src={updateUser.customer_profile_image} alt="profile-image" className={styles.logo_user} />

              )
            )}
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className={styles.mobileMenu}>
            {!userToken && CreatorVisible && CreatorVisible.value == "1" ? <NavLink className={styles.customNavLink} to="/creator">{t("Creators")}</NavLink> : ""}
            {salePlanVisible?.value == "1" ?
              <NavLink className={styles.customNavLink} to="/exclusive">{t("SALE")}</NavLink>
              : ""}
            {Object.keys(currentUser).length !== 0 ?
              (currentUser && currentUser.is_subscribed == "0" ? <NavLink className={styles.customNavLink} to="/subscription">
                {t("SUBSCRIPTION")}
              </NavLink> : "") :
              <NavLink className={styles.customNavLink} to="/subscription">
                {t("SUBSCRIPTION")}
              </NavLink>}
          </Nav>

        </Offcanvas.Body>
      </Offcanvas>
    </header>

  );
}
export default HeaderSub;




