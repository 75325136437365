import { useTranslation } from "react-i18next";
import "../../../assets/Styles/Common.css";
import { useEffect, useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { PAGE } from "../../../utils/Constants";
import styles from "../../Plan/Plan.module.css";
import Loader from "../../../utils/Loader/Loader";
import { NavLink, useNavigate } from "react-router-dom";
import { PLAN } from "../../../utils/Constants";
import { BsCurrencyDollar } from "react-icons/bs";
import { getPurchasedPlan } from "../../../store/slices/PlanListSlice";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { Toast } from "../../../utils/Toaster";


//---------function for sale data----------
function PurchaseData() {
    const { t } = useTranslation();
    const { userToken } = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { purchasePlanList } = useSelector((state) => state.planList);

    // --------function for open component top of the page ----------
    useEffect(() => {
        $("document").ready(function () {
            $(window).scrollTop(0);
        });
    }, []);

    //---------function for plan list api calling----------
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get("token") || userToken;
        async function purchasePlanList() {
            await dispatch(getPurchasedPlan({ page: PAGE.PAGEONE, userToken: token })
            ).then((responsejson) => {
                const response = responsejson.payload;
                if (response && response.settings && response.settings.success == STATUS_CODES.SUCCESS_API) {
                } else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
                    Toast.fire({
                        icon: "error",
                        title: t("SESSION_EXPIRE"),
                    });
                    dispatch(userLogoutClear());
                    navigate("/login");
                }
            });
        }
        purchasePlanList();
    }, []);

    return (
        <>
            
                {isLoading ? <Loader /> : ""}
                <div className={styles.image_gridSet}>
                    {purchasePlanList && purchasePlanList.length > 0
                        ? purchasePlanList.map((item, index) => (
                            <NavLink
                                to={`/purchase/details/${item.id}/${item.plan_type == "workout_plan"
                                    ? PLAN.WORKOUT_PLAN
                                    : PLAN.STREAM_WORKOUT_PLAN
                                    }/${item.tras_id}`}
                                key={index}
                            >
                                <div className={styles.itemSet}>
                                    <div className={styles.planImage}>
                                        <img
                                            src={item.plan_image}
                                            alt="plan image"
                                            className={styles.imageItemSet}
                                        />
                                        <div className={styles.lockIcon}>
                                            <BsCurrencyDollar />
                                        </div>

                                        {item.show_in_app && item.show_in_app.toLocaleLowerCase() == "no" ? (
                                            ""
                                        ) : item.plan_name && (
                                            <div className={styles.nameOnImage}>
                                                <h6>{item.plan_name}</h6>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </NavLink>
                        ))
                        :
                        ""}
                </div>
                {(purchasePlanList == "") ?
                    <div className={styles.subscriptionPart}>
                        <h2 className="text-white">{t("ADD_PURCHASE_TEXT")}</h2>
                        <div className={styles.logoutBtn}>
                            <button onClick={() => navigate("/exclusive")}>
                                {t("ADD_PLAN_BTN")}
                            </button>
                        </div>
                    </div> : ""}
            
        </>
    );
}
export default PurchaseData;
