import styles from "../ContactUs/ContactUs.module.css";
import { Container, Form } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import "../../assets/Styles/Common.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toaster";
import { STATUS_CODES } from "../../utils/StatusCode";
import SubHeader from "../HeaderSection/SubHeader";
import Loader from "../../utils/Loader/Loader";
import $ from "jquery";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

//----------function for contact us form----------- 
function ContactUs() {
  const [feedbackOption, setFeedbackOption] = useState("General Feedback");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  const passwordKeyPress = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, '')); // this remove from all string
  };

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  //-----------function for Contact Us api calling-----------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("email", formdata ? formdata.email.trim() : "");
    requestData.append("subject", feedbackOption);
    requestData.append("message", formdata ? formdata.message : "");
    requestData.append("contactName", formdata ? formdata.fullName.trim() : "");
    setLoader(true);
    await SublyApi.contactUs(requestData).then((response) => {
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        Toast.fire({
          icon: "success",
          title: response.settings.message,
        });
        setLoader(false);
        setValue("fullName", "");
        setValue("email", "");
        setValue("message", "");
        setFeedbackOption("");
      }
      else {
        Toast.fire({
          icon: "error",
          title: response && response.settings.message,
        });
        setLoader(false);
      }
    })
  };

  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      {loader ? (
        <div className="loader">
          <Loader />
        </div>
      ) : null}
      <SubHeader />
      <div className="main">
        <Container>
          <div className="boxWrapper">
            <div className='innerboxWrapper'>
              <div className={styles.contactBlock}>
                <div className="heading">
                  <h2 style={{color:"#fffff"}}>
                    {t("CONTACT_HEADING1")} <br />
                  </h2>
                  <p className="text-center">{t("CONTACT_PARA")}</p>
                </div>
                <div className={styles.formField}>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className={styles.formGroup}>
                      <Form.Label className={styles.formLabel}>
                        {t("NAME")}
                        <span>*</span>
                      </Form.Label>
                      <Form.Control
                        className="form-control"
                        placeholder={t("NAME_FIELD")}
                        {...register("fullName", {
                          onChange: (e) => handleKeyPress(e),
                          required: {
                            value: true,
                            message: `${t("ENTER_NAME")}`,
                          },
                          maxLength: {
                            value: 30,
                            message: `${t("NAME_MAXLENGTH")}`,
                          },
                        })}
                      />
                      {errors.fullName && (
                        <span className="errorMsg">{errors.fullName.message}</span>
                      )}
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                      <Form.Label className={styles.formLabel}>
                        {t("EMAIL_ADDRESS")} <span>*</span>
                      </Form.Label>
                      <Form.Control
                        className="form-control"
                        placeholder={t("ENTER_EMAIL")}
                        {...register("email", {
                          onChange: (e) => {
                            passwordKeyPress(e);
                          },
                          required: {
                            value: true,
                            message: `${t("EMAIL_REQUIRED")}`,
                          },
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: `${t("INVALID_EMAIL")}`,
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="errorMsg">{errors.email.message}</span>
                      )}
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                      <Form.Label className={styles.formLabel}>
                        {t("FEEDBACK_TYPE")}
                        <span>*</span>
                      </Form.Label>
                      <div className={styles.selectOption}>
                        <Form.Select
                          className="form-control"
                          value={feedbackOption}
                          onChange={(event) => setFeedbackOption(event.target.value)}
                        >
                            <option value="General Feedback">{t("OPTION1")}</option>
                            <option value="Report An Issue">{t("OPTION2")}</option>
                            <option value="Advertiesment And Promo">{t("OPTION3")}</option>
                            <option value="One on one session">One on one session</option>
                            <option value="Custom workout plan">Custom workout plan</option>
                            <option value="Custom diet plan">Custom diet plan</option>
                            <option value="Sell your own plan on this app">Sell your own plan on this app</option>
                            <option value="Job at Doviesfitness">Job at 3fitstyle</option>
                        </Form.Select>

                      </div>
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                      <Form.Label className={styles.formLabel}>
                        {t("MESSAGE")}
                        <span>*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        className="form-control"
                        {...register("message", {
                          onChange: (e) => handleKeyPress(e),
                          required: {
                            value: true,
                            message: `${t("MESSAGE_FIELD")}`,
                          },
                        })}
                      />
                      {errors.message && (
                        <span className="errorMsg">{errors.message.message}</span>
                      )}
                    </Form.Group>
                    <div className={styles.submitBtn}>
                      <button onClick={() => handleSubmit}>
                        {t("SUBMIT_BTN")}
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
export default ContactUs;
