import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../Subscription/SubscriptionDetail.module.css";
import "../../assets/Styles/Common.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import paypal from "../../assets/Images/paypal.svg";
import { useEffect, useState } from "react";
import visa from "../../assets/Images/paymentmethods.png";
import SubHeader from "../HeaderSection/SubHeader";
import { useTranslation } from "react-i18next";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toaster";
import $ from "jquery";
import { updateSubcription, userLogoutClear } from "../../store/slices/UserSlice";
import { SESION_TRANSACTION_TYPE } from "../../utils/Constants";
import paypalImage from "../../assets/Images/paypal.svg";
import { getSubscriptionDetail } from "../../store/slices/SubscriptionSlice";
import Loader from "../../utils/Loader/Loader";
import { loadStripe } from '@stripe/stripe-js';
import stripe from "../../assets/Images/stripeLogo.png";

//--------function for paypal subscription-----------
function SubscriptionDetail() {
  const location = useLocation();
  const details = location.state;
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const { packageDetail } = useSelector((state) => state.subscriptionList);
  const { followedCreator } = useSelector((state) => state.creatorList);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const sessionId = queryParams.get("session_id");
  const paymentType = queryParams.get("type");
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const newDate = year + "-" + month + "-" + day;
  const [isLoading, setIsLoading] = useState(false);
  const package_master_id = localStorage.getItem("package_master_id")
  const amount = localStorage.getItem("amount")

  const formattedDate = new Date().toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  useEffect(() => {
    if (id || package_master_id) {
      setIsLoading(true);
      dispatch(
        getSubscriptionDetail({ id: id || package_master_id, authToken: userToken || null })
      ).then(() => {
        setIsLoading(false)
      })
    } else if (details === null && !id) {
      navigate('/subscription');
    }
  }, [details, JSON.stringify(packageDetail)]);

  useEffect(() => {
    const handlePaymentSuccess = async () => {
      if (!sessionId) return; // Prevent running if sessionId is missing

      let data = {
        session_id: sessionId,
        package_master_id: package_master_id || packageDetail?.id,
        amount: amount || packageDetail?.subscription_amount
      };

      try {
        setIsLoading(true);

        const res = await SublyApi.subscriptionPaymentSuccess(data, userToken);

        if (res.settings.success == 1) {
          Toast.fire({
            icon: "success",
            title: res.settings.message,
          });
          navigate("/subscription-tab");
        } else {
          Toast.fire({
            icon: "error",
            title: "Payment failed. Please try again.",
          });
        }
      } catch (error) {
        console.error("Payment verification failed:", error);
        Toast.fire({
          icon: "error",
          title: "Something went wrong. Please try again.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    // Handle Payment Cancellation
    if (paymentType == "cancel") {
      Toast.fire({
        icon: "error",
        title: "Payment failed. Please try again.",
      });
      return;
    }

    handlePaymentSuccess();
  }, [sessionId]);


  const buttonStyle = {
    layout: 'horizontal',
    color: 'blue',
    shape: 'pill',
    label: 'buynow',
    size: 'medium',
    tagline: false,
    height: 45,
  };

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  // ---------manage session api calling----------------------
  async function ManageSessionExpire() {
    const requestData = new FormData();
    requestData.append("transaction_type", SESION_TRANSACTION_TYPE.Subscription);
    requestData.append("plan_id", details?.packageDetail.id || packageDetail?.id);
    return await SublyApi.sessionManage(requestData, userToken).then((response) => {
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        return true;
      }
      else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: t("SESSION_EXPIRE"),
        });
        dispatch(userLogoutClear());
        navigate("/login");
      } else {
        return false;
      }
    }
    );
  }

  //---------creates a paypal order for lifetime subscription-----------
  const createOrder = async (data, actions) => {
    const res = await ManageSessionExpire();
    if (res) {
      return actions.order
        .create({
          purchase_units: [
            {
              amount: {
                currency_code: details?.packageDetail?.subcription_currency || packageDetail?.subcription_currency,
                value: details?.packageDetail.subscription_amount || packageDetail?.subscription_amount,
              },
            },
          ],
        })
        .then((orderID) => {
          // setOrderID(orderID);
          return orderID;
        });
    } else {
      return false;
    }
  };

  async function checkOutHandleLifetime(data) {
    const requestData = new FormData();
    requestData.append("package_master_id", details?.packageDetail.id || packageDetail?.id);
    requestData.append("amount", details?.packageDetail.subscription_amount || packageDetail?.subscription_amount);
    requestData.append("profile_id", data.purchase_units[0].payments.captures[0].id);
    requestData.append("order_id", data.id);
    requestData.append("payment_json", JSON.stringify(data));
    await SublyApi.subscriptionCheckOut(requestData, userToken).then(
      (response) => {
        if (response && response.success == STATUS_CODES.SUCCESS_API) {
          dispatch(updateSubcription({ is_subscribed: 1 }));
          Toast.fire({
            icon: "success",
            title: response.message,
          });
          navigate("/subscription-tab");
        } else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: t("SESSION_EXPIRE"),
          });
          dispatch(userLogoutClear());
          navigate("/login");
        } else {
          Toast.fire({
            icon: "error",
            title: response && response.message,
          });
        }
      }
    );
  }

  // when create order for life time 
  const onApprove = (data, actions) => {
    return actions.order.capture().then(async function (paymentData) {
      checkOutHandleLifetime(paymentData);
    });
  };

  const paypalSubscribe = async (data, actions) => {
    const res = await ManageSessionExpire();
    if (res) {
      return actions.subscription.create({
        plan_id: details?.packageDetail?.package_id || packageDetail?.package_id,
      });
    } else {
      return false;
    }
  };

  const paypalOnError = (err) => {
  };
  const paypalOnApprove = (data, detail) => {
    // call the backend api to store transaction details
    checkOutHandle(data);
  };

  async function checkOutHandle(data) {
    const requestData = new FormData();
    requestData.append("package_master_id", details?.packageDetail.id || packageDetail?.id);
    // requestData.append("amount", details?.packageDetail.subscription_amount);
    requestData.append("amount", details?.packageDetail || packageDetail
      ? (details?.packageDetail.free_trial == 1 || packageDetail?.free_trial == 1
        ? details?.packageDetail.subscription_amount || packageDetail?.subscription_amount
        : details?.packageDetail.trial_amount || packageDetail?.trial_amount)
      : null);

    requestData.append("profile_id", data.subscriptionID);
    requestData.append("order_id", data.orderID);
    requestData.append("payment_json", JSON.stringify(data));
    await SublyApi.subscriptionCheckOut(requestData, userToken).then(
      (response) => {
        if (response && response.success == STATUS_CODES.SUCCESS_API) {
          dispatch(updateSubcription({ is_subscribed: 1 }));
          Toast.fire({
            icon: "success",
            title: response.message,
          });
          navigate("/subscription-tab");
        } else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: t("SESSION_EXPIRE"),
          });
          dispatch(userLogoutClear());
          navigate("/login");
        } else {
          Toast.fire({
            icon: "error",
            title: response && response.message,
          });
        }
      }
    );
  }


  const makePayment = async () => {
    setIsLoading(true);
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    params.delete("type");

    const newRedirectUrl = `${currentUrl.origin}${currentUrl.pathname}${params.toString() ? "?" + params.toString() : ""}`;


    let data = {
      id: details?.packageDetail?.id || packageDetail?.id,
      type: "subscription",
      platform_type: "web",
      redirect_url: newRedirectUrl
    }

    const res = await SublyApi.stripeCheckout(data, userToken)
    if (res.settings) {
      setIsLoading(false);
      localStorage.setItem("package_master_id", details?.packageDetail?.id || packageDetail?.id)
      localStorage.setItem("amount", details?.packageDetail?.subscription_amount || packageDetail?.subscription_amount)
      window.location.href = res.settings.data.url
    }
  }

  return (
    <>
      {isLoading === true ? <Loader /> :
        (<>
          <SubHeader />
          <div className="main">
            <Container>
              <div className="boxWrapper">
                <div className="innerboxWrapper">
                  {/* {
            details !== null ? */}
                  <div className={styles.subDetail}>
                    {(details?.packageDetail.subscription_duration == '4' || packageDetail?.subscription_duration == '4') ? (
                      <div className={styles.subscriptionPayBox}>
                        <div className={styles.summaryText}>
                          <h2>{t("SUBSCRIPTION_DETAIL_SUMMARY")}</h2>
                        </div>
                        <div className={styles.payText}>
                          <p>{details?.packageDetail.currency_symbol || packageDetail?.currency_symbol}
                            {details?.packageDetail.subscription_amount || packageDetail?.subscription_amount} {details?.packageDetail.subcription_currency || packageDetail?.subcription_currency} {t("LIFETIME")}
                            <br></br>
                            ({t("PAYMENT")})
                            <br></br>
                            <div className={styles.dateShow}>
                              {t("STARTS")} : {formattedDate}
                            </div>
                          </p>
                        </div>
                        <div className={styles.timeText}>
                          <p>{details?.packageDetail.package_name || packageDetail?.package_name}</p>
                          <p>
                            {details?.packageDetail.currency_symbol || packageDetail?.currency_symbol}
                            {details?.packageDetail.subscription_amount || packageDetail?.subscription_amount} {details?.packageDetail.subcription_currency || packageDetail?.subcription_currency}
                          </p>
                        </div>
                        <div className={styles.priceText}>
                          <p>{t("TOTAL_PRICE")}</p>
                          <p>
                            {details?.packageDetail.currency_symbol || packageDetail?.currency_symbol}
                            {details?.packageDetail.subscription_amount || packageDetail?.subscription_amount} {details?.packageDetail.subcription_currency || packageDetail?.subcription_currency}
                          </p>
                        </div>
                      </div>
                    ) : (details?.packageDetail.free_trial == 1 || packageDetail.free_trial == 1 ?
                      (
                        <div className={styles.subscriptionPayBox}>
                          <div className={styles.summaryText}>
                            <h2>{t("SUBSCRIPTION_DETAIL_SUMMARY")}</h2>
                          </div>
                          <div className={styles.payText}>
                            <p>{details?.packageDetail.currency_symbol || packageDetail?.currency_symbol}
                              {details?.packageDetail.subscription_amount || packageDetail?.subscription_amount} {details?.packageDetail.subcription_currency || packageDetail?.subcription_currency} {t("FOR_EACH")} {details?.packageDetail?.duration == "1" || packageDetail?.duration == "1" ? "" : details?.packageDetail.duration || packageDetail?.duration} { }
                              {details?.packageDetail.duration > 1 || packageDetail?.duration > 1
                                ? (details?.packageDetail.duration_type?.toLowerCase() || packageDetail?.duration_type?.toLowerCase()) + 's'
                                : (details?.packageDetail.duration_type.toLowerCase() || packageDetail?.duration_type?.toLowerCase())}
                              {details?.packageDetail.duration_cycle > 1 || packageDetail?.duration_cycle > 1 ?
                                `, for ${details?.packageDetail.duration_cycle || packageDetail?.duration_cycle} installments` :
                                ""
                              }
                              <br></br>
                              ({t("RENEWS")})
                              <br></br>
                              <div className={styles.dateShow}>
                                {t("STARTS")} : {formattedDate}
                              </div>
                            </p>
                          </div>
                          <div className={styles.timeText}>
                            <p>{details?.packageDetail.package_name || packageDetail?.package_name}</p>
                            <p>
                              {details?.packageDetail.currency_symbol || packageDetail?.currency_symbol}
                              {details?.packageDetail.subscription_amount || packageDetail?.subscription_amount} {details?.packageDetail.subcription_currency || packageDetail?.subcription_currency}
                            </p>
                          </div>
                          <div className={styles.priceText}>
                            <p>{t("TOTAL_PRICE")}</p>
                            <p>
                              {details?.packageDetail.currency_symbol || packageDetail?.currency_symbol}
                              {details?.packageDetail.subscription_amount || packageDetail?.subscription_amount} {details?.packageDetail.subcription_currency || packageDetail?.subcription_currency}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.subscriptionPayBox}>
                          <div className={styles.summaryText}>
                            <h2>{t("SUBSCRIPTION_DETAIL_SUMMARY")}</h2>
                          </div>
                          <div className={styles.payText}>
                            <p>
                              <span>
                                {t("START")} :
                              </span> {details?.packageDetail.currency_symbol || packageDetail?.currency_symbol}
                              {details?.packageDetail.trial_amount || packageDetail?.trial_amount} {details?.packageDetail.subcription_currency || packageDetail?.subcription_currency} {t("FOR")} {details?.packageDetail.trial_duration || packageDetail?.trial_duration}-
                              {details?.packageDetail.trial_duration > 1 || packageDetail?.trial_duration > 1
                                ? (details?.packageDetail.trial_duration_type.toLowerCase() || packageDetail?.trial_duration_type?.toLowerCase()) + 's'
                                : (details?.packageDetail.trial_duration_type.toLowerCase() || packageDetail?.trial_duration_type?.toLowerCase())} {t("TRIAL")}
                              <br></br>
                              <span>
                                {t("THEN")} :
                              </span> {details?.packageDetail.currency_symbol || packageDetail?.currency_symbol}
                              {details?.packageDetail.subscription_amount || packageDetail?.subscription_amount} {details?.packageDetail.subcription_currency || packageDetail?.subcription_currency} {t("EACH")} {details?.packageDetail.duration == "1" || packageDetail?.duration == "1" ? "" :
                                details?.packageDetail.duration || packageDetail?.duration} { }
                              {details?.packageDetail.duration > 1 || packageDetail?.duration > 1
                                ? (details?.packageDetail.duration_type.toLowerCase() || packageDetail?.duration_type?.toLowerCase()) + 's'
                                : (details?.packageDetail.duration_type.toLowerCase() || packageDetail?.duration_type?.toLowerCase())} {t("AFTER_TRIAL")} {details?.packageDetail.duration_cycle > 1 || packageDetail?.duration_cycle > 1 ?
                                  `, for ${details?.packageDetail.duration_cycle || packageDetail?.duration_cycle} installments` :
                                  ""
                              }
                              <br></br>
                              ({t("RENEWS")})
                              <br></br>
                              <div className={styles.dateShow}>
                                {t("STARTS")} : {formattedDate}
                              </div>
                            </p>
                          </div>
                          <div className={styles.timeText}>
                            <p>{details?.packageDetail.package_name || packageDetail?.package_name}</p>
                            <p>
                              {details?.packageDetail.currency_symbol || packageDetail?.currency_symbol}
                              {details?.packageDetail.trial_amount || packageDetail?.trial_amount} {details?.packageDetail.subcription_currency || packageDetail?.subcription_currency}
                            </p>
                          </div>
                          <div className={styles.priceText}>
                            <p>{t("TOTAL_PRICE")}</p>
                            <p>
                              {details?.packageDetail.currency_symbol || packageDetail?.currency_symbol}
                              {details?.packageDetail.trial_amount || packageDetail?.trial_amount} {details?.packageDetail.subcription_currency || packageDetail?.subcription_currency}
                            </p>
                          </div>
                        </div>
                      ))}

                    <div className={styles.subscriptionDetailText}>
                      <p>
                        {t("SUBSCRIPTION_NOTE")} { }
                        <span>{t("SUBSCRIPTION_NOTE_PARA")}</span>
                      </p>
                    </div>

                    {followedCreator?.is_main_app_show == "No" &&
                      <>

                        <div className={styles.backButton}>
                          <div className={styles.payPal}>
                            <PayPalScriptProvider
                              options={{
                                vault: true,
                                clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                              }}
                            >
                              {(details?.packageDetail.subscription_duration == '4' || packageDetail?.subscription_duration == '4') ?
                                <PayPalButtons
                                  createOrder={createOrder}
                                  onApprove={onApprove}
                                  style={buttonStyle}
                                />
                                : <PayPalButtons
                                  style={buttonStyle}
                                  amount={details?.packageDetail.subscription_amount || packageDetail?.subscription_amount}
                                  currency={details?.packageDetail.subcription_currency || packageDetail?.subcription_currency}
                                  createSubscription={paypalSubscribe}
                                  onApprove={paypalOnApprove}
                                  catchError={paypalOnError}
                                  onError={paypalOnError}
                                  onCancel={paypalOnError}
                                />}

                            </PayPalScriptProvider>
                          </div>
                        </div>
                        <div className={styles.payPalImg}>
                          <img src={visa} alt="logo" />
                        </div>
                        <div className={styles.poweredImg}>
                          <h4>{t("POWERED_BY")}</h4>
                          <img src={paypalImage} alt="logo" />
                        </div>
                        <h4 className="text-center text-white">
                          OR
                        </h4>
                        <div className={styles.backButton}>
                          <button onClick={() => makePayment()} className={styles.paymentButton} style={{ border: "none" }}>
                            <img src={stripe} alt="Stripe Logo" className={styles.stripeLogo} />
                            Buy Now
                          </button>
                        </div>
                        <div className={styles.payPalImg}>
                          <img src={visa} alt="logo" />
                        </div>
                      </>
                    }
                    {followedCreator?.is_main_app_show == "Yes" &&
                      <>
                        <div className={styles.backButton}>
                          <button onClick={() => makePayment()} className={styles.paymentButton} style={{ border: "none" }}>
                            <img src={stripe} alt="Stripe Logo" className={styles.stripeLogo} />
                            Buy Now
                          </button>
                        </div>
                        <div className={styles.payPalImg}>
                          <img src={visa} alt="logo" />
                        </div>
                      </>
                    }
                  </div>
                  {/* : navigate("/subscription")} */}
                </div>
              </div>
            </Container>
          </div>
        </>)}
    </>
  );
}
export default SubscriptionDetail;
