import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReportCreator } from "../../store/slices/CreatorSlice";
import { Toast } from "../../utils/Toaster";
import { useFormik } from "formik";
import * as Yup from "yup";
import StarsRating from 'react-star-rate';
import { CreateReviewRating, GetReviewRating } from "../../store/slices/ReviewRatingSlice";
import { BsFillStarFill } from "react-icons/bs";
import { BiStar } from "react-icons/bi";

const ReviewModal = ({ show, handleClose, id, filter, data }) => {
    const dispatch = useDispatch();
    const { userToken } = useSelector((state) => state.user);


    // Formik initialization
    const formik = useFormik({
        initialValues: {
            rating: data ? data?.dRating : 0,
            title: data ? data?.tReviewTitle : "",
            review: data ? data?.tReviewDescription : "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            title: Yup.string().trim().required("Title is required."),
            review: Yup.string().trim().required("Description is required.")
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const data = {
                authToken: userToken,
                creator_id: id,
                title: values.title,
                description: values.review,
                rating: values.rating
            };
            const filterData = {
                authToken: userToken,
                id: id,
                filter: filter,
            };

            try {
                const response = await dispatch(CreateReviewRating(data)).unwrap();
                if (response?.settings?.success == 1) {
                    Toast.fire({
                        icon: "success",
                        title: response?.settings?.message
                    });
                    formik.resetForm();
                    handleClose();
                    dispatch(GetReviewRating(filterData))
                } else {
                    Toast.fire({
                        icon: "danger",
                        title: response?.settings?.message
                    });
                }
            } catch (error) {
                Toast.fire({
                    icon: "danger",
                    title: "Failed to submit report. Please try again."
                });
            } finally {
                setSubmitting(false);
            }
        }
    });



    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className="report-modal"
        >
            <Modal.Header className="modal-header-custom">
                <Button
                    onClick={() => {
                        handleClose();
                        formik.resetForm();
                    }}
                    className="cancel-btn"
                    variant="link"
                    disabled={formik.isSubmitting}
                >
                    Cancel
                </Button>
                <Modal.Title className="modal-title-custom">Write A Review</Modal.Title>
                <Button
                    type="submit"
                    className="submit-btn"
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting}
                >
                    {formik.isSubmitting ? "Submitting..." : "Submit"}
                </Button>
            </Modal.Header>
            <Modal.Body className="modal-body-custom">

                <Form noValidate onSubmit={formik.handleSubmit}>
                    <div className="star-rating">
                        <span>Tap a Star to Rate</span>
                        <div className="tap-rating tap-rating-create">
                            {[5, 4, 3, 2, 1].map((star) => (
                                <React.Fragment key={star}>
                                    <input
                                        type="radio"
                                        name="rating"
                                        id={`modal-rating-${star}`} // Unique ID
                                        checked={formik.values.rating === star}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            formik.setFieldValue("rating", star);
                                        }}
                                        style={{ display: "none" }}
                                    />
                                    <label htmlFor={`modal-rating-${star}`}>
                                        {formik.values.rating >= star ? (
                                            <BsFillStarFill style={{ fontSize: "30px", color: "#F19937" }} />
                                        ) : (
                                            <BiStar style={{ fontSize: "30px", color: "#F19937" }} />
                                        )}
                                    </label>
                                </React.Fragment>
                            ))}
                        </div>
                        {/* <StarsRating
                            value={formik.values.rating}
                            onChange={(value) => formik.setFieldValue("rating", value)}
                            style={{ fontSize: "1rem !important", color: "rgb(241, 153, 55) !important" }}
                            allowHalf={false}
                        /> */}
                    </div>
                    <div class="form-container">
                        <Form.Group className="form-group" controlId="formTitle">
                            {/* <Form.Label className="form-label">Title <span class="optional">Optional</span></Form.Label> */}
                            {formik?.values?.title == "" && <span style={{ color: "#FA626B" , left : "72px" , position : "absolute" , marginTop :" 10px"  }}>*</span>}
                            <Form.Control
                                name="title"
                                type="text"
                                className={`form-input ${formik.touched.title && formik.errors.title ? "is-invalid" : ""}`}
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                                placeholder="Title"
                                style={{ color: "white" }}
                            />
                            {formik.touched.title && formik.errors.title && (
                                <div className="invalid-feedback">{formik.errors.title}</div>
                            )}
                        </Form.Group>

                        <Form.Group className="form-group" controlId="formReport">
                            {/* <Form.Label className="form-label">Review</Form.Label> */}
                            {formik?.values?.review == "" &&<span style={{ color: "#FA626B" , left : "95px" , position : "absolute" , marginTop :" 10px"  }}>*</span>}
                            <Form.Control
                                name="review"
                                as="textarea"
                                className={`form-input ${formik.touched.review && formik.errors.review ? "is-invalid" : ""}`}
                                rows={6}
                                value={formik.values.review}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                                placeholder="Review"
                                style={{ borderBottom: "0" }}
                            />
                              {formik.touched.review && formik.errors.review && (
                                <div className="invalid-feedback">{formik.errors.review}</div>
                            )}
                        </Form.Group>

                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ReviewModal;
