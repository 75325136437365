import styles from "../HomePage/HomePage.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bg1 from "../../assets/Images/bg1.jpg";
import bg2 from "../../assets/Images/bg2.jpg";
import { useEffect, useState } from "react";
import HeaderSection from "../HeaderSection/HeaderSection";
import { Col, Container, Row } from "react-bootstrap";
import playstore from "../../assets/Images/playstore.png";
import appstore from "../../assets/Images/appstore.png";
import TrainerData from "./TrainerData";
import SublyApi from "../../helpers/Api";
import HomePageSlider from "../../commonComponents/HomePageSlider/HomePageSlider";
import { HOME_IMAGE } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { GetHomePage, setBgImages, userLogoutClear } from "../../store/slices/UserSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import $ from "jquery";

// -------------function for home page-----------
function HomePage() {
  const { t } = useTranslation();
  const [packageList, setPackageList] = useState("");
  const [backgroundImage, setBackgroundImage] = useState();
  const [bgIndex, setBgIndex] = useState(0);
  const dispatch = useDispatch();
  const { backgroundImageRes } = useSelector((state) => state.user);
  const { homePageData } = useSelector((state) => state.user);
  const { userToken, currentUnit, updateUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
 

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);




  useEffect(() => {
    async function getHomePage() {
      try {
        await dispatch(GetHomePage({authToken: userToken || null})).then((res)=>{
          const response = res.payload;
          if (response && response.settings.success == 1) {
            setBackgroundImage(response.settings.data.topImage[0]?.vImage)
          }else{
            window.location.href = "https://www.3fitstyle.com";
          }
        });
      } finally {
        // setIsLoading(false); 
      }
    }
  
    getHomePage();
  }, [dispatch]);
  

  //---------function for home background image change--------- 
  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImage((previousImage) => {
        if (homePageData?.topImage?.length > 0) {
          let lastImgIndex = homePageData?.topImage?.length - 1;
          if (lastImgIndex == bgIndex) {
            setBgIndex(0);
            const webImage = homePageData?.topImage[0]?.vImage;
            return webImage;
          } else {
            const newBgIndex = bgIndex + 1;
            setBgIndex(newBgIndex);
            const webImage = homePageData?.topImage[newBgIndex]?.vImage;
            return webImage;
          }
        }
        else {
          setBackgroundImage(bg1)
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [bgIndex]);

  //---------function for subscription api calling---------- 
  useEffect(() => {
    async function subscriptionPackageList() {
      const details = await SublyApi.getPackageList();
      details && setPackageList(details.data);
    }
    subscriptionPackageList();
  }, []);

  const bgDefaultCss = {
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    backgroundPositionX: "center",
    backgroundColor: "transparent",
    transition: "background 0.3s ease",
    // backgroundPosition:"center",
  };
  const bgCss = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    backgroundPositionX: "center",
    backgroundColor: "transparent",
    transition: "background 0.3s ease",
    // backgroundPosition:"center",
  };

  const handleLoginRedirect = () => {

    const host = window.location.hostname;
    let subdomain = "";
    const parts = host.split(".");
    if (host.endsWith(".staging.3fitstyle.com")) {
      if (parts.length === 4 && parts[0] !== "www") {
    
        subdomain = parts[0];
      } else if (parts.length === 5 && parts[0] === "www") {
      
        subdomain = parts[1];
      }
    }
    dispatch(userLogoutClear());
    if (subdomain) {
      window.location.href = `https://${process.env.REACT_APP_MAIN_URL}${process.env.REACT_APP_PARAM_URL}login?subdomain=${subdomain}`;
    } else {
      localStorage.removeItem("subdomain");
      window.location.href = `https://${process.env.REACT_APP_MAIN_URL}${process.env.REACT_APP_PARAM_URL}login`;
    }
  };
  
  const handleSignupRedirect = () => {
    const host = window.location.hostname;
    let subdomain = "";
    const parts = host.split(".");
    if (host.endsWith(".staging.3fitstyle.com")) {
      if (parts.length === 4 && parts[0] !== "www") {
    
        subdomain = parts[0];
      } else if (parts.length === 5 && parts[0] === "www") {
        subdomain = parts[1];
      }
    }
    dispatch(userLogoutClear());
    if (subdomain) {
      window.location.href = `https://${process.env.REACT_APP_MAIN_URL}${process.env.REACT_APP_PARAM_URL}sign-up?subdomain=${subdomain}`;
    } else {
      localStorage.removeItem("subdomain");
      window.location.href = `https://${process.env.REACT_APP_MAIN_URL}${process.env.REACT_APP_PARAM_URL}sign-up`;
    }
  };
  

  return (
    <>
      <HeaderSection />
      
        <div
          className={styles.headerSection}
          // style={{
          //   backgroundImage: `url(${backgroundImage})`,
          //   backgroundSize: "cover",
          //   width: "100%",
          //   height: "100vh",
          //   backgroundPositionX: "center",
          // }}
          style={backgroundImage ? bgCss : bgDefaultCss}
        >
          <div className={styles.headingText}>
            <h1>{homePageData?.top_title}</h1>
            <h2 style={{color:"#c5c6c9"}}>{homePageData?.top_description}</h2>

            <div className={styles.loginBtn}>
              <div className={styles.StartUp}>
                {/* <NavLink to="/login">{t("LOGIN")}</NavLink> */}
                <NavLink onClick={handleLoginRedirect}>{t("LOGIN")}</NavLink>
              </div>
              <div className={styles.StartUp1}>
                {/* <NavLink to="/sign-up">{t("SIGN_UP")}</NavLink> */}
                <NavLink onClick={handleSignupRedirect}>{t("SIGN_UP")}</NavLink>
              </div>
            </div>
          </div>
        </div>
        <Container>
        <div className="bg-dark">
        <div className={styles.textSection}>
            <div className={styles.innerContainer}>
              <div className="heading">
                <h2>{homePageData?.join_us_title}</h2>   
              </div>
              <p>{homePageData?.join_us_description}</p>
            </div>
        </div>

        <div className={styles.trainerSection}>
            <div className={styles.innerContainer}>
              <div className="heading">
                <h2>{homePageData?.our_trainer_title}</h2>   
              </div>         
              <HomePageSlider packageList={homePageData?.middleImage}/>    
              <p>{homePageData?.our_trainer_description}</p>        
            </div>
        </div>
        <div className={styles.appSection}>
          <div className={styles.innerContainer}>
            {homePageData?.bottomImage?.length >= 1 &&<Row>
                  <Col lg={12}>
                    <div className={styles.trainingImg}>
                      <img src={homePageData?.bottomImage[0]?.vImage} alt="trainer-image" />
                    </div>
                  </Col>
            </Row>}
            <div className={styles.socialSection}>
              <a href={process.env.REACT_APP_APP_STORE_LINKS} target="_blank">
                <img src={appstore} alt="app-store" />
              </a>
              <a href={process.env.REACT_APP_PLAY_STORE_LINKS} target="_blank">
                <img src={playstore} alt="play-store" />
              </a>
            </div>
          </div>
         
           
          
        </div>
        </div>
        </Container>
      
    </>
  );
}
export default HomePage;
