import React, { useState } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReportCreator } from "../../store/slices/CreatorSlice";
import { Toast } from "../../utils/Toaster";
import { useFormik } from "formik";
import * as Yup from "yup";
import StarsRating from 'react-star-rate';
import { CreateReviewRating, DeleteReviewRating, EditReviewRating, GetReviewRating } from "../../store/slices/ReviewRatingSlice";
import { Await, Link } from "react-router-dom";
import { StarRating } from "../../commonComponents/StarRating/RenderStar";
import playstore from "../../assets/Images/playstore.png";
import appstore from "../../assets/Images/appstore.png";
import styles from "./CreatorDetail.module.css";



const FollowCreatorModal = ({ show, handleClose }) => {

    // console.log(object)

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className="creators-modal followed-creator-modal"
        >
            <Modal.Body className="modal-body-custom">
                <div className="response">
                    <div className="creator-response text-white">
                        To connect with this creator or other creators, please follow their profile on the app first. Once you follow, you’ll unlock the option to chat with them directly. You can unfollow at any time if you choose.
                    </div>
                </div>
                <div className="response">
                    <div className="creator-response text-white">
                        Don’t have the app yet? Download it now from the <Link className="text-white">App Store</Link> or <Link className="text-white">Google Play Store</Link> and start connecting with your favorite creators today!
                    </div>
                </div>
                <div className={styles.socialSection}>
                    <Link to={process.env.REACT_APP_APP_STORE_LINKS} target="_blank">
                        <img src={appstore} alt="app-store" />
                    </Link>
                    <Link to={process.env.REACT_APP_PLAY_STORE_LINKS} target="_blank">
                        <img src={playstore} alt="play-store" />
                    </Link>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default FollowCreatorModal;
