import { BiStar } from "react-icons/bi";
import { BsFillStarFill , BsStarHalf } from "react-icons/bs";

const renderStars = (rating) => {
    const roundedRating = Math.round(rating * 2) / 2; // Round to nearest half
    const fullStars = Math.floor(roundedRating); // Count of full stars
    const halfStar = roundedRating % 1 !== 0; // Check for half-star
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Remaining empty stars
  
    const stars = [];
  
    // Push full stars
    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <span key={`full-${i}`} className="full">
          <BsFillStarFill style={{ fontSize: "16px" , color : "#F19937" }} />

        </span>
      );
    }
  
    // Push half star if needed
    if (halfStar) {
      stars.push(
        <span key="half" className="half">
          <BsStarHalf style={{ fontSize: "16px" , color : "#F19937" }} />
        </span>
      );
    }
  
    // Push empty stars
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <span key={`empty-${i}`} className="empty">
          <BiStar style={{ fontSize: "16px" , color : "#F19937" }} />
        </span>
      );
    }
  
    return stars;
  };
  
  export const StarRating = ({ review }) => (
    <div className="stars" style={{display:"flex", position:"relative", top: "-2px"}}>
      {renderStars(review?.rating)}
    </div>
  );
  