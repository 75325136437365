import React, { useEffect, useRef, useState } from 'react';
import styles from "./CreatorDetail.module.css";
import SubHeader from '../HeaderSection/SubHeader';
import { Button, Col, Collapse, Container, Offcanvas, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BiArrowBack, BiDotsVerticalRounded } from 'react-icons/bi';
import { Toast } from "../../utils/Toaster";
import facebook from "../../assets/Images/Facebook_Ico.svg";
import instagram from "../../assets/Images/Instagram_Ico.svg";
import twitter from "../../assets/Images/Twitter_Section.svg";
import tiktok from "../../assets/Images/icon_tiktok.svg";
import youtube from "../../assets/Images/icon_youtube.svg";

// Icons Import
import iconChat from '../../assets/Images/icon-chat.svg';
import iconShare from '../../assets/Images/icon-share.svg';
import iconRating from '../../assets/Images/icon-rating.svg';
import iconReport from '../../assets/Images/icon-report.svg';
import iconSupport from '../../assets/Images/icon-support.svg';
import iconUnfollow from '../../assets/Images/iconunfollow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { followCreator, getCreatorDetails, goTOCreatorApp, UnfollowCreator, updateCreatorDetails } from '../../store/slices/CreatorSlice';
import { StarRating } from '../../commonComponents/StarRating/RenderStar';
import Loader from '../../utils/Loader/Loader';
import LoginModal from '../../commonComponents/LoginModal/LoginModal';
import ReportModal from '../../commonComponents/ReportModal/ReportModal';
import DescriptionModal from './DescriptionModal';
import FollowCreatorModal from './FollowCreatorModal';
import FooterSection from '../FooterSection/FooterSection';

export const CreatorDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const creatorDetails = useSelector((state) => state.creatorList.creatorDetails);
  const { userToken, currentUnit, updateUser, currentUser } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [showFullText, setShowFullText] = useState(false);
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [fullDescription, setFullDescription] = useState('');
  const [showFullDescriptionModal, setFullDescriptionModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showFollowModal, setShowFollowModal] = useState(false);
  const maxCharacters = 250;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);

  // Effect to handle resize event and update the isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleText = () => {
    if (!isMobile) {
      setFullDescription(text);
      setFullDescriptionModal(!showFullDescriptionModal);
      setShowFullText(!showFullText);
    } else {
      setShowFullText(!showFullText);
      setFullDescription(text);
      setIsOffcanvasOpen(!isOffcanvasOpen);
    }
  };

  const text = creatorDetails?.LWebsiteDescription || "";

  const collapseRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (collapseRef.current && !collapseRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);
  useEffect(() => {
    if (id) {
      setIsLoading(true)
      dispatch(
        getCreatorDetails({ id, authToken: userToken || null })
      ).then((res) => {
        setIsLoading(false)
        if (res?.payload?.settings?.data?.eStatus == "Inactive") {
          // navigate("/creater-not-found")
          navigate("/creator", { state: { showPopup: true, message: "Requested creator is no longer available." } });
        }
      })
    }
  }, [id]);

  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate("/creator");
    }
  };
  const handleChat = () => {
    if (!userToken) {
      setOpen(false)
      setShowLoginModal(true);
    }
    else if (userToken && creatorDetails?.isFollowed == 0) {
      setOpen(false);
      setShowFollowModal(true)
    }
    else if (userToken && creatorDetails?.isFollowed == 1) {
      setOpen(false)
      setShowFollowModal(true)
    }
  };
  const copyLinkToClipboard = (id) => {
    const baseUrl = window.location.origin;

    const url = `${baseUrl}/frontend/creatordetail/${id}`;
    // Construct the URL
    navigator.clipboard
      .writeText(url) // Copy the URL to clipboard
      .then(() => {
        Toast.fire({
          icon: "success",
          title: "Link copied to clipboard!",
        });
      });
  };
  const handleReportCreator = () => {
    if (!userToken) {
      setShowLoginModal(true);
    }
    else if (userToken) {
      setShowReportModal(true);
      setOpen(false)
    }
  };
  const handleUnfollowCreator = async () => {
    if (!userToken) {
      setShowLoginModal(true);
    }
    else if (userToken) {
      let data = {
        authToken: userToken,
        creator_id: id
      }
      const res = await dispatch(UnfollowCreator(data))
      if (res?.payload?.settings?.success == 1) {
        Toast.fire({
          icon: "success",
          title: res?.payload?.settings?.message,
        });
        dispatch(
          updateCreatorDetails({
            ...creatorDetails,
            isFollowed: 0,
          })
        );
      } else {
        Toast.fire({
          icon: "danger",
          title: res?.payload?.settings?.message,
        });
      }
    }
  };
  const handleFollowCreator = async () => {
    if (!userToken) {
      // Toast.fire({
      //   icon: "warning",
      //   title: "Please Login",
      // });
      setShowLoginModal(true);
    }
    else if (userToken) {
      let data = {
        authToken: userToken,
        creator_id: id
      }
      const res = await dispatch(followCreator(data))
      if (res?.payload?.settings?.success == 1) {
        Toast.fire({
          icon: "success",
          title: res?.payload?.settings?.message,
        });
        dispatch(
          updateCreatorDetails({
            ...creatorDetails,
            isFollowed: 1,
          })
        );
      } else {
        Toast.fire({
          icon: "danger",
          title: res?.payload?.settings?.message,
        });
      }
    }
  };

  const handleNavigateToWebsite = () => {
    let data = {
      authToken: userToken,
      creator_id: id
    }
    dispatch(goTOCreatorApp(data))
  }

  return (
    <>
      {isLoading === true ? <Loader /> :
        (
          <>
            <div className="mobileHide">
              <SubHeader />
            </div>
            <div className="main paddingNone">
              <div className="mbBack desktopHide">
                <button onClick={handleBack} className="back-button">
                  <span>
                    <BiArrowBack style={{ color: "white" }} />
                  </span>
                </button>
                <button onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open} className="back-button dots-button">
                  <span>
                    <BiDotsVerticalRounded style={{ color: "white" }} />
                  </span>
                </button>
              </div>  
              <Container>
                <div className="boxWrapper" style={{ padding: !isMobile && "30px 0px 60px" }}>
                  <div className='innerboxWrapper'>
                    <div className={styles.actionBar}>
                      <button onClick={handleBack} className="back-button mobileHide">
                        <span>
                          <BiArrowBack style={{ color: "white" }} />
                        </span>
                      </button>
                      <button onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open} className="back-button mobileHide">
                        <span>
                          <BiDotsVerticalRounded style={{ color: "white" }} />
                        </span>
                      </button>
                    </div>
                    <Collapse in={open}>
                      <div className={styles.socialBar} ref={collapseRef}>
                        <Link onClick={() => handleChat()}>
                          <span><img src={iconChat} alt="" /> </span>
                          Chat with Creator
                        </Link>
                        <Link onClick={() => copyLinkToClipboard(creatorDetails?.iAdminId)}>
                          <span><img src={iconShare} alt="" /> </span>
                          Share
                        </Link>
                        <Link
                          to={`/reviewrating/${creatorDetails?.iAdminId}`}
                        >
                          <span><img src={iconRating} alt="" style={{ height: "45px" }} /> </span>
                          Rating & Review
                        </Link>
                        <Link onClick={() => handleReportCreator()}>
                          <span><img src={iconReport} alt="" /> </span>
                          Report Creator
                        </Link>
                        <Link to="/contact-us">
                          <span><img src={iconSupport} alt="" /> </span>
                          App Support
                        </Link>
                        {creatorDetails?.isFollowed == 1 && <Link onClick={() => handleUnfollowCreator()}>
                          <span><img src={iconUnfollow} alt="" /> </span>
                          Unfollow Creator
                        </Link>}
                        {creatorDetails?.isFollowed == 0 && <Link onClick={() => handleFollowCreator()}>
                          <span><img src={iconUnfollow} alt="" /> </span>
                          Follow Creator
                        </Link>}
                      </div>
                    </Collapse>
                    <div className="img-content-wrapper">
                      <Row className="">
                        <Col md={6}>
                          <div className={styles.creatorSingleImage}>

                            <img src={creatorDetails?.vWebsiteImage} alt="" />
                            <div className={styles.creatorSingleImageText}>
                              {creatorDetails?.eWebsiteNameShowInApp == "Yes" && <h2>{creatorDetails?.vWebsiteName}</h2>}
                              {creatorDetails?.eWebsiteSubTitleShowInApp == "Yes" && <h4>{creatorDetails?.vWebsiteSubTitle}</h4>}
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='content-main-wrapper'>
                            <div>
                              <p>
                                {text
                                  .slice(0, maxCharacters)
                                  .split("\n")
                                  .map((line, index) => (
                                    <React.Fragment key={index}>
                                      {line}
                                      <br />
                                    </React.Fragment>
                                  ))}
                                {text.length > maxCharacters && ' ...'}
                                {text.length > maxCharacters && (
                                  <span
                                    onClick={handleToggleText}
                                    style={{ color: '#0195F6', cursor: 'pointer', marginLeft: '5px' }}
                                  >
                                    {showFullText ? 'more' : 'more'}
                                  </span>
                                )}
                              </p>
                            </div>
                            <div>
                              <div className={styles.creatorSingleContent}>
                                {/* <p>{creatorDetails?.LWebsiteDescription}</p> */}
                                {/* <p>
                              {showFullText ? text : `${text.slice(0, maxCharacters)}${text.length > maxCharacters ? " ..." : ""}`}
                              {text.length > maxCharacters && (
                                <span
                                  onClick={handleToggleText}
                                  style={{ color: "#0195F6", cursor: "pointer", marginLeft: "5px" }}
                                >
                                  {showFullText ? "" : "more"}
                                </span>
                              )}
                            </p> */}

                                {isMobile && <Offcanvas
                                  show={isOffcanvasOpen}
                                  onHide={() => setIsOffcanvasOpen(false)}
                                  placement="bottom"
                                  className="desc-drawer"
                                >
                                  <div className="drawer-handle"></div>
                                  <Offcanvas.Header>
                                    <Offcanvas.Title>{creatorDetails?.eWebsiteNameShowInApp == "Yes" && <h4>{creatorDetails?.vWebsiteName}</h4>}
                                      {creatorDetails?.eWebsiteSubTitleShowInApp == "Yes" && <h6>{creatorDetails?.vWebsiteSubTitle}</h6>}</Offcanvas.Title>
                                  </Offcanvas.Header>
                                  <Offcanvas.Body>
                                    {fullDescription?.split("\n").map((line, index) => (
                                      <p key={index}>{line}</p>
                                    ))}
                                  </Offcanvas.Body>
                                </Offcanvas>}


                                <div>
                                  <div className={styles.ratingReviewBox} style={{ cursor: 'pointer' }} onClick={() => navigate(`/reviewrating/${creatorDetails?.iAdminId}`)}>
                                    <h4 className='ratingHeading'>Rating & Review {">"}</h4>
                                    <div className="d-flex justify-content-center gap-2 g-2">
                                      <p style={{ color: "#9B9B9B", margin: 0 }}>
                                        {Number(creatorDetails?.average_rating).toFixed(1)}
                                      </p>
                                      <StarRating
                                        review={{
                                          rating: creatorDetails?.average_rating,
                                        }}
                                      />
                                      <p style={{ color: "#9B9B9B", margin: 0 }}>
                                        {creatorDetails?.total_rating_count} Ratings
                                      </p>
                                    </div>

                                  </div>

                                  <div className={styles.socialLinks}>
                                    {creatorDetails?.social?.map((social) => {
                                      if (social.socialMediaType === "Instagram") {
                                        return (
                                          <Link
                                            key={social.iSocialId}
                                            to={`https://www.instagram.com/${social.socialMediaUsername}`}
                                            target="_blank"
                                          >
                                            <img src={instagram} alt="Instagram" />
                                          </Link>
                                        );
                                      } else if (social.socialMediaType === "Facebook") {
                                        return (
                                          <Link
                                            key={social.iSocialId}
                                            to={`https://www.facebook.com/${social.socialMediaUsername}`}
                                            target="_blank"
                                          >
                                            <img src={facebook} alt="Facebook" />
                                          </Link>
                                        );
                                      } else if (social.socialMediaType === "Twitter") {
                                        return (
                                          <Link
                                            key={social.iSocialId}
                                            to={`https://twitter.com/${social.socialMediaUsername}`}
                                            target="_blank"
                                          >
                                            <img src={twitter} alt="Twitter" />
                                          </Link>
                                        );
                                      }
                                      else if (social.socialMediaType === "Youtube") {
                                        return (
                                          <Link
                                            key={social.iSocialId}
                                            to={`https://youtube.com/${social.socialMediaUsername}`}
                                            target="_blank"
                                          >
                                            <img src={youtube} height={40} width={40} alt="Youtube" />
                                          </Link>
                                        );
                                      }
                                      else if (social.socialMediaType === "Tiktok") {
                                        return (
                                          <Link
                                            key={social.iSocialId}
                                            to={`https://tiktok.com/${social.socialMediaUsername}`}
                                            target="_blank"
                                          >
                                            <img src={tiktok} alt="Tiktok" />
                                          </Link>
                                        );
                                      }
                                      return null; // Handle any other types if needed
                                    })}
                                  </div>
                                  <div className='follow-wrapper mt-4 ' style={{ display: "flex", justifyContent: "center", gap: "10px", alignItems: "center" }}>
                                    {creatorDetails?.isFollowed == 0 ? <button onClick={handleFollowCreator} className="follow-btn" >
                                      Follow to view website
                                    </button> : <Link className="follow-btn" to={`${creatorDetails?.websiteUrl}?token=${userToken}`} target='_blank' style={{ textDecoration: "none", color: "#fff" }} onClick={handleNavigateToWebsite}> Go to Website</Link>}
                                    <button className="chat-btn" onClick={() => handleChat()}>
                                      <span>
                                        <img src={iconChat} alt="" style={{ height: "20px" }} />
                                        {/* <IoChatbubbleEllipsesOutline /> */}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Container>
            </div>

          </>
        )}
      <LoginModal
        show={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        id={id}
        userToken={userToken}
      />
      <ReportModal show={showReportModal} handleClose={() => setShowReportModal(false)} id={id} />
      {!isMobile && <DescriptionModal show={showFullDescriptionModal} handleClose={() => setFullDescriptionModal(false)} data={fullDescription} creatorDetails={creatorDetails} />}
      <FollowCreatorModal show={showFollowModal} handleClose={() => setShowFollowModal(false)} />

    </>

  )
}
